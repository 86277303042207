// src/components/header/useHeader.ts

import { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useTheme, useMediaQuery } from '@mui/material';

const MAX_PRICE = 3000;

const sortOptions = [
  { value: '', label: 'None' },
  { value: 'timeToClose', label: 'Time to Close' },
  { value: 'priceLowToHigh', label: 'Price Low to High' },
  { value: 'priceHighToLow', label: 'Price High to Low' },
  { value: 'numberOfBids', label: 'Number of Bids' },
];

const categories = [
  { name: 'Watches', path: '/category/watch', filter: 'categories' },
  { name: 'Jewelry', path: '/category/jewelry', filter: 'categories' },
  { name: 'Diamonds', path: '/category/diamond', filter: 'categories' },
  { name: 'Gemstones', path: '/category/gemstone', filter: 'categories' },
  { name: 'My Bids', path: '/category/myBids', filter: 'myBids', requireAuth: true },
  { name: 'My Favourites', path: '/category/myFavourite', filter: 'favorites', requireAuth: true },
  // Add more as needed
];

const dynamicFiltersKeyMap: { [key: string]: string } = {
  shape: 'Shape',
  material: 'Material',
  gemstone: 'Gemstone',
  jewelryType: 'Jewelry Type',
};

const useHeader = (filters: any, onApplyFilters: (filters: any) => void, onCloseFilter: () => void) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { requireAuth } = useAuth();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md')); // md breakpoint is 960px

  const [searchQuery, setSearchQuery] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openSortMenu = Boolean(anchorEl);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);
  const searchBoxRef = useRef<HTMLDivElement>(null);
  const { isLoggedIn } = useAuth();

  // Helper function to truncate the display name
  const truncateDisplayName = (name: string) => {
    return name.length > 12 ? name.substring(0, 12) + '...' : name;
  };

  // Sorting handlers
  const handleSortMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSortMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSortSelect = (sortValue: string) => {
    const updatedFilters = { sort: sortValue };
    onApplyFilters(updatedFilters);
    handleSortMenuClose();
  };

  // Search handlers
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = () => {
    const updatedFilters = { q: searchQuery.trim() };
    onApplyFilters(updatedFilters);
    if (!isDesktop) {
      setIsMobileSearchOpen(false); // Close search on mobile after submitting
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSearchSubmit();
    }
  };

  // Filter handlers
  const handleRemoveFilter = (filterType: string, value: any) => {
    const updatedFilters = { ...filters };
    if (filterType === 'categories') {
      updatedFilters[filterType] = updatedFilters[filterType].filter((item: string) => item !== value);
    } else if (filterType === 'priceRange') {
      updatedFilters.priceRange = [0, MAX_PRICE];
    } else if (['myBids', 'favorites', 'myAuctions'].includes(filterType)) {
      updatedFilters[filterType] = false;
    } else if (['q', 'sort', 'supplier', 'supplierName'].includes(filterType)) {
      updatedFilters[filterType] = '';
    } else if (filterType === 'dynamicFilters') {
      delete updatedFilters.dynamicFilters[value];
    }
    onApplyFilters(updatedFilters);
  };

  const handleClearAllFilters = () => {
    const clearedFilters = {
      categories: [],
      shapes: [],
      materials: [],
      gemstones: [],
      priceRange: [0, MAX_PRICE],
      myBids: false,
      favorites: false,
      myAuctions: false,
      q: '',
      sort: '',
      supplier: '',
      supplierName: '',
      dynamicFilters: {},
    };
    onApplyFilters(clearedFilters);
    onCloseFilter();
  };

  const hasFiltersApplied = Boolean(
    (filters.categories && filters.categories.length > 0) ||
      (filters.priceRange && (filters.priceRange[0] !== 0 || filters.priceRange[1] !== MAX_PRICE)) ||
      filters.myBids ||
      filters.favorites ||
      filters.myAuctions ||
      filters.q ||
      filters.sort ||
      filters.supplier ||
      filters.supplierName ||
      (filters.dynamicFilters && Object.keys(filters.dynamicFilters).length > 0)
  );

  const handleGoHome = () => {
    onApplyFilters({});
    console.log('use Header go home');
    navigate('/');
  };

  const handleLogin = async () => {
    await requireAuth();
  };

  // Drawer handlers
  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  const getDynamicFilterLabel = (key: string) => {
    return dynamicFiltersKeyMap[key] || key;
  };

  const handleCategorySelectInternal = (category: string, filter_name: string) => {
    const filter_value = filter_name === 'categories' ? [category] : 'true';
    const updatedFilters = { [filter_name]: filter_value };
    onApplyFilters(updatedFilters);
  };

  // Mobile search handlers
  const handleMobileSearchOpen = () => {
    setIsMobileSearchOpen(true);
  };

  const handleMobileSearchClose = () => {
    setIsMobileSearchOpen(false);
    setSearchQuery('');
  };

  // Click outside handler for mobile search
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isMobileSearchOpen && searchBoxRef.current && !searchBoxRef.current.contains(event.target as Node)) {
        handleMobileSearchClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobileSearchOpen]);

  return {
    isDesktop,
    searchQuery,
    setSearchQuery,
    handleSearchChange,
    handleSearchSubmit,
    handleKeyPress,
    anchorEl,
    openSortMenu,
    handleSortMenuOpen,
    handleSortMenuClose,
    handleSortSelect,
    drawerOpen,
    toggleDrawer,
    categories,
    handleCategorySelectInternal,
    truncateDisplayName,
    handleGoHome,
    handleLogin,
    isMobileSearchOpen,
    handleMobileSearchOpen,
    handleMobileSearchClose,
    searchBoxRef,
    hasFiltersApplied,
    handleRemoveFilter,
    handleClearAllFilters,
    sortOptions,
    getDynamicFilterLabel,
  };
};

export default useHeader;
