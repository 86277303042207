// src/pages/LandingPage.tsx

import React, { useEffect, useState } from 'react';
import { Container, Typography, CircularProgress, Box, useTheme, useMediaQuery } from '@mui/material';
import { AuctionItem as AuctionItemType } from '../models/interfaces';
import AuctionCard from '../components/AuctionCard';
import './LandingPage.css';
import TopSlideshow from '../components/TopSlideshow';
import Categories from '../components/Categories';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useSnackbar } from 'notistack';
import apiClient from '../components/apiClient'; // Import your Axios instance
import { handleAxiosError } from '../utils/handleAxiosError'; // Import the utility function
import HorizontalAuctionList from '../components/HorizontalAuctionList';

const LandingPage: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { requireAuth, isLoggedIn } = useAuth(); // Get Auth0 functions
  const [hotListItems, setHotListItems] = useState<AuctionItemType[]>([]);
  const [lastViewedItems, setLastViewedItems] = useState<AuctionItemType[]>([]);
  const [favoriteItems, setFavoriteItems] = useState<AuctionItemType[]>([]);
  const [bidItems, setBidItems] = useState<AuctionItemType[]>([]);
  const [closingSoonItems, setClosingSoonItems] = useState<AuctionItemType[]>([]);
  const [promotedItems, setPromotedItems] = useState<AuctionItemType[]>([]);
  const [ringsItems, setRingsItems] = useState<AuctionItemType[]>([]);
  const [necklacesItems, setNecklacesItems] = useState<AuctionItemType[]>([]);
  const [braceletsItems, setBraceletsItems] = useState<AuctionItemType[]>([]);
  const [earringsItems, setEarringsItems] = useState<AuctionItemType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // check if is mobile
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchHotListItems = async () => {
    try {
      const response = await apiClient.get('/auction-items/hot-listed');
      setHotListItems(response.data);
    } catch (error: any) {
      console.error('Error fetching hot list items:', error);
      const message = handleAxiosError(error) || 'Failed to fetch hot list items.';
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const fetchLastViewedItems = async () => {
    if (!isLoggedIn) return;
    try {
      const response = await apiClient.get('/user/me/last-viewed');
      setLastViewedItems(response.data.auctions);
    } catch (error: any) {
      console.error('Error fetching last viewed items:', error);
      const message = handleAxiosError(error) || 'Failed to fetch last viewed items.';
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const fetchFavoriteItems = async () => {
    if (!isLoggedIn) return;
    try {
      const response = await apiClient.get('/user/me/favorites');
      setFavoriteItems(response.data.auctions);
    } catch (error: any) {
      console.error('Error fetching favorite items:', error);
      const message = handleAxiosError(error) || 'Failed to fetch favorite items.';
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const fetchBidItems = async () => {
    if (!isLoggedIn) return;
    try {
      const response = await apiClient.get('/auction-items/list', {
        params: { myBids: true, page: 1, limit: 10 },
      });
      setBidItems(response.data.auctions);
    } catch (error: any) {
      console.error('Error fetching bid items:', error);
      const message = handleAxiosError(error) || 'Failed to fetch your bids.';
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const fetchClosingSoonItems = async () => {
    try {
      const response = await apiClient.get('/auction-items', {
        params: {
          sortBy: 'endDate',
          order: 'asc',
          limit: 10,
        },
      });
      setClosingSoonItems(response.data.auctions);
    } catch (error: any) {
      console.error('Error fetching closing soon items:', error);
      const message = handleAxiosError(error) || 'Failed to fetch closing soon items.';
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const fetchPromotedItems = async () => {
    try {
      const response = await apiClient.get('/auction-items', {
        params: {
          promoted: true,
          limit: 20,
        },
      });
      setPromotedItems(response.data.auctions);
    } catch (error: any) {
      console.error('Error fetching promoted items:', error);
      const message = handleAxiosError(error) || 'Failed to fetch promoted items.';
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const fetchRingsItems = async () => {
    try {
      const response = await apiClient.get('/auction-items', {
        params: {
          Categories: 'jewelry',
          limit: 10,
          dynamicFilters: { jewelryType: 'Ring' },
        },
      });
      setRingsItems(response.data.auctions);
    } catch (error: any) {
      console.error('Error fetching rings items:', error);
      const message = handleAxiosError(error) || 'Failed to fetch rings.';
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const fetchNecklacesItems = async () => {
    try {
      const response = await apiClient.get('/auction-items', {
        params: {
          Categories: 'jewelry',
          limit: 10,
          dynamicFilters: { jewelryType: 'Necklace' },
        },
      });
      setNecklacesItems(response.data.auctions);
    } catch (error: any) {
      console.error('Error fetching necklaces items:', error);
      const message = handleAxiosError(error) || 'Failed to fetch necklaces.';
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const fetchBraceletsItems = async () => {
    try {
      const response = await apiClient.get('/auction-items', {
        params: {
          Categories: 'jewelry',
          limit: 10,
          dynamicFilters: { jewelryType: 'Bracelet' },
        },
      });
      setBraceletsItems(response.data.auctions);
    } catch (error: any) {
      console.error('Error fetching bracelets items:', error);
      const message = handleAxiosError(error) || 'Failed to fetch bracelets.';
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const fetchEarringsItems = async () => {
    try {
      const response = await apiClient.get('/auction-items', {
        params: {
          Categories: 'jewelry',
          limit: 10,
          dynamicFilters: { jewelryType: 'Earrings' },
        },
      });
      setEarringsItems(response.data.auctions);
    } catch (error: any) {
      console.error('Error fetching earrings items:', error);
      const message = handleAxiosError(error) || 'Failed to fetch earrings.';
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const handleCategorySelect = (category: string) => {
    navigate(`/category/${category}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          fetchHotListItems(),
          fetchLastViewedItems(),
          fetchFavoriteItems(),
          fetchBidItems(),
          fetchClosingSoonItems(),
          fetchPromotedItems(),
          fetchRingsItems(),
          fetchNecklacesItems(),
          fetchBraceletsItems(),
          fetchEarringsItems(),
        ]);
      } catch (err) {
        setError('Failed to load data.');
        enqueueSnackbar('Failed to load data.', { variant: 'error' });
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [isLoggedIn]);

  return (
    <div className="landing-page-container">
      <Container className="landing-page">
        {isLoading ? (
          <Box className="loading-container">
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography variant="body1" color="error" align="center">
            {error}
          </Typography>
        ) : (
          <>
            {isMobile ? (
              <Box
                className="landing-page-header"
                sx={{
                  backgroundImage: 'url(/site/background2.jpg)',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  height: '30vh',
                  width: '100vw',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  textAlign: 'center',
                }}
              />
            ) : (
              <> </>
            )}

            {hotListItems && hotListItems.length > 0 && <TopSlideshow items={hotListItems.slice(0, 5)} />}

            <Container className="categories-container" style={{ background: 'white', paddingTop: '50px' }}>
              <div className="categories">
                <Categories onCategorySelect={handleCategorySelect} />
              </div>
            </Container>

            {promotedItems.length > 0 && (
              <HorizontalAuctionList 
              title={isMobile ? "Featured<br >Items": "Featured Items"} 
              items={promotedItems} 
              img="/site/row1.png"
              titleLocation={{ margin: {xs: '0px 0px 80px 0px', md: '0px 0px 40px 0px' }}}
               />
            )}
            {favoriteItems.length > 0 && (
              <HorizontalAuctionList
                title={isMobile ? "Your<br >Favorites": "Your Favorites"} 
                titleLocation={{ margin: {xs: '0px 0px 10px 65%', md: '0px 0px 40px 0px' }}}
                items={favoriteItems}
                img="/site/row2.png"
                titleColor="black"
              />
            )}
            {lastViewedItems.length > 0 && (
              <HorizontalAuctionList
                title={isMobile ? "Recently<br >Viewed": "Recently Viewed"} 
                titleLocation={{ margin: {xs: '0px 0px 15% 0px', md: '0px 0px 0px 0px' }}}
                items={lastViewedItems}
                img="/site/row3.png"
                titleColor="black"
              />
            )}
            {bidItems.length > 0 && (
              <HorizontalAuctionList 
              title={isMobile ? "Your<br >Bids": "Your Bids"} 
              items={bidItems} img="/site/row4.png" titleColor="black" />
            )}
            {closingSoonItems.length > 0 && (
              <HorizontalAuctionList
                title={isMobile ? "Closing<br >Soon": "Closing Soon"} 
                titleLocation={{ margin: {xs: '0px 0px -15% 0px', md: '0px 0px 0px 0px' }}}
                items={closingSoonItems}
                img="/site/row5.png"
                titleColor="black"
              />
            )}
            {ringsItems.length > 0 && (
              <HorizontalAuctionList title="Rings" items={ringsItems} img="/site/row6.png" titleColor="black" />
            )}
            {necklacesItems.length > 0 && (
              <HorizontalAuctionList title="Necklaces" items={necklacesItems} img="/site/row7.png" />
            )}
            {braceletsItems.length > 0 && (
              <HorizontalAuctionList title="Bracelets" items={braceletsItems} img="/site/row8.png" />
            )}
            {earringsItems.length > 0 && (
              <HorizontalAuctionList title="Earrings" items={earringsItems} img="/site/row9.png" />
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default LandingPage;
