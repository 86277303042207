// src/components/header/HeaderDesktop.tsx

import React from 'react';
import {
  AppBar,
  Toolbar,
  Grid2,
  Box,
  Button,
  IconButton,
  Tooltip,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Menu,
  MenuItem,
  Fade,
  Chip,
  Typography,
} from '@mui/material';
import {
  AccountCircle,
  Search as SearchIcon,
  Sort as SortIcon,
  FilterAltOff as FilterAltOffIcon,
  FilterAlt as FilterAltIcon,
  Menu as MenuIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import UserMenu from '../UserMenu';
import CategoryButton from './CategoryButton';
import './HeaderDesktop.css';
import { useAuth } from '../../contexts/AuthContext';
import { ReactComponent as BlingLongLogo } from '../../assets/images/BlingLong.svg';

interface HeaderDesktopProps {
  isDesktop: boolean;
  searchQuery: string;
  handleSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSearchSubmit: () => void;
  handleKeyPress: (e: React.KeyboardEvent) => void;
  anchorEl: null | HTMLElement;
  openSortMenu: boolean;
  handleSortMenuOpen: (e: React.MouseEvent<HTMLElement>) => void;
  handleSortMenuClose: () => void;
  handleSortSelect: (value: string) => void;
  drawerOpen: boolean;
  toggleDrawer: (open: boolean) => (e: React.KeyboardEvent | React.MouseEvent) => void;
  categories: Array<{ name: string; path: string; filter: string; requireAuth?: boolean }>;
  handleCategorySelectInternal: (category: string, filter_name: string) => void;
  truncateDisplayName: (name: string) => string;
  handleGoHome: () => void;
  handleLogin: () => void;
  isMobileSearchOpen: boolean;
  handleMobileSearchOpen: () => void;
  handleMobileSearchClose: () => void;
  searchBoxRef: React.RefObject<HTMLDivElement>;
  hasFiltersApplied: boolean;
  handleRemoveFilter: (filterType: string, value: any) => void;
  handleClearAllFilters: () => void;
  sortOptions: Array<{ value: string; label: string }>;
  getDynamicFilterLabel: (key: string) => string;
  user: {
    displayName: string;
    role: string;
  } | null;
  onLogout: () => void;
  filters: any;
  onOpenFilter?: () => void;
}

const HeaderDesktop: React.FC<HeaderDesktopProps> = ({
  isDesktop,
  searchQuery,
  handleSearchChange,
  handleSearchSubmit,
  handleKeyPress,
  anchorEl,
  openSortMenu,
  handleSortMenuOpen,
  handleSortMenuClose,
  handleSortSelect,
  drawerOpen,
  toggleDrawer,
  categories,
  handleCategorySelectInternal,
  truncateDisplayName,
  handleGoHome,
  handleLogin,
  hasFiltersApplied,
  handleRemoveFilter,
  handleClearAllFilters,
  sortOptions,
  getDynamicFilterLabel,
  user,
  onLogout,
  filters,
  onOpenFilter,
}) => {
  const MAX_PRICE = 3000;
  const location = useLocation();
  const showFilterIcon = location.pathname.startsWith('/category');
  const { isLoggedIn } = useAuth(); // Get Auth0 functions
  return (
    <div className="header-desktop-container">
      <AppBar position="static" className="header-appbar">
        {/* Desktop Layout */}
        <Grid2 container size={12}>
          {/* Left Side */}
          <Grid2 size={{ md: 2 }}>
            <Button onClick={handleGoHome} className="header-home-button">
              <BlingLongLogo width={150} height={40} /> {/* Adjust size as needed */}
            </Button>
            {showFilterIcon && (
              <Tooltip title="Filters">
                <Button
                  variant="text"
                  onClick={onOpenFilter}
                  className="header-filter-button"
                  startIcon={<FilterAltIcon />}
                ></Button>
              </Tooltip>
            )}
          </Grid2>
          {/* Center */}
          <Grid2 size={{ md: 8 }} sx={{ justifyContent: 'center', display: 'flex' }}>
            <Box display="flex" alignItems="flex-end" className="header-search-box">
              <TextField
                variant="outlined"
                placeholder="Search..."
                size="small"
                value={searchQuery}
                onChange={handleSearchChange}
                onKeyPress={handleKeyPress}
                className="header-search-input"
                InputProps={{
                  style: {
                    backgroundColor: 'white',
                    borderRadius: 4,
                    paddingRight: 0,
                  },
                }}
              />
              <IconButton
                onClick={handleSearchSubmit}
                className="header-search-button"
                aria-label="search"
                sx={{ p: 1 }}
              >
                <SearchIcon className="header-search-icon" />
              </IconButton>
            </Box>
          </Grid2>
          {/* Right Side */}
          <Grid2 size={{ md: 2 }} offset={{ xs: 0 }} sx={{ textAlign: 'right' }}>
            <div className="header-right">
              {user ? (
                <UserMenu
                  user={{
                    ...user,
                    displayName: truncateDisplayName(user?.displayName || ''),
                  }}
                  onLogout={onLogout}
                  onApplyFilters={handleSortSelect}
                />
              ) : (
                <Button className="header-login-button" startIcon={<AccountCircle />} onClick={handleLogin}>
                  Login
                </Button>
              )}
            </div>
          </Grid2>
        </Grid2>

        {/* Categories Navigation for Desktop */}
        <Box className="header-categories">
          {categories.map(
            (category) =>
              !(category.requireAuth && !isLoggedIn) && (
                <CategoryButton
                  key={category.name}
                  name={category.name}
                  filter_name={category.filter}
                  path={category.path}
                  onCategorySelect={handleCategorySelectInternal}
                />
              )
          )}
        </Box>

        {/* Selected Filters Section */}
        {(location.pathname === '/' || location.pathname.startsWith('/category')) && hasFiltersApplied && (
          <div className="header-filters-section">
            <div className="header-selected-filters-list">
              {/* Display Chips for selected filters */}
              {filters.categories &&
                filters.categories.map((category: string) => (
                  <Chip
                    key={category}
                    label={category}
                    onDelete={() => handleRemoveFilter('categories', category)}
                    className="filter-chip"
                  />
                ))}
              {filters.priceRange && (filters.priceRange[0] !== 0 || filters.priceRange[1] !== MAX_PRICE) && (
                <Chip
                  label={`$${filters.priceRange[0]} - $${filters.priceRange[1]}`}
                  onDelete={() => handleRemoveFilter('priceRange', null)}
                  className="filter-chip"
                />
              )}
              {filters.myBids && (
                <Chip label="My Bids" onDelete={() => handleRemoveFilter('myBids', null)} className="filter-chip" />
              )}
              {filters.favorites && (
                <Chip
                  label="Favorites"
                  onDelete={() => handleRemoveFilter('favorites', null)}
                  className="filter-chip"
                />
              )}
              {filters.myAuctions && (
                <Chip
                  label="My Auctions"
                  onDelete={() => handleRemoveFilter('myAuctions', null)}
                  className="filter-chip"
                />
              )}
              {filters.q && (
                <Chip
                  label={`Search: ${filters.q}`}
                  onDelete={() => handleRemoveFilter('q', null)}
                  className="filter-chip"
                />
              )}
              {filters.supplier && (
                <Chip
                  label={`Supplier: ${filters.supplier}`}
                  onDelete={() => handleRemoveFilter('supplier', null)}
                  className="filter-chip"
                />
              )}
              {filters.supplierName && (
                <Chip
                  label={`Supplier: ${filters.supplierName}`}
                  onDelete={() => handleRemoveFilter('supplierName', null)}
                  className="filter-chip"
                />
              )}
              {filters.dynamicFilters &&
                Object.keys(filters.dynamicFilters).map((key) => (
                  <Chip
                    key={key}
                    label={`${getDynamicFilterLabel(key)}: ${filters.dynamicFilters[key]}`}
                    onDelete={() => handleRemoveFilter('dynamicFilters', key)}
                    className="filter-chip"
                  />
                ))}
              {filters.sort && (
                <Chip
                  label={`Sort: ${filters.sort}`}
                  onDelete={() => handleRemoveFilter('sort', null)}
                  className="filter-chip"
                />
              )}
            </div>
            <div className="header-selected-filters-clear">
              {/* Clear All Filters Button */}
              <Tooltip title="Clear All Filters">
                <IconButton
                  aria-label="clear all filters"
                  onClick={handleClearAllFilters}
                  size="large"
                  className="clear-all-button"
                >
                  <FilterAltOffIcon fontSize="small" color="error" />
                </IconButton>
              </Tooltip>

              {/* Sort Options */}
              <Box display="flex" alignItems="center" className="sort-select">
                <Tooltip title="Sort By">
                  <IconButton aria-label="sort by" onClick={handleSortMenuOpen} size="large" color="inherit">
                    <SortIcon />
                  </IconButton>
                </Tooltip>
                <Menu
                  TransitionComponent={Fade}
                  anchorEl={anchorEl}
                  open={openSortMenu}
                  onClose={handleSortMenuClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                >
                  {sortOptions.map((option) => (
                    <MenuItem
                      key={option.value}
                      selected={filters.sort === option.value}
                      onClick={() => handleSortSelect(option.value)}
                      sx={{
                        fontSize: {
                          xs: '1rem',
                          sm: '1rem',
                          md: '1rem',
                          lg: '1rem',
                        },
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </div>
          </div>
        )}
      </AppBar>
    </div>
  );
};

export default HeaderDesktop;
