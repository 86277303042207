// src/components/add-auction/fields/index.ts
import { diamondFields } from './diamondFields';
import { gemstoneFields } from './gemstoneFields';
import { watchFields } from './watchFields';
import { jewelryFields } from './jewelryFields';

export const categoryFieldsMap: { [key: string]: Array<any> } = {
  Diamonds: diamondFields,
  Gemstones: gemstoneFields,
  Watches: watchFields,
  Jewelry: jewelryFields,
};
