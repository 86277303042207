// src/models/interfaces.ts

// User Interface
export interface CurrentUser {
  email: string;
  userId: string;
  displayName: string;
  role: string;
}

// Bid Interface
export interface Bid {
  bidder: string;
  amount: number;
  timestamp: string;
}

// Base Product Interface
export interface Product {
  productType: string;
  title: string;
  description: string;
  category: string;
  customerSKU?: string;
  supplierSitePreview?: string;
}

// Diamond Interface
export interface Diamond extends Product {
  // Basic Properties
  stoneType: string; // e.g., "Natural"
  shape: string; // e.g., "Round"
  caratWeight: number; // e.g., 1.2

  // Color Properties
  colorType: string; // e.g., "Natural White"
  color?: string[]; // e.g., ["D (colourless)", "E"] (Optional: Depends on colorType)
  fancyColorIntensity?: string; // e.g., "Fancy" (Optional: Depends on colorType)
  fancyColorOvertone?: string; // e.g., "Blue" (Optional: Depends on colorType)
  fancyColorMainStone?: string; // e.g., "Blue" (Optional: Depends on colorType)

  // Clarity Properties
  clarityGrade: string[]; // e.g., ["VVS1", "VS2"]

  // Cut Properties
  cutGrade: string; // e.g., "Excellent"
  symmetry: string; // e.g., "Good"
  polish: string; // e.g., "Excellent"
  fluorescence?: string; // e.g., "None" (Optional)

  // Certification Properties
  certification?: string; // e.g., "IGI" (Optional)
  certificationNumber?: string; // e.g., "1234567890" (Optional)

  // Additional Properties
  additionalInfo?: string; // e.g., "Includes certificate copy" (Optional)
  sku?: string; // e.g., "DIAMOND-001" (Optional)
}

// Gemstone Interface
export interface Gemstone extends Product {
  gemstoneType: string; // e.g., "Opal", "Sapphire", "Emerald"
  caratWeight: number; // e.g., "3.19"
  treatment: string; // e.g., "No evidence of treatment"
  origin: string; // e.g., "Ethiopia"
  laboratoryReport: string; // e.g., "Antwerp Laboratory for Gemstone Testing (ALGT)"
  sealedByLaboratory: string; // e.g., "Yes" or "No"
  shape: string; // e.g., "Oval", "Pear"
  cuttingStyle: string; // e.g., "Cabochon", "Faceted cut"
  gemstoneColour: string; // e.g., "Blue", "Green", "Multicolour"
  transparency: string; // e.g., "Transparent", "Semi-transparent"
  clarityGrade: string; // e.g., "Eye clean", "VS"
  labReportNumber: string; // e.g., "12345678"
  numberOfStones?: number; // Optional for cases like multicolour sapphires
  totalCaratWeight?: number; // Optional for cases like multicolour sapphires
  opticalEffect?: string; // Optional for cases like asterism
  cutGrade?: string; // Optional for cases like excellent cut
}

// Watch Interface
export interface Watch extends Product {
  brand: string; // e.g., "TAG Heuer", "Seiko", "Geovani"
  wmodel: string; // e.g., "Professional 200m", "Diver", "Swiss Diamond Watch"
  referenceNumber: string; // e.g., "999.208A", "SKX009 - 7s26-0020"
  gender: string; // e.g., "Men", "Women", "Unisex"
  period: string; // e.g., "1990-1999", "2000-2010"
  movement: string; // e.g., "Quartz", "Mechanical automatic"
  caseMaterial: string; // e.g., "Steel", "Gold-plated", "Stainless steel"
  caseDiameter: string; // e.g., "28 mm", "42 mm", "37 mm"
  condition: string; // e.g., "Worn with little to no signs of use", "Unworn"
  lengthOfWatchBand: string; // e.g., "18 cm", "21 cm"
  extras?: string; // Optional: e.g., "Box", "Warranty"
  shippedInsured: string; // e.g., "Yes", "No"
  era: string; // e.g., "1900-2000", "After 2000"
}

export interface Jewelry extends Product {
  // Common fields

  // 2. Metal
  material?: string;

  // 3. Fineness (e.g., 14K, 18K)
  fineness?: string;

  // 4. Total Weight (g)
  totalWeight?: number;

  // 5. Condition
  condition?: string;

  // 6. Certification
  certification?: string;

  // 7. Certification Number
  certificationNumber?: string;

  // 8. Total Carat Weight (ct)
  totalCaratWeight?: number;

  // 9. Main Stone
  mainStone?: string;

  // 10. Main Stone Type (e.g., Natural, Synthetic)
  mainStoneType?: string;

  // 11. Color Type (e.g., Natural Colored, Natural White)
  colorType?: string;

  // 12. Color (e.g., D Color)
  color?: string[];

  // 13. Fancy Color Intensity of Main Stone
  fancyColorIntensity?: string;

  // 14. Fancy Color Overtone of Main Stone
  fancyColorOvertone?: string;

  // 15. Fancy Color of Main Stone
  fancyColorMainStone?: string;

  // 16. Main Stone Carat Weight
  mainStoneCaratWeight?: number;

  // 17. Number of Main Stones
  numberOfMainStones?: number;

  // 18. Diamond Clarity of Main Stone
  clarityGrade?: string[];

  // 19. Shape of Main Stone
  shape?: string;

  // 20. Surrounding Stones
  surroundingStones?: string;

  // 21. Surrounding Stone Type
  surroundingStoneType?: string;

  // 22. Surrounding Stone Color Type
  surroundingStoneColorType?: string;

  // 23. Color of Surrounding Stone
  surroundingStoneColor?: string[];

  // 24. Fancy Color Intensity of Surrounding Stone
  surroundingStoneFancyColorIntensity?: string;

  // 25. Fancy Color Overtone of Surrounding Stone
  surroundingStoneFancyColorOvertone?: string;

  // 26. Fancy Color of Surrounding Stone
  surroundingStoneFancyColor?: string;

  // 27. Surrounding Stone Carat Weight
  surroundingStoneCaratWeight?: number;

  // 28. Number of Surrounding Stones
  numberOfSurroundingStones?: number;

  // 29. Diamond Clarity of Surrounding Stone
  surroundingStoneClarityGrade?: string[];

  // 30. Shape of Surrounding Stone
  surroundingStoneShape?: string;

  // 31. Size (String) - For general size descriptions
  size?: string;

  // 32. Ring Size
  ringSize?: string;

  // 33. Bracelet Size
  braceletSize?: string;

  // 34. Size (cm) - Renamed to sizeCm to avoid duplication
  sizeCm?: number;

  // 35. Length (cm)
  length?: number;

  // 36. Diameter (cm)
  diameter?: number;

  // 37. Width (cm)
  width?: number;

  // 38. Height (cm)
  height?: number;

  // 39. Circumference (cm)
  circumference?: number;

  // 40. Title Additional Information
  titleAdditionalInfo?: string;
}

// Union Type for All Product Types
export type ProductTypes = Diamond | Gemstone | Watch | Jewelry;

export type AuctionSupplierInfo = {
  _id: string;
  name: string;
  country: string;
};

// Auction Item Interface
export interface AuctionItem {
  _id: string;
  startingPrice: number;
  reservePrice: number;
  buyItNowPrice: number;
  sellerUpraisal: number;
  bids: Bid[];
  category: string;
  currentBid: number;
  startDate: string;
  endDate: string;
  status: string;
  product: ProductTypes;
  img: string;
  images: string[];
  quickBids: number[];
  maxBid: number;
  expertEstimateMin: number;
  expertEstimateMax: number;
  isFavorite?: boolean; // Add this optional property
  supplier?: AuctionSupplierInfo;
  auctionId: number;
  supplierInternals?: string;
}
export interface GetAuctionsResponse {
  auctions: AuctionItem[];
  totalAuctions: number;
  currentPage: number;
  totalPages: number;
}
// Type Guard Functions
export function isDiamond(product: Product): product is Diamond {
  return product.productType === 'Diamond';
}

export function isGemstone(product: Product): product is Gemstone {
  return product.productType === 'Gemstone';
}

export function isWatch(product: Product): product is Watch {
  return product.productType === 'Watch';
}

export function isJewelry(product: Product): product is Jewelry {
  return product.productType === 'Jewelry';
}

export interface ImageItem {
  id: string;
  src: string;
  file?: File;
  existing: boolean;
  isCover?: boolean; // Make isCover optional
}

export interface User {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  displayName: string;
  role: string;
}
