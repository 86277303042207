// src/index.tsx

import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css'; // Ensure this includes the @font-face declarations
import theme from './theme';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline'; // Import CssBaseline
import * as serviceWorker from './serviceWorker';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { LanguageProvider } from './components/chat/LanguageContext';
import ErrorBoundary from './components/ErrorBoundary';
import { WebSocketProvider } from './contexts/WebSocketContext';
import { DebounceAuctionListProvider } from './contexts/DebounceAuctionListContext';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { AuthProvider } from './contexts/AuthContext';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Router>
        <AuthProvider>
          <SnackbarProvider maxSnack={3}>
            <WebSocketProvider>
              <I18nextProvider i18n={i18n}>
                <LanguageProvider>
                  <ThemeProvider theme={theme}>
                    <CssBaseline /> {/* Ensure CssBaseline is inside ThemeProvider */}
                    <DebounceAuctionListProvider>
                      <App />
                    </DebounceAuctionListProvider>
                  </ThemeProvider>
                </LanguageProvider>
              </I18nextProvider>
            </WebSocketProvider>
          </SnackbarProvider>
        </AuthProvider>
      </Router>
    </ErrorBoundary>
  </React.StrictMode>
);

serviceWorker.unregister();
