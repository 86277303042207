// src/components/onboarding/Step1PersonalInfo.tsx

import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { OnboardingPersonalInfo } from './dto/OnboardingDto'; // Import the onboarding DTO
import PhoneNumberInput from './PhoneNumberInput';

interface StepProps {
  onNext: (data: OnboardingPersonalInfo) => void; // Use onboarding-specific type
  onSkip: () => void;
  initialData: OnboardingPersonalInfo; // Make it required
}

const Step1PersonalInfo: React.FC<StepProps> = ({ onNext, onSkip, initialData }) => {
  const [firstName, setFirstName] = useState<string>(initialData.firstName || '');
  const [lastName, setLastName] = useState<string>(initialData.lastName || '');
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(
    initialData && initialData.mobileNumber && initialData.countryCode
      ? `${initialData.countryCode}${initialData.mobileNumber}`
      : ''
  );
  const [error, setError] = useState<string>('');

  // Effect to handle initial data and autofill
  useEffect(() => {
    if (initialData) {
      const combinedNumber = `${initialData.countryCode}${initialData.mobileNumber}`;
      setPhoneNumber(combinedNumber || undefined);
    }
  }, [initialData]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('handleSubmit - firstName:', firstName);
    console.log('handleSubmit - lastName:', lastName);
    console.log('handleSubmit - phoneNumber:', phoneNumber);

    // Basic validation
    if (!firstName || !lastName || !phoneNumber) {
      setError('All fields are required.');
      return;
    }

    // Validate the phone number using react-phone-number-input's validation
    if (!isValidPhoneNumber(phoneNumber)) {
      setError('Invalid phone number.');
      return;
    }

    // Parse the phone number to extract country code and national number
    const parsedNumber = parsePhoneNumber(phoneNumber);
    if (!parsedNumber) {
      setError('Invalid phone number format.');
      return;
    }

    const countryCode = `+${parsedNumber.countryCallingCode}`;
    const mobileNumber = parsedNumber.nationalNumber;

    console.log('Parsed countryCode:', countryCode);
    console.log('Parsed mobileNumber:', mobileNumber);

    setError('');

    onNext({
      firstName,
      lastName,
      mobileNumber,
      countryCode,
    });
  };

  return (
    <Box
      component="form"
      autoComplete="off"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Typography variant="h6" gutterBottom>
        Personal Information
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      <TextField
        name="firstName"
        label="First Name"
        fullWidth
        margin="normal"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        required
        autoComplete="given-name"
      />
      <TextField
        name="lastName"
        label="Last Name"
        fullWidth
        margin="normal"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        required
        autoComplete="family-name"
      />
      <Box marginTop={2}>
        <PhoneInput
          international
          defaultCountry="US" // Set a default country if needed
          value={phoneNumber}
          onChange={setPhoneNumber}
          name="phone"
          autoComplete="tel"
          // Optional: Customize the input styles to match MUI's TextField
          className="mui-phone-input"
          inputComponent={PhoneNumberInput}

          // You can add additional props or styles as needed
        />
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box display="flex" justifyContent="space-between" marginTop={3}>
        <Button variant="contained" color="primary" type="submit">
          Next
        </Button>
        <Button variant="text" onClick={onSkip}>
          Skip
        </Button>
      </Box>
    </Box>
  );
};

export default Step1PersonalInfo;
