// src/components/add-auction/fields/gemstoneFields.ts

export const calculateTitleForGemstone = (productFields: any): string => {
  const {
    gemstoneType,
    caratWeight,
    treatment,
    origin,
    shape,
    cuttingStyle,
    gemstoneColour,
    clarityGrade,
    numberOfStones,
    totalCaratWeight,
  } = productFields;

  let titleParts = [];

  // Number of Stones (if more than one)
  if (numberOfStones && numberOfStones > 1) {
    titleParts.push(`${numberOfStones}pcs`);
  }

  // Total Carat Weight or Carat Weight
  if (totalCaratWeight) {
    titleParts.push(`${totalCaratWeight}ct`);
  } else if (caratWeight) {
    titleParts.push(`${caratWeight}ct`);
  }

  // Gemstone Type
  if (gemstoneType) {
    titleParts.push(gemstoneType);
  }

  // Gemstone Colour
  if (gemstoneColour) {
    titleParts.push(gemstoneColour);
  }

  // Shape and Cutting Style
  const shapeAndCut = [shape, cuttingStyle].filter(Boolean).join(' ');
  if (shapeAndCut) {
    titleParts.push(shapeAndCut);
  }

  // Clarity Grade
  if (clarityGrade) {
    titleParts.push(`Clarity: ${clarityGrade}`);
  }

  // Treatment
  if (treatment && treatment !== 'No evidence of treatment') {
    titleParts.push(`(${treatment})`);
  }

  // Origin
  if (origin) {
    titleParts.push(`from ${origin}`);
  }

  return titleParts.join(' ');
};

export const gemstoneFields = [
  {
    name: 'gemstoneType',
    label: 'Gemstone Type',
    type: 'text',
    values: [
      'Alexandrite',
      'Amber',
      'Amethyst',
      'Ametrine',
      'Ammolite',
      'Andalusite',
      'Apatite',
      'Aquamarine',
      'Beryl',
      'Blood Coral',
      'Bowenite',
      'Carnelian',
      "Cat's Eye (Chrysoberyl)",
      'Chalcedony',
      'Chrysoberyl',
      'Citrine',
      'Coral',
      'Danburite',
      'Demantoid',
      'Diamond',
      'Diaspore',
      'Diopside',
      'Emerald',
      'Enstatite',
      'Fire Opal',
      'Fluorite',
      'Garnet',
      'Grandidierite',
      'Hackmanite',
      'Heliodor',
      'Hessonite',
      'Hiddenite',
      'Iolite',
      'Jadeite',
      'Kunzite',
      'Kyanite',
      'Mixed gemstones',
      'Moonstone',
      'Morganite',
      'Opal',
      'Pearl',
      'Peridot',
      'Prehnite',
      'Quartz',
      'Rhodolite',
      'Ruby',
      'Sapphire',
      'Sapphire padparadscha',
      'Scapolite',
      'Sinhalite',
      'Smithsonite',
      'Spessartite',
      'Sphalerite',
      'Sphene',
      'Spinel',
      'Spodumene',
      'Star Ruby',
      'Star Sapphire',
      'Tanzanite',
      'Topaz',
      'Tourmaline',
      'Tourmaline paraiba',
      'Tsavorite',
      'Vesuvianite',
      'Yellow Beryl (Heliodor)',
      'Zircon',
      'Zoisite',
    ],
  },
  {
    name: 'caratWeight',
    label: 'Carat Weight',
    type: 'number',
  },
  {
    name: 'treatment',
    label: 'Treatment',
    type: 'text',
    values: [
      'Clarity enhancement',
      'Commonly heated with no evidence of treatment',
      'Commonly irradiated with no evidence of treatment',
      'Dyeing',
      'Fracture healing / filling',
      'Heat treatment',
      'High pressure, High temperature (HPHT) treatment',
      'Impregnation',
      'Irradiation',
      'No evidence of treatment',
      'No heat treatment',
      'Not specified in the report',
      'Oiling',
      'Surface diffusion',
      'Waxing',
    ],
  },
  {
    name: 'origin',
    label: 'Origin',
    type: 'text',
    values: [],
  },
  {
    name: 'laboratoryReport',
    label: 'Laboratory Report',
    type: 'text',
    values: [
      'American Gemological Labs (AGL)',
      'Anchor Cert',
      'Antwerp Laboratory for Gemstone Testing (ALGT)',
      'Asian Institute of Gemological Sciences (AIGS)',
      'Bellerophon Gemlab',
      'C. Dunaigre Gem Lab (CD)',
      'Carat Gem Lab',
      'Central Gem Laboratory (CGL)',
      'Certified gemologist report',
      'Deutsche Stiftung Edelsteinforschung (DSEF)',
      'GEM-TECH Istituto Gemmologico',
      'GGTL Liechtenstein and Switzerland',
      'Gem Report Antwerp (GRA)',
      'Gem research Swiss Lab (GRS)',
      'Gemewizard Gemological Laboratory (GWLab)',
      'Gemological Institute of America (GIA)',
      'G\u00fcbelin Gem Lab',
      'Instituto Gem\u00f3logico Espa\u00f1ol (IGE)',
      'International Gemological Institute (IGI)',
      'International Jewellery & Gems Certifiers (IJGC)',
      'Istituto Gemmologico Italiano (IGI)',
      'Laboratoire Fran\u00e7ais de Gemmologie (LFG)',
      'Lotus Gemology',
      'Masterstones',
      'Netherlands Gem Laboratory (NEL)',
      'No laboratory report',
      'Swiss Gemmological Institute (SSEF)',
      'The London Gem and Pearl Laboratory',
    ],
  },
  {
    name: 'sealedByLaboratory',
    label: 'Sealed by Laboratory',
    type: 'text',
    values: [],
  },
  {
    name: 'shape',
    label: 'Shape',
    type: 'text',
    values: [
      'Bead',
      'Bullet',
      'Calf',
      'Cushion',
      'Cut cornered square',
      'Drop',
      'Emerald',
      'Fancy',
      'Fantasy',
      'Half moon',
      'Heart',
      'Kite',
      'Lozenge',
      'Marquise',
      'Mixed shape',
      'Octagon',
      'Oval',
      'Pear',
      'Radiant',
      'Rectangle',
      'Round',
      'Shield',
      'Square',
      'Star',
      'Tapered',
      'Trapezoid',
      'Triangle',
    ],
  },
  {
    name: 'cuttingStyle',
    label: 'Cutting Style',
    type: 'text',
    values: [
      'Baguette cut',
      'Barion cut',
      'Brilliant cut',
      'Briolette cut',
      'Cabochon cut',
      'Carre cut',
      'Carving cut',
      'Emerald cut',
      'Faceted cut',
      'Mixed cut',
      'Modified brilliant cut',
      'Modified cut',
      'Old European cut',
      'Old mine cut',
      'Pear shape cut',
      'Princess cut',
      'Radiant cut',
      'Rose cut',
      'Scissor cut',
      'Single cut',
      'Squared emerald cut',
      'Step cut',
      'Table cut',
      'Trilliant cut',
    ],
  },
  {
    name: 'gemstoneColour',
    label: 'Gemstone Colour',
    type: 'text',
    values: [
      'Bi-colour',
      'Black',
      'Blue',
      'Brown',
      'Colourless',
      'Green',
      'Grey',
      'Multicolour',
      'Orange',
      'Pink',
      'Purple',
      'Red',
      'Violet',
      'White',
      'Yellow',
    ],
  },
  {
    name: 'transparency',
    label: 'Transparency',
    type: 'text',
    values: ['Opaque', 'Semi-translucent', 'Semi-transparent', 'Translucent', 'Transparent'],
  },
  {
    name: 'clarityGrade',
    label: 'Clarity Grade',
    type: 'text',
    values: ['Eye clean', 'Heavy', 'Severe', 'Slight', 'Moderate', 'Loupe clean'],
  },
  {
    name: 'labReportNumber',
    label: 'Lab Report Number',
    type: 'text',
    values: [],
  },
  {
    name: 'numberOfStones',
    label: 'Number of Stones',
    type: 'number',
    values: [],
  },
  {
    name: 'totalCaratWeight',
    label: 'Total carat weight',
    type: 'text',
    values: [],
  },
  {
    name: 'opticalEffect',
    label: 'Optical effect',
    type: 'text',
    values: [
      'Adularescence',
      'Asterism',
      'Chatoyancy',
      'Colour change',
      'Fluorescence',
      'Iridescence',
      'Opalescence',
      'Play of colour',
      'Tenebrescence',
    ],
  },
  {
    name: 'cutGrade',
    label: 'Cut grade',
    type: 'text',
    values: ['Excellent', 'Fair', 'Good', 'Poor', 'Very good'],
  },
];
