// src/components/add-auction/AnalyzeModal.tsx

import React, { useState, useRef, useEffect, Suspense } from 'react';
import { Modal, Box, Typography, Button, CircularProgress, Paper, Snackbar, Alert } from '@mui/material';
import { AutoFixHigh } from '@mui/icons-material';
import { ImageItem, AuctionItem } from '../../models/interfaces';
import animationData from './lottie/spin.json';
import apiClient from '../apiClient'; // Import your Axios instance
import { handleAxiosError } from '../../utils/handleAxiosError'; // Import the utility function
import { useAuth } from '../../contexts/AuthContext';

// Dynamically import the Lottie component
const Lottie = React.lazy(() => import('lottie-react'));

interface AnalyzeModalProps {
  open: boolean;
  onClose: () => void;
  images: ImageItem[];
  coverImage: ImageItem | null; // Added this line
  onAnalyzeComplete: (data: any) => void;
  auctionItem: AuctionItem;
}

const AnalyzeModal: React.FC<AnalyzeModalProps> = ({
  open,
  onClose,
  images,
  coverImage,
  onAnalyzeComplete,
  auctionItem,
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [progressMessages, setProgressMessages] = useState<string[]>([]); // State for progress updates
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const analyzeAbortControllerRef = useRef<AbortController | null>(null);
  const { getAccessToken, isLoggedIn, requireAuth } = useAuth(); // Access Auth0 functions
  // Create a ref for the scrollable container
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const handleAnalyze = async () => {
    setSubmitting(true);
    setProgressMessages([]); // Reset progress messages when starting a new analysis
    const controller = new AbortController();
    analyzeAbortControllerRef.current = controller;

    const formData = new FormData();

    // Append coverImage if available
    if (coverImage && !coverImage.existing && coverImage.file) {
      formData.append('coverImage', coverImage.file);
    }

    // Append new images to FormData
    images.forEach((image) => {
      if (!image.existing && image.file) {
        formData.append('images', image.file);
      }
    });

    // Append auctionItem fields
    Object.keys(auctionItem).forEach((key) => {
      if (
        key !== 'product' &&
        key !== 'images' &&
        key !== 'img' &&
        key !== 'bids' &&
        key !== 'quickBids' &&
        key !== '_id'
      ) {
        const value = (auctionItem as any)[key];
        if (Array.isArray(value)) {
          value.forEach((item) => {
            formData.append(key, item);
          });
        } else {
          formData.append(key, String(value));
        }
      }
    });

    // Append product fields
    Object.keys(auctionItem.product).forEach((key) => {
      const value = (auctionItem.product as any)[key];
      if (Array.isArray(value)) {
        value.forEach((item) => {
          formData.append(`product[${key}]`, item);
        });
      } else {
        formData.append(`product[${key}]`, String(value));
      }
    });

    const accessToken = await getAccessToken(); // Get access token from Auth0

    try {
      const response = await fetch('/api/auction-items/analyze', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
        credentials: 'include',
        signal: controller.signal,
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error analyzing auction item:', errorData);
        alert('Failed to analyze auction item.');
        onClose();
        return;
      }
      console.log('Analyze response:', response);

      // Handle the streamed response
      const reader = response.body?.getReader();
      const decoder = new TextDecoder('utf-8');
      let buffer = '';

      if (!reader) {
        console.error('No reader found for response body.');
        return;
      }

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        buffer += decoder.decode(value, { stream: true });

        console.log('Buffer:', buffer);
        // Split the buffer into lines based on SSE message format
        const lines = buffer.split('\n\n');
        buffer = lines.pop() || ''; // Keep any partial message

        for (const line of lines) {
          if (line.startsWith('data:')) {
            const data = line.slice(5).trim();
            if (data.startsWith('{')) {
              // Final auction item data
              const auctionItemData = JSON.parse(data);
              console.log('Final Auction Item:', auctionItemData);
              onAnalyzeComplete(auctionItemData);
              setSuccessMessage('Auction item analyzed successfully.');
              onClose();
            } else {
              // Progress message
              console.log('Progress:', data);
              setProgressMessages((prev) => [...prev, data]); // Update progress messages
            }
          }
        }
      }
    } catch (error: any) {
      if (error.name === 'CanceledError') {
        console.log('Analyze request was aborted');
      } else {
        const message = handleAxiosError(error);
        console.error('Error:', message);
        setErrorMessage('An unexpected error occurred. Please try again.');
      }
    } finally {
      setSubmitting(false);
      analyzeAbortControllerRef.current = null;
    }
  };

  useEffect(() => {
    if (open) {
      handleAnalyze();
    }
    // Cleanup function to abort the analysis if the modal is closed
    return () => {
      if (analyzeAbortControllerRef.current) {
        analyzeAbortControllerRef.current.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // useEffect to scroll to the bottom whenever progressMessages change
  useEffect(() => {
    if (scrollContainerRef.current) {
      // Scroll to the bottom smoothly
      scrollContainerRef.current.scrollTo({
        top: scrollContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [progressMessages]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="analyze-modal" aria-describedby="analyze-auction-item">
      <Box
        position="absolute"
        top="50%"
        left="50%"
        style={{ transform: 'translate(-50%, -50%)' }}
        bgcolor="background.paper"
        p={4}
        display="flex"
        flexDirection="column"
        alignItems="center"
        minWidth={300}
        maxWidth={600}
        borderRadius={2}
        boxShadow={24}
      >
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {/* Optionally, you can use a placeholder image or an icon */}
          <img
            src="/images/analyze.jpg"
            alt="Analyzing"
            style={{ width: '150px', height: '150px', marginRight: '20px' }}
          />

          {/* Use Suspense to wrap the dynamically loaded Lottie component */}
          <Suspense fallback={<CircularProgress />}>
            <Lottie animationData={animationData} style={{ height: '150px', width: '150px' }} />
          </Suspense>
        </div>

        <CircularProgress style={{ marginTop: '20px' }} />

        <Typography variant="h6" gutterBottom style={{ marginTop: '20px', textAlign: 'center' }}>
          Analyzing your auction item...
        </Typography>

        {/* Progress Updates Section */}
        <Box
          component={Paper}
          variant="outlined"
          sx={{
            width: '100%',
            maxHeight: '200px',
            overflowY: 'auto',
            padding: 2,
            marginTop: 2,
            backgroundColor: '#f9f9f9',
          }}
          ref={scrollContainerRef} // Attach the ref here
        >
          <Typography variant="subtitle1" gutterBottom>
            Progress Updates:
          </Typography>
          {progressMessages.length > 0 ? (
            progressMessages.map((msg, index) => (
              <Typography key={index} variant="body2" gutterBottom>
                {msg}
              </Typography>
            ))
          ) : (
            <Typography variant="body2" color="textSecondary">
              No updates yet.
            </Typography>
          )}
        </Box>
        {/* End of Progress Updates Section */}

        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            if (analyzeAbortControllerRef.current) {
              analyzeAbortControllerRef.current.abort();
            }
            setSubmitting(false);
            onClose();
          }}
          style={{ marginTop: '20px' }}
          disabled={!submitting}
          startIcon={<AutoFixHigh />}
        >
          Cancel
        </Button>

        {/* Success Snackbar */}
        <Snackbar
          open={Boolean(successMessage)}
          autoHideDuration={6000}
          onClose={() => setSuccessMessage('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{ width: '100%' }}>
            {successMessage}
          </Alert>
        </Snackbar>

        {/* Error Snackbar */}
        <Snackbar
          open={Boolean(errorMessage)}
          autoHideDuration={6000}
          onClose={() => setErrorMessage('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={() => setErrorMessage('')} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Modal>
  );
};

export default AnalyzeModal;
