// src/components/create-supplier/CreateSupplierPage.tsx

import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Paper,
  Button,
  TextField,
  Grid,
  Snackbar,
  Alert,
  CircularProgress,
  Box,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import apiClient from '../apiClient'; // Import your Axios instance
import { handleAxiosError } from '../../utils/handleAxiosError'; // Import the utility function
import './CreateSupplierPage.css'; // Ensure this file exists and contains your styles

interface Supplier {
  _id: string;
  name: string;
  description: string;
  website: string;
  contactEmail: string;
  contactPhone: string;
  address: {
    street: string;
    city: string;
    state: string;
    zip: string;
    country: string;
  };
  users: any[]; // Array of user objects; adjust type as needed
}

const CreateSupplierPage: React.FC = () => {
  const [supplierData, setSupplierData] = useState<Supplier>({
    _id: '',
    name: '',
    description: '',
    website: '',
    contactEmail: '',
    contactPhone: '',
    address: {
      street: '',
      city: '',
      state: '',
      zip: '',
      country: '',
    },
    users: [],
  });
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');

  // User search states
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
  const [searching, setSearching] = useState<boolean>(false); // Loading state for user search

  useEffect(() => {
    if (id) {
      setIsEditMode(true);
      setLoading(true);
      const fetchSupplier = async () => {
        try {
          const response = await apiClient.get<Supplier>(`/suppliers/${id}`);
          setSupplierData(response.data);
          // Assuming response.data.users contains an array of user objects
          if (response.data.users && Array.isArray(response.data.users)) {
            setSelectedUsers(response.data.users);
          }
        } catch (error: any) {
          const message = handleAxiosError(error);
          console.error('Error fetching supplier:', message);
          setErrorMessage(message);
        } finally {
          setLoading(false);
        }
      };
      fetchSupplier();
    }
  }, [id]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name.startsWith('address.')) {
      const addressField = name.split('.')[1];
      setSupplierData((prev) => ({
        ...prev,
        address: {
          ...prev.address,
          [addressField]: value,
        },
      }));
    } else {
      setSupplierData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleUserSearch = async () => {
    if (!searchQuery) return;
    setSearching(true);
    try {
      const response = await apiClient.get<any[]>(`/user/search?q=${encodeURIComponent(searchQuery)}`);
      setSearchResults(response.data);
    } catch (error: any) {
      const message = handleAxiosError(error);
      console.error('Error searching users:', message);
      setErrorMessage(message);
    } finally {
      setSearching(false);
    }
  };

  const handleAddUser = (user: any) => {
    if (!selectedUsers.find((u) => u._id === user._id)) {
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  const handleRemoveUser = (userId: string) => {
    setSelectedUsers(selectedUsers.filter((user) => user._id !== userId));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const method = isEditMode ? 'PUT' : 'POST';
    const url = isEditMode ? `/suppliers/${id}` : '/suppliers';

    const dataToSubmit = {
      ...supplierData,
      users: selectedUsers.map((user) => user._id),
    };

    setLoading(true);
    try {
      await apiClient.request({
        method: method,
        url: url,
        data: dataToSubmit,
      });
      setSuccessMessage(`Supplier ${isEditMode ? 'updated' : 'created'} successfully.`);
      navigate('/suppliers');
    } catch (error: any) {
      const message = handleAxiosError(error);
      console.error(`Error ${isEditMode ? 'updating' : 'creating'} supplier:`, message);
      setErrorMessage(message);
    } finally {
      setLoading(false);
    }
  };

  // Handler for closing Snackbar alerts
  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage('');
    setSuccessMessage('');
  };

  if (loading && isEditMode) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md" className="create-supplier-container">
      <Typography variant="h4" component="h1" gutterBottom>
        {isEditMode ? 'Edit Supplier' : 'Create Supplier'}
      </Typography>
      <Paper elevation={3} className="paper-container">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {/* Supplier Name */}
            <Grid item xs={12}>
              <TextField
                label="Supplier Name"
                name="name"
                value={supplierData.name}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            {/* Description */}
            <Grid item xs={12}>
              <TextField
                label="Description"
                name="description"
                value={supplierData.description}
                onChange={handleChange}
                fullWidth
                multiline
                rows={4}
              />
            </Grid>
            {/* Website */}
            <Grid item xs={12}>
              <TextField
                label="Website"
                name="website"
                value={supplierData.website}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            {/* Contact Email */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Contact Email"
                name="contactEmail"
                value={supplierData.contactEmail}
                onChange={handleChange}
                fullWidth
                required
                type="email"
              />
            </Grid>
            {/* Contact Phone */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Contact Phone"
                name="contactPhone"
                value={supplierData.contactPhone}
                onChange={handleChange}
                fullWidth
                type="tel"
              />
            </Grid>
            {/* Address Fields */}
            <Grid item xs={12}>
              <Typography variant="h6">Address</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Street"
                name="address.street"
                value={supplierData.address.street}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="City"
                name="address.city"
                value={supplierData.address.city}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="State"
                name="address.state"
                value={supplierData.address.state}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="ZIP Code"
                name="address.zip"
                value={supplierData.address.zip}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Country"
                name="address.country"
                value={supplierData.address.country}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            {/* Assign Users */}
            <Grid item xs={12}>
              <Typography variant="h6">Assign Users</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                label="Search Users"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                fullWidth
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleUserSearch();
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button variant="contained" color="primary" onClick={handleUserSearch} fullWidth disabled={searching}>
                {searching ? <CircularProgress size={24} /> : 'Search'}
              </Button>
            </Grid>

            {/* Display Search Results */}
            {searchResults.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="subtitle1">Search Results:</Typography>
                <ul className="user-list">
                  {searchResults.map((user) => (
                    <li key={user._id} className="user-item">
                      <span>
                        {user.email} - {user.firstName} {user.lastName}
                      </span>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => handleAddUser(user)}
                        disabled={selectedUsers.some((u) => u._id === user._id)}
                      >
                        {selectedUsers.some((u) => u._id === user._id) ? 'Added' : 'Add'}
                      </Button>
                    </li>
                  ))}
                </ul>
              </Grid>
            )}

            {/* Display Selected Users */}
            {selectedUsers.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="subtitle1">Selected Users:</Typography>
                <ul className="user-list">
                  {selectedUsers.map((user) => (
                    <li key={user._id} className="user-item">
                      <span>
                        {user.email} - {user.firstName} {user.lastName}
                      </span>
                      <Button variant="outlined" size="small" onClick={() => handleRemoveUser(user._id)}>
                        Remove
                      </Button>
                    </li>
                  ))}
                </ul>
              </Grid>
            )}
          </Grid>

          {/* Submit Button */}
          <div className="save-button-container" style={{ marginTop: '20px' }}>
            <Button variant="contained" color="primary" type="submit" disabled={loading}>
              {isEditMode ? 'Update Supplier' : 'Create Supplier'}
            </Button>
          </div>
        </form>
      </Paper>

      {/* Success Snackbar */}
      <Snackbar
        open={Boolean(successMessage)}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>

      {/* Error Snackbar */}
      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CreateSupplierPage;
