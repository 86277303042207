// src/components/header/CategoryButton.tsx

import React from 'react';
import { Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import './CategoryButton.css'; // Create if specific styles are needed

interface CategoryButtonProps {
  name: string;
  filter_name: string;
  path: string;
  onCategorySelect: (category: string, filter_name: string) => void;
}

const CategoryButton: React.FC<CategoryButtonProps> = ({ name, filter_name, path, onCategorySelect }) => {
  const handleClick = () => {
    onCategorySelect(name, filter_name);
  };

  return (
    <Button component={RouterLink} to={path} className="header-category" onClick={handleClick}>
      {name}
    </Button>
  );
};

export default CategoryButton;
