// src/pages/HomePage.tsx

import React, { useEffect, useRef, useState } from 'react';
import { Container, Grid2, CircularProgress, Typography } from '@mui/material';
import { GetAuctionsResponse, AuctionItem as AuctionItemType } from '../models/interfaces';
import Categories from '../components/Categories';
import AuctionCardTransition from '../components/AuctionCardTransition';
import { useCookies } from 'react-cookie';
import './HomePage.css';
import { useWebSocket } from '../contexts/WebSocketContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import qs from 'qs';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useParams } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import { useDebounce } from '../contexts/DebounceAuctionListContext';
import { useAuth } from '../contexts/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import apiClient from '../components/apiClient'; // Import your Axios instance
import { handleAxiosError } from '../utils/handleAxiosError'; // Import the utility function

interface HomePageProps {
  filters?: any; // Make filters optional with default value
  onApplyFilters: (filters: any) => void;
  onChatContext: (chatContext: any) => void;
}

const HomePage: React.FC<HomePageProps> = ({
  filters = {}, // Default to empty object
  onApplyFilters,
  onChatContext,
}) => {
  const [cookies] = useCookies(['blinglong']);
  const [items, setItems] = useState<AuctionItemType[]>([]);
  const [statusFilter, setStatusFilter] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null); // Added error state
  const [hasScrolled, setHasScrolled] = useState(false); // Track scroll state
  const { category } = useParams<{ category: string }>();
  const { socket } = useWebSocket();
  const [title, setTitle] = useState<string>('Auction House');
  const location = useLocation();
  const navigate = useNavigate();
  const [supplierDetails, setSupplierDetails] = useState<any>({});

  const { debounceFetch } = useDebounce();
  const { requireAuth } = useAuth(); // Get requireAuth from context

  const titleMap: { [key: string]: string } = {
    jewelry: 'Jewelry',
    Jewelry: 'Jewelry',
    watch: 'Watches',
    Watches: 'Watches',
    watches: 'Watches',
    gemstone: 'Gemstones',
    Gemstones: 'Gemstones',
    diamonds: 'Diamonds',
    Diamonds: 'Diamonds',
    diamond: 'Diamonds',
    myBids: 'My Bids',
    myFavourite: 'My Favorites',
  };

  // Utility function to sanitize filters
  const sanitizeFilters = (filters: any = {}) => {
    const { page, ...rest } = filters;
    return rest;
  };

  const fetchItems = (appliedFilters: any, page: number = 1) => {
    if (isLoading) return;

    setIsLoading(true);
    setError(null);

    const restFilters = sanitizeFilters(appliedFilters || {});

    const params: any = {
      page,
      limit: 20,
    };

    if (statusFilter) {
      params.status = statusFilter;
    }

    if (restFilters) {
      Object.assign(params, restFilters);
    }

    debounceFetch(async (debouncedParams: any) => {
      console.log(`Fetching page: ${debouncedParams.page}`); // Debugging log

      try {
        const response = await apiClient.get<GetAuctionsResponse>(`/auction-items/list`, {
          params: debouncedParams,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'brackets' });
          },
        });
        return response.data;
      } catch (error: any) {
        throw error;
      }
    }, params)
      .then((data) => {
        if (data && Array.isArray(data.auctions)) {
          const fetchedCurrentPage = typeof data.currentPage === 'string' ? Number(data.currentPage) : data.currentPage;

          const fetchedTotalPages = typeof data.totalPages === 'string' ? Number(data.totalPages) : data.totalPages;

          if (isNaN(fetchedCurrentPage) || isNaN(fetchedTotalPages)) {
            console.error('Conversion error: currentPage or totalPages is NaN', data);
            setError('Received invalid pagination data from server.');
            return;
          }

          setItems((prevItems) => (page === 1 ? data.auctions : [...prevItems, ...data.auctions]));
          setCurrentPage(fetchedCurrentPage);
          setTotalPages(fetchedTotalPages);
          console.log(`Fetched page: ${fetchedCurrentPage} of ${fetchedTotalPages}`);
          console.log('Fetched items:', data.auctions);
          console.log('Current filters:', restFilters);
          updateChatContect(data.auctions);
        } else {
          console.error('Unexpected response format:', data);
          setError('Received unexpected data format from server.');
          if (page === 1) setItems([]);
        }
      })
      .catch(async (error: any) => {
        console.error('Error fetching auctions:', error);
        const message = handleAxiosError(error) || 'Failed to load auction items. Please try again.';
        if (error.response && error.response.status === 401) {
          await requireAuth(); // Ensure authentication
        } else {
          setError(message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateChatContect = (auctions: AuctionItemType[]) => {
    const chatContext = auctions.map((auction) => {
      let chatContextItem = {
        id: auction._id,
        title: auction.product.title,
        description: auction.product.description,
        expertEstimateMax: auction.expertEstimateMax,
        expertEstimateMin: auction.expertEstimateMin,
        startDate: auction.startDate,
        endDate: auction.endDate,
        currentBid: auction.currentBid,
        startingPrice: auction.startingPrice,
        category: auction.category,
        isFavorite: auction.isFavorite,
      };
      return chatContextItem;
    });
    onChatContext({ list: chatContext });
  };

  console.log('HomePage filters:', filters);
  // Function to fetch more data
  const fetchMoreData = () => {
    console.log('fetchMoreData called with filters:', filters, 'currentPage:', currentPage);
    if (currentPage >= totalPages || isLoading) return;
    fetchItems(filters || {}, currentPage + 1); // Fallback to empty object if filters is null
  };

  useEffect(() => {
    if (category) {
      setTitle(titleMap[category] || 'Auction House');
      console.log('Category changed:', category);
      const updatedFilters = {
        ...filters,
        categories: [titleMap[category] || category],
      };
      console.log('Updated filters:', updatedFilters);
      onApplyFilters(updatedFilters);
    }
  }, [category]);

  useEffect(() => {
    if (supplierDetails && supplierDetails.name) {
      setTitle(`Items From ${supplierDetails.name}` || 'Auction House');
    }
  }, [supplierDetails]);

  useEffect(() => {
    if (location.pathname.startsWith('/supplier/')) {
      const supplierId = location.pathname.split('/').pop();
      apiClient
        .get(`/suppliers/${supplierId}`)
        .then((response) => {
          if (response.data) {
            setSupplierDetails(response.data);
          }
        })
        .catch((error: any) => {
          const message = handleAxiosError(error) || 'Failed to fetch supplier details.';
          setError(message);
        });
    }
  }, [location]);

  // Initial and filter-based fetching
  useEffect(() => {
    console.log('useEffect triggered with filters:', filters, 'statusFilter:', statusFilter);
    // Reset to first page when filters change
    setItems([]);
    setCurrentPage(1);
    setTotalPages(1);
    fetchItems(filters, 1);
  }, [filters, statusFilter]);

  const handleCategorySelect = (category: string) => {
    console.log('handleCategorySelect called with category:', category);
    const updatedFilters = {
      ...filters,
      categories: [category],
    };
    console.log('Updated filters:', updatedFilters);

    setItems([]);
    setCurrentPage(1);
    setTotalPages(1);
    fetchItems(updatedFilters, 1);
    navigate(`/category/${category}`);
  };

  // Handle real-time bid updates
  useEffect(() => {
    if (socket) {
      const handlePriceUpdate = (data: { auctionId: string; newPrice: number }) => {
        console.log('Received priceUpdated event:', data);
        setItems((prevItems) =>
          prevItems.map((item) => (item._id === data.auctionId ? { ...item, currentBid: data.newPrice } : item))
        );
      };

      // Listen for 'priceUpdated' events
      socket.on('priceUpdated', handlePriceUpdate);

      // Cleanup the listener on unmount
      return () => {
        socket.off('priceUpdated', handlePriceUpdate);
      };
    }
  }, [socket]);

  // Track if the user has scrolled
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setHasScrolled(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Swap items every 5 seconds if user hasn't scrolled
  useEffect(() => {
    let interval: NodeJS.Timeout | null = null; // Explicitly type the interval

    if (!hasScrolled) {
      interval = setInterval(() => {
        if (window.scrollY === 0) {
          setItems((prevItems) => {
            if (prevItems.length >= 12) {
              const newItems = [...prevItems];
              const first6 = newItems.slice(0, 6);
              const next6 = newItems.slice(6, 12);
              newItems.splice(0, 12, ...next6, ...first6);
              return newItems;
            }
            return prevItems;
          });
        }
      }, 5000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [hasScrolled]);

  return (
    <div className="home-page">
      <Container className="categories-container">
        <div className="categories">
          <Categories onCategorySelect={handleCategorySelect} />
        </div>
      </Container>

      <Container className="auctions-container">
        <Typography variant="h3" align="left" gutterBottom>
          {title}
        </Typography>
        <div className="auctions">
          {error && (
            <Typography variant="body1" color="error" align="center">
              {error}
            </Typography>
          )}

          {/* Display "No auction items found." if not loading and no items */}
          {!isLoading && items.length === 0 && !error && (
            <Typography variant="body1" align="center">
              No auction items found.
            </Typography>
          )}

          {/* Only render InfiniteScroll if there are items or loading */}
          {(items.length > 0 || isLoading) && (
            <InfiniteScroll
              dataLength={items.length}
              next={fetchMoreData}
              hasMore={currentPage < totalPages && items.length > 0}
              loader={
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                  <CircularProgress />
                </div>
              }
              endMessage={
                items.length > 0 ? (
                  <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop: '20px' }}>
                    No more items to load.
                  </Typography>
                ) : null
              }
              style={{ overflow: 'visible' }} // Prevent internal scrollbar
            >
              <Grid2 container spacing={{ xs: 1, sm: 2, md: 3, lg: 3 }}>
                <TransitionGroup component={null}>
                  {items.map((item) => (
                    <CSSTransition key={item._id} timeout={500} classNames="item">
                      <AuctionCardTransition key={item._id} item={item} />
                    </CSSTransition>
                  ))}
                </TransitionGroup>
              </Grid2>
            </InfiniteScroll>
          )}

          {/* Optionally, display loader outside InfiniteScroll if needed */}
          {isLoading && items.length === 0 && (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <CircularProgress />
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default HomePage;
