// src/components/auction/AuctionListPage/AuctionFilterSidebar.tsx

import React from 'react';
import {
  Typography,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './AuctionFilterSidebar.css'; // Import CSS specific to AuctionFilterSidebar

interface AuctionFilterSidebarProps {
  statusCategories: { [key: string]: string[] };
  selectedStatuses: string[];
  onStatusChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClearFilters: () => void;
}

const AuctionFilterSidebar: React.FC<AuctionFilterSidebarProps> = ({
  statusCategories,
  selectedStatuses,
  onStatusChange,
  onClearFilters,
}) => {
  return (
    <div className="add-auction-filter-sidebar-container sidebar-visible">
      <div className="custom-filter-sidebar">
        <Typography variant="h6" gutterBottom>
          Auction Staus Filter
        </Typography>
        {Object.entries(statusCategories).map(([phase, statuses]) => (
          <Accordion key={phase}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${phase}-content`} id={`${phase}-header`}>
              <Typography>{phase}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup>
                {statuses.map((status) => (
                  <FormControlLabel
                    key={status}
                    control={
                      <Checkbox
                        checked={selectedStatuses.includes(status)}
                        onChange={onStatusChange}
                        name={status}
                        color="primary"
                      />
                    }
                    label={status}
                  />
                ))}
              </FormGroup>
            </AccordionDetails>
          </Accordion>
        ))}

        {/* Clear Filters Button */}
        {selectedStatuses.length > 0 && (
          <Button
            variant="outlined"
            color="primary"
            onClick={onClearFilters}
            style={{ marginTop: '16px' }}
            fullWidth
            className="clear-all-button"
          >
            Clear Filters
          </Button>
        )}
      </div>
    </div>
  );
};

export default AuctionFilterSidebar;
