// src/components/onboarding/Step3PaymentMethod.tsx

import React, { useState } from 'react';
import { Box, Button, Typography, Checkbox, FormControlLabel, CircularProgress } from '@mui/material';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { PaymentMethod } from './dto/OnboardingDto'; // Import the DTO
import apiClient from '../apiClient'; // Ensure this is correctly set up

interface StepProps {
  onNext: (data: PaymentMethod) => void;
  onSkip: () => void;
  initialData?: {
    paymentMethod: string;
    billingSameAsShipping: boolean;
  };
}

const Step3PaymentMethod: React.FC<StepProps> = ({ onNext, onSkip, initialData }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [paymentMethod, setPaymentMethod] = useState<string>(initialData?.paymentMethod || '');
  const [billingSameAsShipping, setBillingSameAsShipping] = useState<boolean>(
    initialData?.billingSameAsShipping ?? true
  );
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!stripe || !elements) {
      setError('Stripe has not loaded yet.');
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      setError('Card element not found.');
      return;
    }

    setLoading(true);
    setError('');

    try {
      // Create a PaymentMethod in Stripe
      const { error, paymentMethod: stripePaymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        setError(error.message || 'Payment method creation failed.');
        setLoading(false);
        return;
      }

      if (stripePaymentMethod && stripePaymentMethod.id) {
        setPaymentMethod(stripePaymentMethod.id);
        onNext({
          paymentMethod: stripePaymentMethod.id,
          billingSameAsShipping,
        });
      } else {
        setError('Failed to create payment method.');
      }
    } catch (err: any) {
      console.error('Error during payment method creation:', err);
      setError('An unexpected error occurred.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Typography variant="h6" gutterBottom>
        Payment Method
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      <CardElement options={{ hidePostalCode: true }} />
      <FormControlLabel
        control={
          <Checkbox
            checked={billingSameAsShipping}
            onChange={(e) => setBillingSameAsShipping(e.target.checked)}
            color="primary"
          />
        }
        label="Billing address same as shipping address"
      />
      <Box sx={{ flexGrow: 1 }} /> {/* Spacer to push buttons to the bottom */}
      <Box display="flex" justifyContent="space-between" marginTop={3}>
        <Button variant="contained" color="primary" type="submit" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Next'}
        </Button>
        <Button variant="text" onClick={onSkip} disabled={loading}>
          Skip
        </Button>
      </Box>
    </Box>
  );
};

export default Step3PaymentMethod;
