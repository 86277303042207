// src/components/onboarding/OnboardingPage.tsx

import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Navigate } from 'react-router-dom';
import OnboardingStepper from './OnboardingStepper';
import CircularProgress from '@mui/material/CircularProgress';

const OnboardingPage: React.FC = () => {
  const { user, loading } = useAuth();

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
        <CircularProgress />
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/auth/login" />;
  }

  if (user.hasCompletedOnboarding) {
    return <Navigate to="/" />;
  }

  return <OnboardingStepper />;
};

export default OnboardingPage;
