// src/components/DynamicFilterSection.tsx

import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, TextField, Slider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LimitedOptionsList from './LimitedOptionsList';

interface DynamicFilterSectionProps {
  fields: Array<any>;
  dynamicFilterValues: { [key: string]: any };
  onDynamicFilterChange: (name: string, value: any) => void;
}

const DynamicFilterSection: React.FC<DynamicFilterSectionProps> = ({
  fields,
  dynamicFilterValues,
  onDynamicFilterChange,
}) => {
  const handleInputChange = (name: string, value: any) => {
    onDynamicFilterChange(name, value);
  };

  return (
    <div className="dynamic-filter-section">
      {fields.map((field) => (
        <Accordion key={field.name}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">{field.label}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {field.values && field.values.length > 0 ? (
              // Render checkboxes with "Show More/Less" functionality
              <LimitedOptionsList
                field={field}
                dynamicFilterValues={dynamicFilterValues}
                handleInputChange={handleInputChange}
              />
            ) : field.type === 'number' && field.range ? (
              // Render slider for range selection
              <>
                <Slider
                  value={dynamicFilterValues[field.name] || [field.min || 0, field.max || 100]}
                  onChange={(e, newValue) => handleInputChange(field.name, newValue)}
                  valueLabelDisplay="auto"
                  min={field.min || 0}
                  max={field.max || 100}
                  className="filter-slider"
                />
                <Typography className="filter-price-range">
                  {field.label}: ${dynamicFilterValues[field.name]?.[0]} - ${dynamicFilterValues[field.name]?.[1]}
                </Typography>
              </>
            ) : field.type === 'text' ? (
              // Render text input
              <TextField
                label={field.label}
                value={dynamicFilterValues[field.name] || ''}
                onChange={(e) => handleInputChange(field.name, e.target.value)}
                fullWidth
                margin="normal"
                className="filter-search-field"
              />
            ) : field.type === 'number' ? (
              // Render number input
              <TextField
                type="number"
                label={field.label}
                value={dynamicFilterValues[field.name] || ''}
                onChange={(e) => handleInputChange(field.name, e.target.value)}
                fullWidth
                margin="normal"
                className="filter-search-field"
              />
            ) : null}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default DynamicFilterSection;
