// src/components/FilterSection.tsx

import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface FilterSectionProps {
  title: string;
  items: string[];
  selectedItems: string[];
  onToggle: (item: string) => void;
}

const FilterSection: React.FC<FilterSectionProps> = ({ title, items, selectedItems, onToggle }) => {
  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List dense>
          {items.map((item) => (
            <ListItem key={item}>
              <FormControlLabel
                control={
                  <Checkbox checked={selectedItems.includes(item)} onChange={() => onToggle(item)} color="primary" />
                }
                label={item}
              />
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default FilterSection;
