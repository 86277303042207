// src/components/add-auction/renderFields.tsx

import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  Checkbox,
  ListItemText,
  Chip,
  Box,
  Grid2,
} from '@mui/material';
import { CurrentUser } from '../../models/interfaces';
import { SelectChangeEvent } from '@mui/material/Select';
import LockIcon from '@mui/icons-material/Lock';
import { styled } from '@mui/material/styles';
import { NumericFormat } from 'react-number-format';
import { getAllowedStatusTransitions, isValidStatusTransition } from './statusUtils';

// Define the structure of each field
export interface Dependency {
  name: string;
  value: any;
}

export interface NumericFormatChangeEvent {
  target: {
    name: string;
    value: string; // This will be the formatted value
  };
}

export interface FieldDefinition {
  name: string;
  label: string;
  type: string;
  values?: string[];
  multiline?: boolean;
  rows?: number;
  required?: boolean;
  min?: number;
  roles?: string[]; // Changed from 'role' to 'roles' to support multiple roles
  dependentOn?: Dependency;
  withPrevious?: boolean;
  ai_instruction?: string;
}

export interface ValuesType {
  [key: string]: any; // Values can be strings or arrays
}

export type HandleChangeType = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | NumericFormatChangeEvent
) => void;

export type HandleSelectChangeType = (event: SelectChangeEvent<any>) => void;

interface RenderFieldsProps {
  fields: FieldDefinition[];
  handleChange: HandleChangeType;
  handleSelectChange: HandleSelectChangeType;
  values: ValuesType;
  user: CurrentUser;
}

// Styled Components
const CustomChip = styled(Chip)(({ theme }) => ({
  margin: '2px',
  backgroundColor: '#e0e0e0',
  color: 'black',
  fontWeight: 'normal',
  fontFamily: 'Muli, Arial, Helvetica, sans-serif',
  fontSize: '14px',
}));

const CustomCheckbox = styled(Checkbox)({
  color: 'black',
  '&.Mui-checked': {
    color: 'black',
  },
});

// Common MenuProps to limit visible height and style scrollbars
const commonMenuProps = {
  PaperProps: {
    sx: {
      maxHeight: 250, // Adjust as needed
      color: 'grey',
      backgroundColor: 'white',
      '& .MuiMenu-list': {
        padding: 0,
      },
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#f1f1f1',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#888',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#555',
      },
    },
  },
};

// Helper Components

/**
 * Renders a standard Select component with consistent styling.
 */
const RenderSelect: React.FC<{
  field: FieldDefinition;
  availableOptions: string[];
  value: string;
  onChange: (e: SelectChangeEvent<string>) => void;
}> = ({ field, availableOptions, value, onChange }) => (
  <FormControl fullWidth variant="outlined" sx={{ marginTop: '16px' }}>
    <InputLabel>{field.label}</InputLabel>
    <Select
      label={field.label}
      name={field.name}
      value={value || ''}
      onChange={onChange}
      required={field.required || false}
      MenuProps={commonMenuProps}
      sx={{
        '& .MuiOutlinedInput-root': {
          fontFamily: 'Muli, Arial, Helvetica, sans-serif',
          fontSize: '14px',
        },
        '& .MuiSelect-select': {
          padding: '10.5px 14px',
        },
      }}
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
      {availableOptions.map((option) => (
        <MenuItem
          key={option}
          value={option}
          sx={{
            padding: '8px 16px',
            fontFamily: 'Muli, Arial, Helvetica, sans-serif',
            fontSize: '14px',
            color: '#3f51b5',
            '&.Mui-selected': {
              backgroundColor: '#f0f0f0',
            },
            '&.Mui-selected:hover': {
              backgroundColor: '#e0e0e0',
            },
          }}
        >
          {option}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

/**
 * Renders a multi-select component with consistent styling.
 */
const RenderMultiSelect: React.FC<{
  field: FieldDefinition;
  selectedValues: string[];
  onChange: HandleSelectChangeType;
}> = ({ field, selectedValues, onChange }) => (
  <FormControl fullWidth variant="outlined" sx={{ marginTop: '16px' }}>
    <InputLabel>{field.label}</InputLabel>
    <Select
      label={field.label}
      name={field.name}
      multiple
      value={selectedValues || []}
      onChange={onChange}
      renderValue={(selected) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {(selected as string[]).map((value) => (
            <CustomChip key={value} label={value} />
          ))}
        </Box>
      )}
      required={field.required || false}
      MenuProps={commonMenuProps}
      sx={{
        '& .MuiOutlinedInput-root': {
          fontFamily: 'Muli, Arial, Helvetica, sans-serif',
          fontSize: '14px',
        },
        '& .MuiSelect-select': {
          padding: '10.5px 14px',
        },
      }}
    >
      {field.values?.map((option) => (
        <MenuItem
          key={option}
          value={option}
          sx={{
            padding: '8px 16px',
            fontFamily: 'Muli, Arial, Helvetica, sans-serif',
            fontSize: '14px',
            color: '#3f51b5',
            '&.Mui-selected': {
              backgroundColor: '#f0f0f0',
            },
            '&.Mui-selected:hover': {
              backgroundColor: '#e0e0e0',
            },
          }}
        >
          <CustomCheckbox checked={(selectedValues || []).includes(option)} />
          <ListItemText
            primary={option}
            sx={{
              '& .MuiListItemText-primary': {
                fontFamily: 'Muli, Arial, Helvetica, sans-serif',
                fontSize: '14px',
              },
              color: 'black',
            }}
          />
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

/**
 * Renders a numeric input field with formatting and consistent styling.
 */
const RenderNumericField: React.FC<{
  field: FieldDefinition;
  value: string;
  onChange: HandleChangeType;
  isReadOnly: boolean;
}> = ({ field, value, onChange, isReadOnly }) => (
  <NumericFormat
    label={field.label}
    name={field.name}
    value={value || '0.00'}
    onValueChange={({ formattedValue }) => {
      const event: NumericFormatChangeEvent = {
        target: {
          name: field.name,
          value: formattedValue,
        },
      };
      onChange(event);
    }}
    decimalScale={2}
    fixedDecimalScale
    allowNegative={false}
    decimalSeparator="."
    thousandSeparator=","
    valueIsNumericString
    customInput={TextField}
    fullWidth
    variant="outlined"
    InputLabelProps={isReadOnly ? { shrink: true } : {}}
    required={field.required || false}
    InputProps={{
      readOnly: isReadOnly,
      startAdornment: isReadOnly ? (
        <InputAdornment position="start">
          <LockIcon color="disabled" />
        </InputAdornment>
      ) : undefined,
      style: isReadOnly ? { backgroundColor: '#f5f5f5', cursor: 'not-allowed' } : {},
      sx: {
        fontFamily: 'Muli, Arial, Helvetica, sans-serif',
        fontSize: '14px',
      },
    }}
    sx={{
      '& .MuiOutlinedInput-root': {
        fontFamily: 'Muli, Arial, Helvetica, sans-serif',
        fontSize: '14px',
      },
      '& .MuiInputBase-input': {
        padding: '10.5px 14px',
      },
    }}
  />
);

/**
 * Renders a standard TextField with consistent styling.
 */
const RenderTextField: React.FC<{
  field: FieldDefinition;
  value: string;
  onChange: HandleChangeType;
  isReadOnly: boolean;
}> = ({ field, value, onChange, isReadOnly }) => (
  <TextField
    label={field.label}
    name={field.name}
    type={field.type === 'textarea' ? 'text' : field.type}
    value={value || ''}
    onChange={onChange}
    fullWidth
    variant="outlined"
    multiline={field.multiline || false}
    rows={field.multiline ? field.rows || 6 : undefined}
    InputLabelProps={field.type === 'date' || isReadOnly ? { shrink: true } : {}}
    required={field.required || false}
    inputProps={{
      min: field.min,
      readOnly: isReadOnly,
    }}
    InputProps={{
      readOnly: isReadOnly,
      startAdornment: isReadOnly ? (
        <InputAdornment position="start">
          <LockIcon color="disabled" />
        </InputAdornment>
      ) : undefined,
      style: isReadOnly ? { backgroundColor: '#f5f5f5', cursor: 'not-allowed' } : {},
      sx: {
        fontFamily: 'Muli, Arial, Helvetica, sans-serif',
        fontSize: '14px',
      },
    }}
    sx={{
      '& .MuiOutlinedInput-root': {
        fontFamily: 'Muli, Arial, Helvetica, sans-serif',
        fontSize: '14px',
      },
      '& .MuiInputBase-input': {
        padding: '10.5px 14px',
      },
    }}
  />
);

/**
 * The main RenderFields component that organizes and renders fields based on definitions.
 */
const RenderFields: React.FC<RenderFieldsProps> = ({ fields, handleChange, handleSelectChange, values, user }) => {
  if (!fields || fields.length === 0) {
    return null;
  }

  // Filter fields based on user roles
  const filteredFields = fields.filter((field) => {
    if (field.roles && !field.roles.includes(user.role)) {
      return false; // User does not have access to this field
    }
    return true; // Field is accessible
  });

  const rows: FieldDefinition[][] = [];
  let currentRow: FieldDefinition[] = [];

  filteredFields.forEach((field) => {
    // Handle dependency
    if (field.dependentOn) {
      const dependentValue = values[field.dependentOn.name];
      const requiredValue = field.dependentOn.value;

      if (requiredValue === '*') {
        if (dependentValue === '' || dependentValue === 'None') return; // Skip this field if dependency is not met
      } else if (Array.isArray(requiredValue)) {
        if (!requiredValue.includes(dependentValue)) return; // Skip if dependent value is not in the required array
      } else {
        if (dependentValue !== requiredValue) return; // Skip if dependent value does not match
      }
    }

    if (field.withPrevious && currentRow.length > 0) {
      currentRow.push(field);
    } else {
      if (currentRow.length > 0) {
        rows.push([...currentRow]);
        currentRow = [];
      }
      currentRow.push(field);
    }

    // Limit to up to four fields per row for better layout
    if (currentRow.length === 4) {
      rows.push([...currentRow]);
      currentRow = [];
    }
  });

  if (currentRow.length > 0) {
    rows.push([...currentRow]);
  }

  return (
    <Box>
      {rows.map((rowFields, rowIndex) => (
        <Grid2 container spacing={2} key={`row-${rowIndex}`} alignItems="flex-start" marginTop={2}>
          {rowFields.map((field) => {
            const xs = rowFields.length === 1 ? 12 : rowFields.length === 2 ? 6 : 3; // Adjust xs based on number of fields

            // Determine if the field is read-only
            const isReadOnly = field.type === 'readonly';

            let fieldComponent;

            switch (field.type) {
              case 'select':
                if (field.name === 'status') {
                  // Special handling for status field
                  const availableStatuses = getAllowedStatusTransitions(values.status, user.role);

                  fieldComponent = (
                    <RenderSelect
                      field={field}
                      availableOptions={availableStatuses}
                      value={values[field.name]}
                      onChange={handleSelectChange}
                    />
                  );
                } else {
                  fieldComponent = (
                    <RenderSelect
                      field={field}
                      availableOptions={field.values || []}
                      value={values[field.name]}
                      onChange={handleSelectChange}
                    />
                  );
                }
                break;

              case 'multi-select':
                fieldComponent = (
                  <RenderMultiSelect field={field} selectedValues={values[field.name]} onChange={handleSelectChange} />
                );
                break;

              case 'float':
                fieldComponent = (
                  <RenderNumericField
                    field={field}
                    value={values[field.name]}
                    onChange={handleChange}
                    isReadOnly={isReadOnly}
                  />
                );
                break;

              default:
                fieldComponent = (
                  <RenderTextField
                    field={field}
                    value={values[field.name]}
                    onChange={handleChange}
                    isReadOnly={isReadOnly}
                  />
                );
                break;
            }

            return (
              <Grid2 size={xs} key={field.name}>
                {fieldComponent}
              </Grid2>
            );
          })}
        </Grid2>
      ))}
    </Box>
  );
};

export default RenderFields;
