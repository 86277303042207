// src/components/chat/ChatButton.tsx

import React, { useEffect, useState } from 'react';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, Fab, Tooltip } from '@mui/material';
import ChatInterface from './ChatInterface';
import './ChatButton.css';
import { CurrentUser } from '../../models/interfaces';
import { ConfirmationProvider } from '../../contexts/ConfirmationContext';
import { useSnackbar } from 'notistack'; // Import useSnackbar for notifications
import apiClient from '../apiClient'; // Import your Axios instance
import { handleAxiosError } from '../../utils/handleAxiosError'; // Import the utility function
import { useAuth } from '../../contexts/AuthContext'; // Import useAuth

interface ChatButtonProps {
  getChatContext: () => any;
  onOpenFilters: () => void;
  user: CurrentUser | null;
  onApplyFilters: (filters: any) => void;
  filters: any;
}

const ChatButton: React.FC<ChatButtonProps> = ({ getChatContext, onOpenFilters, user, onApplyFilters, filters }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { requireAuth, isLoggedIn } = useAuth(); // Get Auth0 functions
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [sessionNumber, setSessionNumber] = useState<number>(0); // Initialize appropriately
  const [lastInteraction, setLastInteraction] = useState<any>(null); // Define the type based on your data
  const [loading, setLoading] = useState<boolean>(true);

  const loadLastInteraction = async () => {
    try {
      if (!isLoggedIn) return;
      await requireAuth(); // Ensure authentication
      if (!isLoggedIn) {
        enqueueSnackbar('You must be logged in to access chat.', { variant: 'warning' });
        setLoading(false);
        return;
      }

      const response = await apiClient.get('/armond/get_last_interaction', {
        params: { sessionNumber },
      });
      setLastInteraction(response.data.interaction);
    } catch (error: any) {
      console.error('Error loading last interaction:', error);
      const message = handleAxiosError(error) || 'Failed to load last interaction.';
      enqueueSnackbar(message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadLastInteraction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array ensures this runs once on mount

  const handleToggleChat = () => {
    setIsChatOpen((prev) => !prev);
  };

  return (
    <div className="chat-button-container">
      <Tooltip title={isChatOpen ? 'Close Chat' : 'Open Chat'} placement="left">
        <Fab
          className={`chat-button ${isChatOpen ? 'open' : ''}`}
          color={isChatOpen ? 'secondary' : 'primary'}
          aria-label={isChatOpen ? 'Close chat' : 'Open chat'}
          onClick={handleToggleChat}
        >
          {isChatOpen ? <CloseIcon /> : <ChatIcon />}
        </Fab>
      </Tooltip>

      {isChatOpen && (
        <ConfirmationProvider>
          {loading ? (
            <div className="chat-loading">
              <CircularProgress />
            </div>
          ) : (
            <ChatInterface
              sessionNumber={sessionNumber}
              lastInteraction={lastInteraction}
              getChatContext={getChatContext}
              onOpenFilters={onOpenFilters}
              user={user}
              onApplyFilters={onApplyFilters}
              filters={filters}
            />
          )}
        </ConfirmationProvider>
      )}
    </div>
  );
};

export default ChatButton;
