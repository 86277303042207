// src/components/auction/AuctionListPage/AuctionListPage.tsx

import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Paper,
  Grid,
  useMediaQuery,
  Theme,
  Snackbar,
  Alert,
  CircularProgress,
  Box,
} from '@mui/material';
import debounce from 'lodash.debounce';
import { useNavigate } from 'react-router-dom';
import AuctionFilterSidebar from './AuctionFilterSidebar';
import SearchAndToggleControls from './SearchAndToggleControls';
import AuctionTable from './AuctionTable';
import PaginationControls from './PaginationControls';
import DeleteConfirmationDialog from './DeleteConfirmationDialog';
import './AuctionListPage.css'; // Import CSS for AuctionListPage
import { CurrentUser } from '../../../models/interfaces';
import apiClient from '../../apiClient'; // Import the API client
import { handleAxiosError } from '../../../utils/handleAxiosError'; // Import the utility function
import { log } from 'console';
import { getStatusCategories } from '../../add-auction/statusUtils'; // Import the status utility function

interface AuctionItem {
  _id: string;
  product: { title: string; description: string; customerSKU: string };
  // Ensure this property exists
  currentBid: number;
  startingPrice: number;
  status: string;
  startDate: string;
  endDate: string;
  isHotListed: boolean; // Ensure this property exists
  img: string;
}

interface AuctionListPageProps {
  loginUser: CurrentUser;
}

const AuctionListPage: React.FC<AuctionListPageProps> = ({ loginUser }) => {
  const [auctions, setAuctions] = useState<AuctionItem[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortOption, setSortOption] = useState<string>(''); // '' signifies no sorting
  const [onlyMyAuctions, setOnlyMyAuctions] = useState(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Notifications
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  // Delete Confirmation Dialog
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedAuctionId, setSelectedAuctionId] = useState<string | null>(null);

  // Status Filters
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);

  const navigate = useNavigate();
  const limit = 10;

  // Media Query for Large Screens (≥1200px)
  const isLargeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg')); // 'lg' typically ≥1200px

  // Fetch Auctions with Sorting and Status Filters
  const fetchAuctions = async (
    pageNumber = 1,
    query = '',
    sortOption: string = '',
    selectedStatuses: string[] = []
  ) => {
    setIsLoading(true);
    try {
      const queryParams = new URLSearchParams({
        q: query,
        page: pageNumber.toString(),
        limit: limit.toString(),
        myAuctions: onlyMyAuctions.toString(),
      });

      if (sortOption) {
        queryParams.append('sort', sortOption);
      }

      let statusVec: string[] = [];
      selectedStatuses.forEach((status) => {
        if (queryParams.has('status')) {
          statusVec = queryParams.get('status')?.split(',') || [];
        }
        if (status === 'Hot Items') {
          queryParams.append('isHotListed', true.toString());
        } else {
          statusVec.push(status);
        }
      });
      if (statusVec.length > 0) {
        queryParams.append('status', statusVec.join(','));
      }

      const response = await apiClient.get<{ auctions: AuctionItem[]; totalPages: number }>(
        `/auction-items/list?${queryParams.toString()}`
      );

      setAuctions(response.data.auctions); // Ensure data.auctions includes startingPrice and isHotListed
      setTotalPages(response.data.totalPages);
    } catch (error: any) {
      console.error('Error fetching auctions:', error);
      const message = handleAxiosError(error) || 'Failed to fetch auctions';
      setErrorMessage(message);
    } finally {
      setIsLoading(false);
    }
  };

  // Initial Fetch and on Dependency Changes
  useEffect(() => {
    fetchAuctions(page, searchQuery, sortOption, selectedStatuses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchQuery, onlyMyAuctions, sortOption, selectedStatuses]);

  // Debounced Search Handler
  const debouncedSearch = React.useMemo(
    () =>
      debounce((query: string) => {
        setSearchQuery(query);
        setPage(1);
      }, 500),
    []
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(event.target.value);
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  // Toggle "Only My Auctions"
  const handleToggleOnlyMyAuctions = () => {
    setOnlyMyAuctions((prev) => !prev);
    setPage(1);
  };

  // Handle Sorting
  const handleSort = (
    field: 'currentBid' | 'endDate' | 'startDate' | 'title' | 'description' | 'status' | 'startingPrice' | 'customerSKU'
  ) => {
    switch (field) {
      case 'currentBid':
        if (sortOption === 'priceLowToHigh') {
          setSortOption('priceHighToLow');
        } else {
          setSortOption('priceLowToHigh');
        }
        break;
      case 'endDate':
        if (sortOption === 'timeToClose') {
          setSortOption('');
        } else {
          setSortOption('timeToClose');
        }
        break;
      case 'startDate':
        if (sortOption === 'timeToStart') {
          setSortOption('');
        } else {
          setSortOption('timeToStart');
        }
        break;
      case 'title':
        if (sortOption === 'titleAsc') {
          setSortOption('titleDesc');
        } else {
          setSortOption('titleAsc');
        }
        break;
      case 'description':
        if (sortOption === 'descriptionAsc') {
          setSortOption('descriptionDesc');
        } else {
          setSortOption('descriptionAsc');
        }
        break;
      case 'status':
        if (sortOption === 'statusAsc') {
          setSortOption('statusDesc');
        } else {
          setSortOption('statusAsc');
        }
        break;
      case 'startingPrice': // Handle Starting Price Sorting
        if (sortOption === 'startingPriceAsc') {
          setSortOption('startingPriceDesc');
        } else {
          setSortOption('startingPriceAsc');
        }
        break;
      case 'customerSKU': // Handle Customer SKU Sorting
        if (sortOption === 'customerSKUAsc') {
          setSortOption('customerSKUDesc');
        } else {
          setSortOption('customerSKUAsc');
        }
        break;
      default:
        setSortOption('');
        break;
    }
    setPage(1);
  };

  // Handle Status Filter Changes
  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const status = event.target.name;
    if (event.target.checked) {
      setSelectedStatuses((prev) => [...prev, status]);
    } else {
      setSelectedStatuses((prev) => prev.filter((s) => s !== status));
    }
    setPage(1);
  };

  // Clear All Filters
  const handleClearFilters = () => {
    setSelectedStatuses([]);
    setPage(1);
  };

  // Handle Delete Click
  const handleDeleteClick = (auctionId: string) => {
    setSelectedAuctionId(auctionId);
    setOpenDeleteModal(true);
  };

  // Confirm Deletion
  const handleDeleteConfirm = async () => {
    if (!selectedAuctionId) return;

    try {
      await apiClient.delete(`/auction-items/${selectedAuctionId}`);
      setAuctions((prevAuctions) => prevAuctions.filter((auction) => auction._id !== selectedAuctionId));
      setSuccessMessage('Auction item deleted successfully.');
    } catch (error: any) {
      const message = handleAxiosError(error) || 'Failed to delete auction';
      console.error('Error deleting auction:', message);
      setErrorMessage(message);
    } finally {
      setOpenDeleteModal(false);
      setSelectedAuctionId(null);
    }
  };

  // Cancel Deletion
  const handleDeleteCancel = () => {
    setOpenDeleteModal(false);
    setSelectedAuctionId(null);
  };

  // Handle Page Change
  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  // Handler for closing Snackbar alerts
  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage('');
    setSuccessMessage('');
  };

  // Implementing the Hot List Toggle Handler
  const handleToggleHotList = async (auctionId: string) => {
    try {
      // Make an API call to toggle the hot list status
      // This assumes you have an endpoint like PUT /auction-items/{id}/hot-listed
      const hotListParams = {
        isHotListed: !auctions.find((auction) => auction._id === auctionId)?.isHotListed,
      };
      const response = await apiClient.post(`/auction-items/${auctionId}/hot-listed`, hotListParams);

      // Update the auction in the state
      setAuctions((prevAuctions) =>
        prevAuctions.map((auction) =>
          auction._id === auctionId ? { ...auction, isHotListed: !auction.isHotListed } : auction
        )
      );

      // Show a success message based on the new status
      setSuccessMessage(response.data.isHotListed ? 'Auction removed from Hot List.' : 'Auction added to Hot List.');
    } catch (error: any) {
      const message = handleAxiosError(error) || 'Failed to toggle Hot List status';
      console.error('Error toggling Hot List:', message);
      setErrorMessage(message);
    }
  };

  return (
    <div className="auction-list-page-container">
      {/* Fixed Filter Sidebar */}
      {isLargeScreen && (
        <AuctionFilterSidebar
          statusCategories={getStatusCategories(loginUser?.role)} // Pass the status categories based on user role
          selectedStatuses={selectedStatuses}
          onStatusChange={handleStatusChange}
          onClearFilters={handleClearFilters}
        />
      )}

      {/* Main Content */}
      <div
        className="main-content"
        style={{
          marginLeft: isLargeScreen ? '300px' : '0', // Adjust margin based on sidebar width
          maxWidth: '100%',
          margin: '0 auto',
          padding: '20px',
        }}
      >
        {/* Search and Toggle Controls */}
        <SearchAndToggleControls
          searchQuery={searchQuery}
          onSearchChange={handleSearchChange}
          onlyMyAuctions={onlyMyAuctions}
          onToggleOnlyMyAuctions={handleToggleOnlyMyAuctions}
        />

        {/* Auction Table */}
        <Paper style={{ marginTop: '20px' }}>
          {isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="300px">
              <CircularProgress />
            </Box>
          ) : (
            <AuctionTable
              auctions={auctions}
              isLoading={isLoading}
              sortOption={sortOption}
              onSort={handleSort}
              onDelete={handleDeleteClick}
              onToggleHotList={handleToggleHotList} // Pass the handler
            />
          )}
        </Paper>

        {/* Pagination Controls */}
        <PaginationControls currentPage={page} totalPages={totalPages} onPageChange={handlePageChange} />
      </div>

      {/* Delete Confirmation Dialog */}
      <DeleteConfirmationDialog open={openDeleteModal} onConfirm={handleDeleteConfirm} onCancel={handleDeleteCancel} />

      {/* Notifications */}
      <Snackbar
        open={Boolean(successMessage)}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AuctionListPage;
