// src/components/CountdownTimer.tsx

import React from 'react';
import Countdown, { CountdownRendererFn } from 'react-countdown';
import { Typography } from '@mui/material';

// CountdownTimer component
interface CountdownTimerProps {
  endDate: string;
  trailString?: string;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ endDate, trailString = 'left' }) => {
  // Helper function to handle pluralization
  const pluralize = (value: number, singular: string, plural: string, trail: string) => {
    return `${value} ${value === 1 ? singular : plural} ${trail}`;
  };

  // Countdown render function
  const renderer: CountdownRendererFn = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a message when the countdown completes
      return (
        <Typography
          color="error"
          sx={{
            fontSize: { xs: '1rem', sm: '1rem', md: '1.2rem', lg: '1.3rem' },
            fontWeight: 500,
          }}
        >
          Auction Ended
        </Typography>
      );
    } else {
      let display: string;

      if (days > 0) {
        // If there are days left, display days only
        display = pluralize(days, 'day', 'days', trailString);
      } else if (hours > 0) {
        // If less than a day but hours are left, display hours only
        display = pluralize(hours, 'hour', 'hours', trailString);
      } else if (minutes > 5) {
        // If less than an hour but more than 5 minutes, display minutes only
        display = pluralize(minutes, 'minute', 'minutes', trailString);
      } else {
        // If less than or equal to 5 minutes, display minutes and seconds
        const minuteText = pluralize(minutes, 'minute', 'minutes', trailString);
        const secondText = pluralize(seconds, 'second', 'seconds', trailString);
        display = `${minuteText}, ${secondText}`;
      }

      return (
        <Typography
          sx={{
            fontSize: { xs: '1rem', sm: '1rem', md: '1rem', lg: '2rem' },
            fontWeight: 600,
            color: 'text.primary',
          }}
        >
          {display}
        </Typography>
      );
    }
  };

  return <Countdown date={new Date(endDate)} renderer={renderer} />;
};

export default CountdownTimer;
