// src/components/onboarding/Step2AddressInfo.tsx

import React, { useState, useCallback } from 'react';
import { Box, Button, TextField, Typography, Autocomplete } from '@mui/material';
import axios from 'axios';
import { OnboardingAddressInfo } from './dto/OnboardingDto';
import debounce from 'lodash.debounce';

interface StepProps {
  onNext: (data: OnboardingAddressInfo) => void;
  onSkip: () => void;
  initialData: OnboardingAddressInfo;
}

const Step2AddressInfo: React.FC<StepProps> = ({ onNext, onSkip, initialData }) => {
  const [street, setStreet] = useState<string>(initialData.street); // Renamed from 'address' to 'street'
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [aptSuite, setAptSuite] = useState<string>(initialData.aptSuite || '');
  const [company, setCompany] = useState<string>(initialData.company || '');
  const [country, setCountry] = useState<string>(initialData.country);
  const [state, setState] = useState<string>(initialData.state);
  const [city, setCity] = useState<string>(initialData.city);
  const [zip, setZip] = useState<string>(initialData.zip);
  const [error, setError] = useState<string>('');

  const fetchAddressSuggestions = async (input: string) => {
    if (input.length < 4) {
      setSuggestions([]);
      return;
    }

    try {
      const response = await axios.get(`https://maps.googleapis.com/maps/api/place/autocomplete/json`, {
        params: {
          input: input,
          key: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
          types: 'address',
          components: `country:${country.toLowerCase()}`, // Adjust based on selected country
        },
      });
      if (response.data.predictions) {
        setSuggestions(response.data.predictions.map((p: any) => p.description));
      }
    } catch (err) {
      console.error('Error fetching address suggestions:', err);
      setSuggestions([]);
    }
  };

  // Debounced version to prevent excessive API calls
  const debouncedFetch = useCallback(debounce(fetchAddressSuggestions, 500), [country]);

  const handleAddressChange = (value: string) => {
    setStreet(value);
    debouncedFetch(value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Basic validation
    if (!street.trim() || !country.trim() || !state.trim() || !city.trim() || !zip.trim()) {
      setError('Please fill in all required fields.');
      return;
    }
    setError('');

    const addressInfo: OnboardingAddressInfo = {
      street: street.trim(), // Changed from 'address' to 'street'
      country: country.trim(),
      state: state.trim(),
      city: city.trim(),
      zip: zip.trim(),
      aptSuite: aptSuite.trim(),
      company: company.trim(),
    };

    onNext(addressInfo);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Typography variant="h6" gutterBottom>
        Address Information
      </Typography>
      {error && <Typography color="error">{error}</Typography>}

      {/* Address Autocomplete */}
      <Autocomplete
        freeSolo
        options={suggestions}
        onInputChange={(event, newInputValue) => {
          handleAddressChange(newInputValue);
        }}
        renderInput={(params) => <TextField {...params} label="Address" margin="normal" required />}
        value={street}
        onChange={(event, newValue) => {
          if (newValue) {
            setStreet(newValue);
            setSuggestions([]);
          }
        }}
      />

      {/* Apt/Suite Field */}
      <TextField
        label="Apt/Suite/etc (Optional)"
        fullWidth
        margin="normal"
        value={aptSuite}
        onChange={(e) => setAptSuite(e.target.value)}
      />

      {/* Company Field */}
      <TextField
        label="Company (Optional)"
        fullWidth
        margin="normal"
        value={company}
        onChange={(e) => setCompany(e.target.value)}
      />

      {/* Country Field */}
      <TextField
        label="Country"
        fullWidth
        margin="normal"
        value={country}
        onChange={(e) => setCountry(e.target.value)}
        required
      />

      {/* State Field */}
      <TextField
        label="State"
        fullWidth
        margin="normal"
        value={state}
        onChange={(e) => setState(e.target.value)}
        required
      />

      {/* City Field */}
      <TextField
        label="City"
        fullWidth
        margin="normal"
        value={city}
        onChange={(e) => setCity(e.target.value)}
        required
      />

      {/* Zip/Postal Code Field */}
      <TextField
        label="Zip/Postal Code"
        fullWidth
        margin="normal"
        value={zip}
        onChange={(e) => setZip(e.target.value)}
        required
      />

      {/* Spacer to push buttons to the bottom */}
      <Box sx={{ flexGrow: 1 }} />

      {/* Navigation Buttons */}
      <Box display="flex" justifyContent="space-between" marginTop={3}>
        <Button variant="contained" color="primary" type="submit">
          Next
        </Button>
        <Button variant="text" onClick={onSkip}>
          Skip
        </Button>
      </Box>
    </Box>
  );
};

export default Step2AddressInfo;
