// src/contexts/chat/AppContext.tsx

import React, { createContext, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

interface AppContextProps {
  summarizeScreen: () => string;
  changeScreen: (path: string) => void;
  // Add any other shared functions or data
}

export const AppContext = createContext<AppContextProps>({
  summarizeScreen: () => '',
  changeScreen: () => {},
});

interface AppProviderProps {
  children: ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const navigate = useNavigate();

  const summarizeScreen = () => {
    // Implement logic to summarize the current screen
    return 'Screen summary...';
  };

  const changeScreen = (path: string) => {
    navigate(path);
  };

  return <AppContext.Provider value={{ summarizeScreen, changeScreen }}>{children}</AppContext.Provider>;
};
