// src/components/Header/Header.tsx

import React from 'react';
import useHeader from './useHeader';
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';
import './Header.css'; // Shared styles if any

interface HeaderProps {
  onOpenFilter?: () => void;
  user: {
    displayName: string;
    role: string;
  } | null;
  onLogout: () => void;
  onApplyFilters: (filters: any) => void;
  filters: any;
  onCloseFilter: () => void;
}

const Header: React.FC<HeaderProps> = ({ onOpenFilter, user, onLogout, onApplyFilters, filters, onCloseFilter }) => {
  const header = useHeader(filters, onApplyFilters, onCloseFilter);

  return (
    <div className="header-container">
      {header.isDesktop ? (
        <HeaderDesktop {...header} user={user} onLogout={onLogout} filters={filters} onOpenFilter={onOpenFilter} />
      ) : (
        <HeaderMobile {...header} user={user} onLogout={onLogout} filters={filters} onOpenFilter={onOpenFilter} />
      )}
    </div>
  );
};

export default Header;
