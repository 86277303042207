// src/components/chat/useRecognition.ts

import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import timeWithMilliseconds from './timeWithMilliseconds';

// Define the interface for the props passed to useRecognition
interface UseRecognitionProps {
  getLanguage: () => string;
  voiceRecordingFinish: (currentText: string) => void;
  voiceFinalTranscriptUpdate: (text: string) => void;
  voiceListeningStopped: () => void;
  getCurrentText: () => string;
  audioSourceRef: React.RefObject<AudioBufferSourceNode | null>;
  isPlaying: React.MutableRefObject<boolean>;
  playbackText: React.MutableRefObject<string>;
  audioContextRef: React.RefObject<AudioContext | null>;
  setListeningState: React.Dispatch<React.SetStateAction<boolean>>;
  stopAudio: () => void;
  swEchoCancellation: React.MutableRefObject<boolean>;
  talkStatusUpdate: (status: string) => void;
  inFlightRequest: boolean;
  submitCount: number;
}

export function useRecognition({
  getLanguage,
  voiceRecordingFinish,
  voiceFinalTranscriptUpdate,
  voiceListeningStopped,
  getCurrentText,
  audioSourceRef,
  isPlaying,
  playbackText,
  audioContextRef,
  setListeningState,
  stopAudio,
  swEchoCancellation,
  talkStatusUpdate,
  inFlightRequest,
  submitCount,
}: UseRecognitionProps) {
  const { t } = useTranslation();

  // Refs
  const listening = useRef<boolean>(false);
  type SpeechRecognition = typeof window.SpeechRecognition;

  interface ExtendedWindow extends Window {
    SpeechRecognition: SpeechRecognition;
    webkitSpeechRecognition: SpeechRecognition;
  }
  const SpeechRecognitionClass =
    (window as ExtendedWindow).SpeechRecognition || (window as ExtendedWindow).webkitSpeechRecognition;
  const recognitionRef = useRef<InstanceType<SpeechRecognition> | null>(null);
  const pauseTimeoutRef = useRef<ReturnType<typeof setTimeout>[]>([]);
  const stoppedPressedRef = useRef<boolean>(false);
  const recognitionRestart = useRef<boolean>(false);
  const prevFinalTranscriptCopyRef = useRef<string>('');
  const prevInterimTranscriptCopyRef = useRef<string>('');
  const interimTranscriptNotCleared = useRef<string>('');
  const startingText = useRef<string>('');
  const ignoreTranscriptDuringPlayback = useRef<string>('');
  const isInitialized = useRef<boolean>(false);
  const stopTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const mediaStreamRef = useRef<MediaStream | null>(null);
  const noSpeechRestartCount = useRef<number>(0);
  const languageRef = useRef<string>('');
  const ignoreInterimTranscriptAlreadySent = useRef<string>('');

  // State
  const [isStopping, setIsStopping] = useState<boolean>(false);
  const [lastSubmitCount, setLastSubmitCount] = useState<number>(0);

  const PAUSE_DELAY = 3000;
  const debug = true;

  useEffect(() => {
    if (submitCount !== lastSubmitCount) {
      setLastSubmitCount(submitCount);
      if (debug) console.log(timeWithMilliseconds(), 'Submit Count Changed. Clearing Transcript...');
      clearTranscript();
    }
  }, [submitCount, lastSubmitCount]);

  // Function to clear all timers
  const clearTimers = () => {
    pauseTimeoutRef.current.forEach((timerId) => clearTimeout(timerId));
    pauseTimeoutRef.current = [];
  };

  // Function to clear the transcript and restart recognition if needed
  const clearTranscript = () => {
    if (debug) console.log(timeWithMilliseconds(), 'Clearing Transcript... restart recognition');
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      recognitionRestart.current = true;
    }
  };

  // Function to clear local transcript copies
  const clearLocalTexts = () => {
    prevFinalTranscriptCopyRef.current = '';
    startingText.current = '';
  };

  // Function to forcefully stop recognition
  const forceStopRecognition = () => {
    if (recognitionRef.current) {
      recognitionRef.current.abort();
      setIsStopping(false);
      setListeningState(false);
      listening.current = false;
      stoppedPressedRef.current = false;
      clearTimers();
      voiceRecordingFinish('');
    }
  };

  // Function to clear the last middle word from the transcript
  const clearLastMiddleWord = (word: string, finalTranscript: string): string => {
    const numWords = word.split(' ').length;
    const finalTranscriptArray = finalTranscript.split(' ');
    const lastWord = finalTranscriptArray.pop() || '';

    // Check if the last word is a prefix of the word
    if (word.toLowerCase().includes(lastWord.toLowerCase())) {
      for (let i = 0; i < numWords - 1; i++) {
        finalTranscriptArray.pop();
      }
      return finalTranscriptArray.join(' ');
    }
    return finalTranscript;
  };

  // Initialize Speech Recognition
  const initRecognition = () => {
    if (isInitialized.current) {
      return;
    }

    // Access the SpeechRecognition API
    const SpeechRecognitionClass = (window.SpeechRecognition || (window as any).webkitSpeechRecognition) as
      | typeof SpeechRecognition
      | undefined;
    if (!SpeechRecognitionClass) {
      console.error('SpeechRecognition is not supported in this browser.');
      return;
    }

    const recognition = new SpeechRecognitionClass();

    recognition.continuous = true;
    recognition.interimResults = true;
    //recognition.lang = getLanguage();
    recognition.lang = 'en-US';
    recognition.maxAlternatives = 1;

    if (debug) console.log(timeWithMilliseconds(), '############# Recognition: initRecognition', recognition);

    recognition.onstart = () => {
      if (debug) console.log(timeWithMilliseconds(), 'Voice recognition onstart activated');
      try {
        listening.current = true;
        setListeningState(true);
        stoppedPressedRef.current = false;
        prevFinalTranscriptCopyRef.current = '';
        ignoreTranscriptDuringPlayback.current = '';
        clearTimers();
      } catch (error) {
        console.error('Error in onstart:', error);
      }
    };

    recognition.onend = () => {
      ignoreInterimTranscriptAlreadySent.current = '';
      if (recognitionRestart.current) {
        recognitionRestart.current = false;
        recognitionRef.current?.start();
        return;
      }

      if (debug) console.log(timeWithMilliseconds(), 'Voice recognition onend deactivated', stoppedPressedRef.current);

      if (!stoppedPressedRef.current) {
        if (debug) console.log(timeWithMilliseconds(), 'Voice recognition onend restarting...', recognitionRef.current);
        recognitionRef.current?.start();
        return;
      }

      listening.current = false;
      setListeningState(false);

      if (!stoppedPressedRef.current) {
        voiceRecordingFinish('');
      }

      clearTimers();
      setIsStopping(false); // Reset the stopping flag
      if (stopTimeoutRef.current) {
        clearTimeout(stopTimeoutRef.current); // Clear the force stop timeout
        stopTimeoutRef.current = null;
      }
    };

    recognition.onresult = (event: SpeechRecognitionEvent) => {
      if (stoppedPressedRef.current || recognitionRestart.current) return;

      let interimTranscript = '';
      let finalTranscript = '';

      for (let i = event.resultIndex; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {
          finalTranscript += event.results[i][0].transcript;
        } else {
          interimTranscript += event.results[i][0].transcript;
        }
      }

      if (finalTranscript === '' && interimTranscript === '') {
        if (debug)
          console.log(
            timeWithMilliseconds(),
            'Impossible to have both final and interim transcript empty. Ignoring...'
          );
        return;
      }

      if (interimTranscript !== '') {
        if (debug) console.log(timeWithMilliseconds(), 'Interim Transcript:', interimTranscript);
      }

      if (finalTranscript !== '') {
        if (debug) console.log(timeWithMilliseconds(), 'Final Transcript:', finalTranscript);
      }

      // Every time we get a result, we need to clear the timers
      clearTimers();

      let backupInterimTranscript = interimTranscript;

      if (interimTranscriptNotCleared.current !== '') {
        if (finalTranscript !== '') {
          interimTranscriptNotCleared.current = '';
        } else {
          if (debug)
            console.log(
              timeWithMilliseconds(),
              'Ignoring interim transcript interimTranscriptNotCleared: ',
              interimTranscriptNotCleared.current
            );
          if (interimTranscript.startsWith(interimTranscriptNotCleared.current)) {
            interimTranscript = interimTranscript.substring(interimTranscriptNotCleared.current.length);
            if (debug) console.log(timeWithMilliseconds(), 'Interim Transcript after ignoring: ', interimTranscript);
          }
        }
      }

      if (
        prevInterimTranscriptCopyRef.current.toLowerCase().includes(t('talkInterface.stopPlay')) &&
        interimTranscript.toLowerCase().includes(t('talkInterface.stopPlay'))
      ) {
        // Need to clean this since we already stopped the playback
        interimTranscript = interimTranscript.replace(t('talkInterface.stopPlay'), '');
      }

      if (
        prevInterimTranscriptCopyRef.current.toLowerCase().includes(t('talkInterface.stopPlay')) &&
        finalTranscript.toLowerCase().includes(t('talkInterface.stopPlay'))
      ) {
        // Need to clean this since we already stopped the playback
        finalTranscript = finalTranscript.replace(t('talkInterface.stopPlay'), '');
        // In case this is final transcript, we need to clear the ignore transcript since there is no way it can penetrate the next final transcript
        ignoreTranscriptDuringPlayback.current = '';
      }

      prevInterimTranscriptCopyRef.current = interimTranscript;

      noSpeechRestartCount.current = 0;

      // Control word detection
      let currentTextPrefix = '';
      let currentText = '';

      if (ignoreInterimTranscriptAlreadySent.current !== '') {
        if (debug)
          console.log(
            timeWithMilliseconds(),
            'Ignoring interim transcript ignoreInterimTranscriptAlreadySent: ',
            ignoreInterimTranscriptAlreadySent.current
          );
        if (interimTranscript.includes(ignoreInterimTranscriptAlreadySent.current)) {
          interimTranscript = interimTranscript.replace(ignoreInterimTranscriptAlreadySent.current, '');
          if (debug) console.log(timeWithMilliseconds(), 'Interim Transcript after ignoring: ', interimTranscript);
        }
        if (finalTranscript !== '') {
          if (finalTranscript.includes(ignoreInterimTranscriptAlreadySent.current.trim())) {
            finalTranscript = finalTranscript.replace(ignoreInterimTranscriptAlreadySent.current.trim(), '');
            if (debug)
              console.log(
                timeWithMilliseconds(),
                'Final Transcript includes ignoreInterimTranscriptAlreadySent. Clearing it: ',
                finalTranscript
              );
          }
          ignoreInterimTranscriptAlreadySent.current = '';
        }
      }

      currentTextPrefix = startingText.current.length > 0 ? startingText.current.trim() : '';
      currentTextPrefix +=
        prevFinalTranscriptCopyRef.current.length > 0 ? ' ' + prevFinalTranscriptCopyRef.current.trim() : '';
      currentTextPrefix = currentTextPrefix.trim();
      currentText = finalTranscript.length > 0 ? finalTranscript : interimTranscript;
      currentText = `${currentTextPrefix} ${currentText.trim()}`.trim();

      if (debug) console.log(timeWithMilliseconds(), 'currentText: ', currentText);

      // Handle control words
      if (
        interimTranscript.toLowerCase().includes(t('talkInterface.stopPlay')) ||
        finalTranscript.toLowerCase().includes(t('talkInterface.stopPlay'))
      ) {
        if (isPlaying.current) {
          if (audioSourceRef.current) {
            prevFinalTranscriptCopyRef.current = clearLastMiddleWord(
              t('talkInterface.stopPlay'),
              prevFinalTranscriptCopyRef.current
            );
            if (debug) console.log(timeWithMilliseconds(), 'Call stop.');
            if (audioSourceRef.current) {
              audioSourceRef.current.disconnect();
            }
            isPlaying.current = false;
            playbackText.current = '';
            if (swEchoCancellation.current) {
              clearTranscript();
            } else {
              interimTranscriptNotCleared.current = backupInterimTranscript;
            }
            stopAudio();

            // In case this is final transcript, we need to clear the ignore transcript since there is no way it can penetrate the next final transcript
            if (finalTranscript.toLowerCase().includes(t('talkInterface.stopPlay'))) {
              ignoreTranscriptDuringPlayback.current = '';
            }
          } else {
            if (debug)
              console.log(
                timeWithMilliseconds(),
                'No audio source REF... Really weird. Ignoring control word.',
                t('talkInterface.stopPlay')
              );
          }
        } else {
          if (finalTranscript.toLowerCase().includes(t('talkInterface.stopPlay'))) {
            ignoreTranscriptDuringPlayback.current = '';
          }
          if (debug)
            console.log(
              timeWithMilliseconds(),
              'Playback is not in progress. Ignoring control word.',
              t('talkInterface.stopPlay')
            );
        }
        return;
      }

      if (
        interimTranscript.toLowerCase().includes(t('talkInterface.stopListen')) ||
        finalTranscript.toLowerCase().includes(t('talkInterface.stopListen'))
      ) {
        const newFinal = clearLastMiddleWord(t('talkInterface.stopListen'), currentText);
        voiceFinalTranscriptUpdate(newFinal);
        stopListening('Control Word Stop')();
        return;
      }

      if (
        interimTranscript.toLowerCase().includes(t('talkInterface.sendText')) ||
        finalTranscript.toLowerCase().includes(t('talkInterface.sendText'))
      ) {
        const newFinal = clearLastMiddleWord(t('talkInterface.sendText'), currentText);
        if (debug) console.log(timeWithMilliseconds(), 'Send Transcript: ##', newFinal, '##', currentText, '##');
        voiceFinalTranscriptUpdate(newFinal);
        clearLocalTexts();
        voiceRecordingFinish(currentText);
        clearTranscript();
        if (finalTranscript === '') {
          // We are sending the transcript. Based on the interim only, need to make sure if the next interim will include this one need to ignore it.
          ignoreInterimTranscriptAlreadySent.current = interimTranscript;
        }
        return;
      }

      if (inFlightRequest) {
        if (debug) console.log(timeWithMilliseconds(), 'In Flight Request. Ignoring transcript.', interimTranscript);
        ignoreTranscriptDuringPlayback.current = interimTranscript;
        return;
      }

      if (isPlaying.current) {
        if (debug)
          console.log(timeWithMilliseconds(), 'Playback is in progress. Ignoring transcript.', interimTranscript);
        ignoreTranscriptDuringPlayback.current = interimTranscript;
        return;
      }

      if (ignoreTranscriptDuringPlayback.current !== '') {
        if (
          interimTranscript.length > ignoreTranscriptDuringPlayback.current.length &&
          interimTranscript.includes(ignoreTranscriptDuringPlayback.current)
        ) {
          ignoreTranscriptDuringPlayback.current = interimTranscript;
        }
        if (debug) console.log(timeWithMilliseconds(), 'Ignoring transcript: ', ignoreTranscriptDuringPlayback.current);
        interimTranscript = interimTranscript.replace(ignoreTranscriptDuringPlayback.current, '');

        // Clear the ignore transcript after using it in final transcript
        if (finalTranscript !== '') {
          finalTranscript = finalTranscript.replace(ignoreTranscriptDuringPlayback.current, '');
          ignoreTranscriptDuringPlayback.current = '';
        }

        // Extract the last word from playbackText and compare it to Interim Transcript
        const lastWord = playbackText.current.split(' ').pop() || '';
        if (lastWord.toLowerCase().includes(interimTranscript)) {
          if (debug)
            console.log(
              timeWithMilliseconds(),
              'Interim Transcript includes last word of playbackText. Ignoring transcript.'
            );
          return;
        }
      }

      // Filter out playback text from final transcript
      if (debug) {
        console.log(
          'playbackText.current, ',
          playbackText.current,
          'playbackText.current.length, ',
          playbackText.current.length,
          'finalTranscript, ',
          finalTranscript,
          'finalTranscript.length, ',
          finalTranscript.length,
          'playbackText.current.includes(finalTranscript), ',
          playbackText.current.includes(finalTranscript)
        );
      }

      voiceFinalTranscriptUpdate(currentText);

      if (finalTranscript && !(playbackText.current.length > 0 && finalTranscript.includes(playbackText.current))) {
        if (prevFinalTranscriptCopyRef.current !== '') {
          finalTranscript = `${prevFinalTranscriptCopyRef.current.trim()} ${finalTranscript.trim()}`;
        }
        prevFinalTranscriptCopyRef.current = finalTranscript;
        if (debug) console.log(timeWithMilliseconds(), 'Pushing Transcript: ', currentText);
        if (stoppedPressedRef.current) {
          recognitionRef.current?.stop();
          if (debug)
            console.log(
              timeWithMilliseconds(),
              '####### Voice recognition paused. Stopping... stoppedPressed ',
              currentText
            );
          return;
        }
      }

      if (interimTranscript || finalTranscript) {
        if (debug) console.log(timeWithMilliseconds(), 'Setting Timeout: currentText: ', currentText);
        const timerId = setTimeout(() => {
          if (debug)
            console.log(timeWithMilliseconds(), 'Voice recognition paused. Stopping... setTimeout', currentText);
          voiceRecordingFinish(currentText);
          clearLocalTexts();
          if (swEchoCancellation.current) {
            // This will clear all transcripts
            clearTranscript();
          } else {
            if (finalTranscript === '') {
              // Need to make sure if the next interim will include this one need to ignore it.
              ignoreInterimTranscriptAlreadySent.current = interimTranscript;
            }
          }
        }, PAUSE_DELAY);
        pauseTimeoutRef.current.push(timerId);
      }
    };

    recognition.onerror = (event: SpeechRecognitionErrorEvent) => {
      if (event.error === 'no-speech') {
        if (debug) console.log(timeWithMilliseconds(), 'No speech detected. Restarting recognition...');
        noSpeechRestartCount.current += 1;
        if (noSpeechRestartCount.current < 5) {
          clearTranscript();
        } else {
          noSpeechRestartCount.current = 0;
          console.log('No speech detected. Allow Stop');
        }
      } else {
        console.error('Error occurred in recognition:', event.error, event.message, event);
      }
    };

    recognitionRef.current = recognition;
    isInitialized.current = true;
  };

  // Effect to initialize recognition when the language changes
  useEffect(() => {
    if (!('SpeechRecognition' in window) && !('webkitSpeechRecognition' in window)) {
      alert('Your browser does not support Speech Recognition. Try Google Chrome.');
      return;
    }

    const currentLanguage = getLanguage();
    if (currentLanguage !== languageRef.current) {
      if (languageRef.current !== '') {
        languageRef.current = currentLanguage;
        if (debug) console.log(timeWithMilliseconds(), 'Recognition: Language changed. Initializing recognition...');
        initRecognition();
      } else {
        languageRef.current = currentLanguage;
      }
    }
  }, [getLanguage]); // Only re-run if the language getter changes

  // Function to start listening
  const startListening = async () => {
    if (!isInitialized.current) {
      initRecognition();
      talkStatusUpdate('start-listening');
    }

    if (debug) console.log(timeWithMilliseconds(), '----------- 1. Voice recognition started');
    if (debug)
      console.log(
        timeWithMilliseconds(),
        'Recognition: startListening',
        listening.current,
        recognitionRef.current,
        isStopping,
        isInitialized.current
      );

    if (!listening.current && recognitionRef.current && !isStopping && isInitialized.current) {
      if (debug) console.log(timeWithMilliseconds(), 'Recognition: startListening', getCurrentText());
      startingText.current = getCurrentText();
      listening.current = true;
      setListeningState(true);
      stoppedPressedRef.current = false;
      clearTimers();
      try {
        recognitionRef.current.start();
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true,
          },
        });
        mediaStreamRef.current = stream;
      } catch (error) {
        console.error('Error starting recognition in startListening:', error);
      }
    }
  };

  // Function to stop listening
  const stopListening = (reason: string) => () => {
    if (debug)
      console.log(timeWithMilliseconds(), '----------- 2. Voice recognition stopListening. Stopping...', reason);
    if (listening.current && recognitionRef.current && !isStopping) {
      listening.current = false;
      setListeningState(false);
      stoppedPressedRef.current = true;
      clearTimers();
      setIsStopping(true);
      recognitionRef.current.stop();

      if (reason !== 'sendTranscript') {
        voiceListeningStopped();
      }

      stopTimeoutRef.current = setTimeout(() => {
        if (debug) console.log(timeWithMilliseconds(), 'Force stopping recognition');
        forceStopRecognition();
      }, 1000);

      prevFinalTranscriptCopyRef.current = '';
    }
  };

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
        if (debug) console.log(timeWithMilliseconds(), 'Closing Audio Context !!!!!!!!!!!!!!!!!!!!!!!!!');
        audioContextRef.current.close();
      }
      if (mediaStreamRef.current) {
        if (debug) console.log(timeWithMilliseconds(), 'Stopping Media Stream !!!!!!!!!!!!!!!!!!!!!!!!!');
        mediaStreamRef.current.getTracks().forEach((track) => track.stop());
      }
      if (audioSourceRef.current) {
        if (debug) console.log(timeWithMilliseconds(), 'Stopping Audio Source !!!!!!!!!!!!!!!!!!!!!!!!!');
        if (audioSourceRef.current) {
          audioSourceRef.current.disconnect();
        }
      }
    };
  }, []);

  return {
    listening: listening.current,
    startListening,
    stopListening,
    clearTranscript,
  };
}
