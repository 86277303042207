// src/components/users/EditUserPage.tsx

import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Paper,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  CircularProgress,
  Box,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import ProfileTabs from '../settings/ProfileTabs';
import { UserSettings } from '../../models/UserSettings';
import '../settings/styles/UserProfileSettingsPage.css';
import { CurrentUser } from '../../models/interfaces';
import apiClient from '../apiClient'; // Import your Axios instance
import { handleAxiosError } from '../../utils/handleAxiosError'; // Import the utility function

interface EditUserPageProps {
  loginUser: CurrentUser;
}

const EditUserPage: React.FC<EditUserPageProps> = ({ loginUser }) => {
  const { id } = useParams<{ id: string }>(); // Get user ID from the URL
  const [originalSettings, setOriginalSettings] = useState<UserSettings | null>(null);
  const [editedUserSettings, setEditedUserSettings] = useState<UserSettings | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserSettings = async () => {
      try {
        const response = await apiClient.get(`/user/${id}`);
        const userData = response.data;

        const settings: UserSettings = {
          personalInfo: {
            firstName: userData.firstName || '',
            lastName: userData.lastName || '',
            displayName: userData.displayName || '',
            dateJoined: userData.dateJoined || '',
          },
          contactInfo: {
            email: userData.email || '',
            phoneNumber: userData.phoneNumber || '',
          },
          addressInfo: {
            billingAddress: userData.billingAddress || {
              street: '',
              city: '',
              state: '',
              zip: '',
              country: '',
            },
            shippingAddress: userData.shippingAddress || {
              street: '',
              city: '',
              state: '',
              zip: '',
              country: '',
            },
          },
          preferences: {
            hobbies: userData.hobbies || '',
            receiveNewsletters: userData.receiveNewsletters || false,
            receivePromotions: userData.receivePromotions || false,
            receiveUpdates: userData.receiveUpdates || false,
          },
          accountDetails: {
            dateJoined: userData.dateJoined || '',
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: '',
          },
          role: userData.role,
        };

        setOriginalSettings(settings);
        setEditedUserSettings(settings);
      } catch (error: any) {
        console.error('Error fetching user settings:', error);
        const message = handleAxiosError(error) || 'Failed to fetch user data.';
        setErrorMessage(message);
      }
    };

    fetchUserSettings();
  }, [id]);

  const handleSave = async () => {
    if (!editedUserSettings) return;
    setIsSaving(true);
    try {
      const settingsToUpdate = {
        personalInfo: editedUserSettings.personalInfo,
        contactInfo: editedUserSettings.contactInfo,
        addressInfo: editedUserSettings.addressInfo,
        preferences: editedUserSettings.preferences,
        role: editedUserSettings.role, // Include role in the update
      };

      await apiClient.put(`/user/${id}`, settingsToUpdate); // Ensure backend handles role update
      setOriginalSettings(editedUserSettings);
      setSuccessMessage('User settings saved successfully!');
    } catch (error: any) {
      console.error('Error saving user settings:', error);
      const message = handleAxiosError(error) || 'Failed to save user settings.';
      setErrorMessage(message);
    } finally {
      setIsSaving(false);
    }
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage('');
    setSuccessMessage('');
  };

  if (!editedUserSettings) {
    return (
      <Container className="loading-container">
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" className="main-container">
      <Typography variant="h4" component="h1" gutterBottom>
        Edit User Profile
      </Typography>
      <Paper elevation={3} className="paper-container">
        <ProfileTabs
          userSettings={editedUserSettings}
          setUserSettings={setEditedUserSettings as React.Dispatch<React.SetStateAction<UserSettings>>}
          originalSettings={originalSettings!}
        />

        {/* Conditional Role Field */}
        <FormControl fullWidth margin="normal" disabled={loginUser.role !== 'Admin'}>
          <InputLabel id="role-select-label">Role</InputLabel>
          {loginUser.role === 'Admin' ? (
            <Select
              labelId="role-select-label"
              id="role-select"
              value={editedUserSettings.role}
              label="Role"
              onChange={(e) =>
                setEditedUserSettings((prev) => (prev ? { ...prev, role: e.target.value as string } : prev))
              }
            >
              <MenuItem value="User">LoginUser</MenuItem>
              <MenuItem value="Supplier">Supplier</MenuItem>
              <MenuItem value="Admin">Admin</MenuItem>
              {/* Add more roles as needed */}
            </Select>
          ) : (
            <Typography variant="body1" style={{ padding: '14px 0 0 14px' }}>
              {editedUserSettings.role}
            </Typography>
          )}
        </FormControl>

        <div className="save-button-container">
          <Button variant="contained" color="primary" onClick={handleSave} disabled={isSaving}>
            {isSaving ? <CircularProgress size={24} /> : 'Save All Changes'}
          </Button>
          <Button
            variant="outlined"
            color="warning"
            style={{ marginLeft: '10px' }}
            onClick={() => navigate('/users')}
            disabled={isSaving}
          >
            Cancel
          </Button>
        </div>
      </Paper>

      {/* Success Snackbar */}
      <Snackbar
        open={Boolean(successMessage)}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>

      {/* Error Snackbar */}
      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default EditUserPage;
