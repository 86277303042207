// src/components/onboarding/PhoneNumberInput.tsx

import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { styled } from '@mui/material/styles';
import CountrySelector from './CountrySelector'; // Import the updated CountrySelector
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import './styles/PhoneNumberInput.css'; // Optional: Import additional CSS if needed

type PhoneNumberInputProps = Omit<TextFieldProps, 'onChange' | 'value'> & {
  value: string | undefined;
  onChange: (value: string | undefined) => void;
};

const StyledTextField = styled(TextField)({
  // Add any custom styles here if necessary
});

const PhoneNumberInput = React.forwardRef<HTMLInputElement, PhoneNumberInputProps>((props, ref) => {
  const { value, onChange, ...otherProps } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Parse phone number to get country code and country
  const parsedNumber = value ? parsePhoneNumberFromString(value) : undefined;
  const countryCode = parsedNumber?.country || 'US'; // Default to 'US' if not available

  return (
    <Box
      display="flex"
      flexDirection={isMobile ? 'column' : 'row'}
      alignItems={isMobile ? 'stretch' : 'center'}
      gap={isMobile ? 2 : 1}
      width="100%"
    >
      <StyledTextField
        {...otherProps}
        value={value || ''}
        onChange={(e) => onChange(e.target.value)}
        inputRef={ref}
        variant="outlined"
        fullWidth
        inputMode="tel" // Triggers numeric keypad on mobile
        placeholder="1234567890"
        sx={{
          flex: 1, // Ensure the TextField takes up the remaining space
        }}
      />
    </Box>
  );
});

export default PhoneNumberInput;
