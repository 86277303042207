import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import './ConfirmationContext.css';

interface ConfirmationContextProps {
  confirm: (options: ConfirmationOptions) => Promise<boolean>;
}

interface ConfirmationOptions {
  title: string;
  description: string;
  confirmText?: string;
  cancelText?: string;
}

const ConfirmationContext = createContext<ConfirmationContextProps | undefined>(undefined);

export const useConfirmation = (): ConfirmationContextProps => {
  const context = useContext(ConfirmationContext);
  if (!context) {
    throw new Error('useConfirmation must be used within a ConfirmationProvider');
  }
  return context;
};

export const ConfirmationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<ConfirmationOptions | null>(null);
  const [promise, setPromise] = useState<(value: boolean) => void>(() => () => {});

  const confirm = (options: ConfirmationOptions): Promise<boolean> => {
    setOptions(options);
    setOpen(true);
    return new Promise<boolean>((resolve) => {
      setPromise(() => resolve);
    });
  };

  const handleClose = (result: boolean) => {
    setOpen(false);
    if (promise) {
      promise(result);
    }
  };

  return (
    <div className="confirmation-dialog-container">
      <ConfirmationContext.Provider value={{ confirm }}>
        {children}
        <Dialog
          open={open}
          onClose={() => handleClose(false)}
          aria-labelledby="confirmation-dialog-title"
          aria-describedby="confirmation-dialog-description"
          className="confirmation-dialog"
        >
          <DialogTitle id="confirmation-dialog-title">{options?.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="confirmation-dialog-description">{options?.description}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose(false)} color="primary">
              {options?.cancelText || 'Cancel'}
            </Button>
            <Button onClick={() => handleClose(true)} color="primary" autoFocus>
              {options?.confirmText || 'Confirm'}
            </Button>
          </DialogActions>
        </Dialog>
      </ConfirmationContext.Provider>
    </div>
  );
};
