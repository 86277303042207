// src/contexts/DebounceContext.tsx

import React, { createContext, useRef, useContext } from 'react';
import isEqual from 'lodash/isEqual';
import qs from 'qs';

interface DebounceAuctionListContextProps {
  debounceFetch: (fetchFunction: (params: any) => Promise<any>, params: any) => Promise<any>;
}

// Define the shape of pending promises with both resolve and reject
interface PendingPromise {
  resolve: (value: any) => void;
  reject: (error: any) => void;
}

interface CacheEntry {
  data: any;
  timestamp: number;
}

const DebounceContext = createContext<DebounceAuctionListContextProps | undefined>(undefined);

export const DebounceAuctionListProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const desiredTTL = 10000; // 10 seconds
  const debounceRef = useRef<{
    params: any | null;
    timerId: NodeJS.Timeout | null;
    cache: Map<string, any>;
    // Update the type to store both resolve and reject
    pendingPromises: Map<string, Array<PendingPromise>>;
  }>({
    params: null,
    timerId: null,
    cache: new Map(),
    pendingPromises: new Map(),
  });

  const debounceFetch = (fetchFunction: (params: any) => Promise<any>, params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      const paramsKey = qs.stringify(params, { arrayFormat: 'brackets' });

      // If response is cached, return it immediately
      const cacheEntry = debounceRef.current.cache.get(paramsKey);
      if (cacheEntry && Date.now() - cacheEntry.timestamp < desiredTTL) {
        resolve(cacheEntry.data);
        return;
      } else {
        debounceRef.current.cache.delete(paramsKey);
      }

      // Check if there's already a pending request with the same params
      if (debounceRef.current.pendingPromises.has(paramsKey)) {
        // Add both resolve and reject to the pending promises list
        debounceRef.current.pendingPromises.get(paramsKey)?.push({ resolve, reject });
        return;
      } else {
        // Initialize the pending promises list with both resolve and reject
        debounceRef.current.pendingPromises.set(paramsKey, [{ resolve, reject }]);
      }

      // Debounce logic
      if (debounceRef.current.params && isEqual(params, debounceRef.current.params)) {
        if (debounceRef.current.timerId) {
          clearTimeout(debounceRef.current.timerId);
        }
        debounceRef.current.timerId = setTimeout(() => {
          executeFetch(fetchFunction, params, paramsKey);
          debounceRef.current.timerId = null;
        }, 300);
      } else {
        if (debounceRef.current.timerId) {
          clearTimeout(debounceRef.current.timerId);
          debounceRef.current.timerId = null;
        }
        executeFetch(fetchFunction, params, paramsKey);
      }
      debounceRef.current.params = params;
    });
  };

  const executeFetch = async (fetchFunction: (params: any) => Promise<any>, params: any, paramsKey: string) => {
    try {
      const response = await fetchFunction(params);
      // Cache the response
      debounceRef.current.cache.set(paramsKey, { data: response, timestamp: Date.now() });
      // Resolve all pending promises
      debounceRef.current.pendingPromises.get(paramsKey)?.forEach(({ resolve }) => {
        resolve(response);
      });
    } catch (error) {
      // Handle errors appropriately by rejecting all pending promises
      debounceRef.current.pendingPromises.get(paramsKey)?.forEach(({ reject }) => {
        reject(error);
      });
    } finally {
      // Clean up pending promises
      debounceRef.current.pendingPromises.delete(paramsKey);
    }
  };

  return <DebounceContext.Provider value={{ debounceFetch }}>{children}</DebounceContext.Provider>;
};

export const useDebounce = () => {
  const context = useContext(DebounceContext);
  if (!context) {
    throw new Error('useDebounce must be used within a DebounceAuctionListProvider');
  }
  return context;
};
