// src/components/HorizontalAuctionList.tsx

import React from 'react';
import { Box, Typography, useTheme, useMediaQuery, IconButton } from '@mui/material';
import { AuctionItem as AuctionItemType } from '../models/interfaces';
import AuctionCard from './AuctionCard';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { useRef } from 'react';
import { useEffect } from 'react';

interface HorizontalAuctionListProps {
  title: string;
  items: AuctionItemType[];
  img: string;
  titleColor?: string;
  titleLocation?: object;
}

const HorizontalAuctionList: React.FC<HorizontalAuctionListProps> = ({ title, items, img, titleColor = 'white', titleLocation}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  // Scroll functions
  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    }
  };

  // Optional: Handle responsive adjustments or other side effects
  useEffect(() => {
    // Any side effects if needed
  }, [isMobile]);

  return (
    <Box sx={{ margin: '50px 0 40px 0', position: 'relative', overflow: 'hidden' }}>
      {/* Title Section */}
      <Box
        sx={{
          backgroundImage: `url(${img})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: { xs: '150px', sm: '300px', md: '350px' },
          borderRadius: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          marginLeft: { xs: '8px', sm: '30px' },
          marginRight: { xs: '0px', sm: '30px' },
          paddingLeft: { xs: '10px', sm: '30px' },
          position: 'relative',
          zIndex: 1,
        }}
      >
        <Typography
          variant={isMobile ? 'h4' : 'h2'}
          sx={{
            color: titleColor,
            fontWeight: 400,
            fontFamily: 'Rubik, sans-serif',
            fontSize: { xs: '1.5rem', sm: '2rem' },
            ...titleLocation,
            letterSpacing: { xs: '1px', sm: '2px' },
            textShadow: '1px 1px 4px rgba(0, 0, 0, 0.7)',
          }}
          dangerouslySetInnerHTML={{ __html: title }}
        ></Typography>
      </Box>

      {/* Scroll Buttons */}
      <IconButton
        onClick={scrollLeft}
        aria-label="Scroll Left"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '10px',
          transform: 'translateY(-50%)',
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 1)',
          },
          zIndex: 2,
        }}
      >
        <ArrowBackIos />
      </IconButton>
      <IconButton
        onClick={scrollRight}
        aria-label="Scroll Right"
        sx={{
          position: 'absolute',
          top: '50%',
          right: '10px',
          transform: 'translateY(-50%)',
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 1)',
          },
          zIndex: 2,
        }}
      >
        <ArrowForwardIos />
      </IconButton>

      {/* Auction Items List */}
      <Box
        ref={scrollContainerRef}
        sx={{
          display: 'flex',
          overflowX: 'auto',
          scrollbarWidth: 'thin',
          scrollbarColor: 'rgba(0, 0, 0, 0.2) transparent',
          paddingBottom: '10px',
          gap: '16px',
          scrollBehavior: 'smooth',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          marginTop: '20px',
        }}
      >
        {items.map((item) => (
          <Box
            key={item._id}
            sx={{
              flex: '0 0 auto',
              width: { xs: '200px', sm: '250px', md: '300px' },
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <AuctionCard item={item} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default HorizontalAuctionList;
