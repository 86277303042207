// src/components/settings/ContactInfo.tsx

import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography, TextField } from '@mui/material';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { UserSettings } from '../../models/UserSettings';
import './styles/ContactInfo.css';
import PhoneNumberInput from '../onboarding/PhoneNumberInput'; // Adjust the import path as needed

interface ContactInfoProps {
  contactInfo: UserSettings['contactInfo'];
  setUserSettings: React.Dispatch<React.SetStateAction<UserSettings>>;
  originalContactInfo: UserSettings['contactInfo'];
}

const ContactInfo: React.FC<ContactInfoProps> = ({ contactInfo, setUserSettings, originalContactInfo }) => {
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(contactInfo.phoneNumber || '');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    setPhoneNumber(contactInfo.phoneNumber || '');
  }, [contactInfo.phoneNumber]);

  useEffect(() => {
    if (phoneNumber && !isValidPhoneNumber(phoneNumber)) {
      setError('Invalid phone number.');
    } else {
      setError('');
    }
  }, [phoneNumber]);

  const handlePhoneChange = (value: string | undefined) => {
    setPhoneNumber(value);
    setUserSettings((prev) => ({
      ...prev,
      contactInfo: {
        ...prev.contactInfo,
        phoneNumber: value || '',
      },
    }));
  };

  const isChanged = (field: keyof UserSettings['contactInfo']) => {
    return contactInfo[field] !== originalContactInfo[field];
  };

  return (
    <form className="form-container">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Email"
            name="email"
            type="email"
            fullWidth
            variant="outlined"
            required
            value={contactInfo.email}
            onChange={(e) =>
              setUserSettings((prev) => ({
                ...prev,
                contactInfo: {
                  ...prev.contactInfo,
                  email: e.target.value,
                },
              }))
            }
            className={isChanged('email') ? 'changed-field' : ''}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ width: '100%' }}>
            <Typography variant="subtitle1" gutterBottom>
              Phone Number
            </Typography>
            <PhoneInput
              international
              defaultCountry="US"
              value={phoneNumber}
              onChange={handlePhoneChange}
              name="phone"
              autoComplete="tel"
              className="mui-phone-input"
              inputComponent={PhoneNumberInput}
              style={{ width: '100%' }}
            />
            {error && (
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default ContactInfo;
