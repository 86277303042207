// src/components/chat/timeWithMilliseconds.ts

/**
 * Returns the current time formatted as HH:MM:SS.mmm
 * where mmm represents milliseconds.
 * Example: "14:30:15.123"
 */
export default function timeWithMilliseconds(): string {
  const date = new Date();
  const timeString = date.toLocaleTimeString('en-GB', {
    // Use 'en-GB' for 24-hour format
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false, // Ensure 24-hour format
  });
  const milliseconds = date.getMilliseconds();

  return `${timeString}.${milliseconds.toString().padStart(3, '0')}`;
}
