// src/components/add-auction/statusUtils.ts

type StatusCategories = { [key: string]: string[] };

export const getStatusCategories = (role: string) => {
  const categories: StatusCategories = {
    'Supplier Phase': ['Draft', 'Adjustment', 'Submitted'],
    'Auction Phase': ['Upcoming', 'Active', 'Active - Minimum Reach'],
    'Post Sale': ['Sold', 'Shipment', 'Closed', 'Returning', 'Returned'],
  };

  if (role === 'Admin') {
    categories['Admin Phase'] = ['Approved', 'Need clarification', 'Denied', 'Hot Items'];
  }

  return categories;
};

export const getAllowedStatusTransitions = (currentStatus: string, userRole: string): string[] => {
  if (userRole === 'Admin') {
    // Admin can change to any status
    return Object.values(getStatusCategories(userRole)).flat();
  } else if (userRole === 'Supplier') {
    // Supplier can change status to 'Draft' and 'Submitted' regardless of current status
    const allowed = ['Draft', 'Submitted'];
    // Include current status to ensure it's visible
    if (!allowed.includes(currentStatus)) {
      return [currentStatus, ...allowed];
    }
    return allowed;
  }
  // Default: no transitions allowed
  return [];
};

export const isValidStatusTransition = (currentStatus: string, newStatus: string, userRole: string): boolean => {
  const allowedStatuses = getAllowedStatusTransitions(currentStatus, userRole);
  return allowedStatuses.includes(newStatus);
};
