// src/components/supplier/SupplierListPage.tsx

import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Snackbar,
  Alert,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { Edit as EditIcon } from '@mui/icons-material';
import './SupplierListPage.css'; // Import the new CSS file
import apiClient from '../apiClient';
import { handleAxiosError } from '../../utils/handleAxiosError';

interface Supplier {
  _id: string;
  name: string;
  contactEmail: string;
  contactPhone: string;
  // Add other fields as needed
}

const SupplierListPage: React.FC = () => {
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false); // Loading state for fetching suppliers

  // Fetch suppliers on component mount
  useEffect(() => {
    const fetchSuppliers = async () => {
      setLoading(true);
      try {
        const response = await apiClient.get<Supplier[]>('/suppliers');
        setSuppliers(response.data);
      } catch (error: any) {
        const message = handleAxiosError(error);
        console.error('Error fetching suppliers:', message);
        setErrorMessage(message);
      } finally {
        setLoading(false);
      }
    };

    fetchSuppliers();
  }, []);

  // Handler for deleting a supplier
  const handleDelete = async (id: string) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this supplier?');
    if (!confirmDelete) return;

    try {
      await apiClient.delete(`/suppliers/${id}`);
      setSuppliers((prev) => prev.filter((supplier) => supplier._id !== id));
      setSuccessMessage('Supplier deleted successfully.');
    } catch (error: any) {
      const message = handleAxiosError(error);
      console.error('Error deleting supplier:', message);
      setErrorMessage(message);
    }
  };

  // Handler for editing a supplier
  const handleEdit = (id: string) => {
    navigate(`/suppliers/edit/${id}`);
  };

  // Handler for closing Snackbar alerts
  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage('');
    setSuccessMessage('');
  };

  return (
    <div className="supplier-list-page-container">
      <Container maxWidth="md" className="supplier-main-container">
        <Typography variant="h4" component="h1" gutterBottom>
          Suppliers
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/create-supplier')}
          className="create-supplier-button"
        >
          Create Supplier
        </Button>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Contact Email</TableCell>
                  <TableCell>Contact Phone</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {suppliers.map((supplier) => (
                  <TableRow key={supplier._id}>
                    <TableCell>{supplier.name}</TableCell>
                    <TableCell>{supplier.contactEmail}</TableCell>
                    <TableCell>{supplier.contactPhone}</TableCell>
                    <TableCell align="right">
                      <IconButton color="primary" onClick={() => navigate(`/edit-supplier/${supplier._id}`)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton color="error" onClick={() => handleDelete(supplier._id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {suppliers.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No suppliers found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        {/* Success Snackbar */}
        <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={() => setSuccessMessage('')}>
          <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{ width: '100%' }}>
            {successMessage}
          </Alert>
        </Snackbar>

        {/* Error Snackbar */}
        <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={() => setErrorMessage('')}>
          <Alert onClose={() => setErrorMessage('')} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>
      </Container>
    </div>
  );
};

export default SupplierListPage;
