// src/utils/handleAxiosError.ts

import { AxiosError } from 'axios';

export const handleAxiosError = (error: any): string => {
  if (error.isAxiosError) {
    const axiosError = error as AxiosError;
    if (axiosError.response) {
      // Server responded with a status other than 2xx
      const data = axiosError.response.data as { message?: string };
      return data.message || `Error: ${axiosError.response.statusText}`;
    } else if (axiosError.request) {
      // Request was made but no response received
      return 'No response received from server.';
    } else {
      // Something else happened while setting up the request
      return axiosError.message;
    }
  }
  // Non-Axios error
  return error.message || 'An unknown error occurred.';
};
