// src/components/chat/ChatMessage.tsx

import React from 'react';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import './ChatMessage.css';

interface Message {
  content: string;
  role: string;
  question_name?: string;
  progress?: string;
}

interface ChatMessageProps {
  message: Message;
  onDelete: (index: number) => void;
  onEdit: (index: number) => void;
  isLatestUserMessage: boolean;
  index: number;
}

const ChatMessage: React.FC<ChatMessageProps> = ({ message, onDelete, onEdit, isLatestUserMessage, index }) => {
  const content = message.content;
  return (
    <div className={`chat-message-container ${isLatestUserMessage && message.role === 'user' ? 'last-message' : ''}`}>
      <div className={`chat-message ${message.role !== 'user' ? 'bot-message' : 'user-message'}`}>
        {content} {isLatestUserMessage}
        {message.role === 'user' && isLatestUserMessage && (
          <div className="chat-message-button-container">
            <button className="delete-button" onClick={() => onDelete(index)}>
              <FaTrashAlt />
            </button>
            <button className="edit-button" onClick={() => onEdit(index)}>
              <FaEdit />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatMessage;
