// src/components/auction/AuctionListPage/PaginationControls.tsx

import React from 'react';
import { Pagination } from '@mui/material';

interface PaginationControlsProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
}

const PaginationControls: React.FC<PaginationControlsProps> = ({ currentPage, totalPages, onPageChange }) => {
  return (
    <Pagination
      count={totalPages}
      page={currentPage}
      onChange={onPageChange}
      color="primary"
      showFirstButton
      showLastButton
      style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}
    />
  );
};

export default PaginationControls;
