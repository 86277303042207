// src/components/AuctionCardTransition.tsx

import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Grid2 } from '@mui/material';
import AuctionCard from './AuctionCard';
import { AuctionItem } from '../models/interfaces';

interface AuctionCardTransitionProps {
  item: AuctionItem;
}

const AuctionCardTransition: React.FC<AuctionCardTransitionProps> = ({ item }) => {
  const nodeRef = useRef<HTMLDivElement>(null);

  return (
    <CSSTransition key={item._id} timeout={500} classNames="auction-item" nodeRef={nodeRef}>
      <Grid2 size={{ xs: 6, sm: 6, md: 3 }} ref={nodeRef}>
        <AuctionCard item={item} />
      </Grid2>
    </CSSTransition>
  );
};

export default React.memo(AuctionCardTransition);
