// src/components/add-auction/fields/watchFields.ts

export const calculateTitleForWatch = (productFields: any): string => {
  const { brand, wmodel, referenceNumber, movement, caseMaterial, caseDiameter, gender, period, condition, extras } =
    productFields;

  let titleParts = [];

  // Brand
  if (brand) {
    titleParts.push(brand);
  }

  // Model
  if (wmodel && wmodel !== 'Unknown') {
    titleParts.push(wmodel);
  }

  // Reference Number
  if (referenceNumber) {
    titleParts.push(`Ref. ${referenceNumber}`);
  }

  // Movement
  if (movement) {
    titleParts.push(movement);
  }

  // Case Material
  if (caseMaterial) {
    titleParts.push(caseMaterial);
  }

  // Case Diameter
  if (caseDiameter) {
    titleParts.push(`${caseDiameter}mm`);
  }

  // Gender
  if (gender) {
    titleParts.push(`${gender}'s Watch`);
  }

  // Condition
  if (condition) {
    titleParts.push(`(${condition})`);
  }

  // Extras
  if (extras && Array.isArray(extras) && extras.length > 0) {
    titleParts.push(`with ${extras.join(', ')}`);
  }

  return titleParts.join(' ');
};

export const watchFields = [
  {
    name: 'brand',
    label: 'Brand',
    type: 'text',
    values: [
      '5.11',
      '5.11 Tactical',
      '88 Rue du Rhone',
      'A. Lange & S\u00f6hne',
      'A.Mantei',
      'AK Geneve',
      'AK-Homme',
      'Accurate',
      'Accurist',
      'Accutron',
      'Adex',
      'Adidas',
      'Aero Nautec',
      'Aero Watch',
      'Aeronautec',
      'Aerowatch',
      'Aigner',
      'Airain',
      'Akribos XXIV',
      'Alain LE mondial',
      'Alain Silberstein',
      'Alba',
      'AleXora',
      'Alessandro Baldieri',
      'Alexander Phillip Watch',
      'Alexander Shorokhoff',
      'Alexandre Christie',
      'Alfex',
      'Alfred Dunhill',
      'Almanus',
      'Alpener',
      'Alpha Sierra',
      'Alpha Time',
      'Alpina',
      'Alprosa',
      'Altanus',
      'Alviero Martini 1a Classe',
      'Amaswiss',
      'Amida Digitrend',
      'Ancre',
      'Andersen Gen\u00e8ve',
      'Andreas Strehler',
      'Andrew Vorontsov',
      'Android',
      'Andr\u00e9 Fleury',
      'Andr\u00e9 Le Marquand',
      'Angelus',
      'Angular Momentum',
      'Animal',
      'Anonimo',
      'Anonimo Firenze',
      'Antima',
      'Antoine Martin',
      'Antoine Preziuso',
      'Appella',
      'Apple',
      'Aqua Master',
      'Aquadive',
      'Aquadive Forum',
      'Aquanautic',
      'Aquastar',
      'Aquaswiss',
      'Arcadia',
      'Archimede',
      'Arctos',
      'Ardath',
      'Aristo',
      'Armand Nicolet',
      'Armida',
      'Armin Strom',
      'Armitron',
      'Armour Lite',
      'Arnold & Son',
      'Artauro',
      'Artego',
      'Arthur Oskar Stampfli',
      'Artya',
      'Askania',
      'Askania Uhren',
      'Aspen Jewelry & Watches',
      'Asprey London',
      'Ateliers de Monaco',
      'Ateria',
      'Atlantic',
      'Atlantis',
      'Audemars Piguet',
      'Auguste Reymond',
      'Auricoste',
      'Automatic',
      'Autran & Viala',
      'Avalanche',
      'Avia',
      'Avianne & Co.',
      'Avier',
      'Avocet Vertech',
      'Azimuth',
      'B. Junge & S\u00f6hne',
      'B.R.M.',
      'BALL',
      'BCBG',
      'BCBG MAX AZRIA',
      'BMW',
      'BRERA OROLOGI',
      'BWC',
      'BWC-Swiss',
      'Baby-G',
      'Backes & Strauss',
      'Badollet',
      'Bagger & Gehring',
      'Balmain',
      'Balmer',
      'Bandit',
      'Barbara Bixby',
      'Barrington Griffiths',
      'Barthelay',
      'Basilika',
      'Bassel',
      'Bathys Hawaii',
      'Baudry Time',
      'Baume',
      'Baume & Mercier',
      'Bausele',
      'Bavarian Crono',
      'Beaudry',
      'Bedat & Co.',
      'Bell & Ross',
      'Benarus',
      'Benny & Co.',
      'Benrus',
      'Benrus Watch Company',
      'Benzinger',
      'Bernex',
      'Berney Blondeau',
      'Bernhard H. Mayer',
      'Bernhardt Watch Company',
      'Bernouille',
      'Bernoulli',
      'Berrer & S\u00f6hne',
      'Berrer und S\u00f6hne',
      'Berthet',
      'Bertolucci',
      'Bertucci',
      'Bethge',
      'Beuchat',
      'Bexei',
      'Bianci',
      'Bifora',
      'Bijoux Terner',
      'Billabong',
      'Birks',
      'Black-Out Concept',
      'Blacksand',
      'BlancheFontaine SA',
      'Blancier',
      'Blancpain',
      'Bleitz',
      'Blu',
      'Boccia',
      'Boegli',
      'Bohlin',
      'Bombardier',
      'Bomberg',
      'BorgeauD',
      'Borgward',
      'Boschett',
      'Bosie',
      'Botta Design',
      'Boucheron',
      'Bovet',
      'Bozeman Watch Co.',
      'Bradley',
      'Braun',
      'Breguet',
      'Breil',
      'Breitling',
      'Bremont',
      'Brera',
      'Brior',
      'Brooks Brothers',
      'Bruno Banani',
      'Bruno S\u00f6hnle',
      'Bruvik',
      'Buben & Z\u00f6rweg',
      'Bucherer',
      'Buchner & Bovalier',
      'Buler',
      'Bulloc',
      'Bulova',
      'Bunz',
      'Burberry',
      'Buren',
      'Burgi',
      'Burlington Watch Co.',
      'Bvlgari',
      'C.H. Wolf',
      'CASIO Edifice',
      'CASIO Edifice watch collection',
      'CH-Pihl',
      'COOL',
      'CTK (The Chinese Timekeeper)',
      'CWC',
      'Cabestan',
      'Cacheux',
      'Cadola',
      'Caliber',
      'Campanola',
      'Camy',
      'Candino',
      'Caravelle',
      'Caravelle New York',
      'Cardinal',
      'Carl F. Bucherer',
      'Carlo Ferrara',
      'Carrera',
      'Carrera y Carrera',
      'Cartier',
      'Casio',
      'Catamaran',
      'Catena',
      'Catorex',
      'Cattin',
      'Cavadini',
      'Cavenago',
      'Cecil Purnell',
      'Cedric Johner',
      'Century',
      'Ceres',
      'Cerruti',
      'Certina',
      'Chanel',
      'Charles Conrad',
      'Charles David',
      'Charles Dumont',
      'Charles-Hubert',
      'Charles Hubert Paris',
      'Charles Oudin',
      'Charles Raymond',
      'Charmex',
      'Charriol',
      'Chase-Durer',
      'Chaumet',
      'Chiffres Gregson',
      'Choisi',
      'Chopard',
      'Chotovelli & Figli',
      'Chris Benz',
      'Christi Oneil',
      'Christiaan v.d. Klaauw',
      'Christian Bernard',
      'Christian Dior',
      'Christian Jacques',
      'Christian Klings',
      'Christina London',
      'Christine Genesis',
      'Christophe Claret',
      'Christopher Ward',
      'Chromachron',
      'Chronographe Suisse',
      'Chronoseum',
      'Chronosport',
      'Chronoswiss',
      'Chronotech',
      'Churpf\u00e4lzische Uhrenmanufaktur',
      'Cimier',
      'Citizen',
      'Claude Meylan',
      'Claude Valentini',
      'Clebar',
      'Clerc',
      'Click-Clock',
      'Clock Box',
      'Clodia',
      'Close Enough',
      'Clyda',
      'Coach',
      'Coinwatch',
      'Colibri',
      'Colomer & Sons',
      'Concord',
      'Condor',
      'Confr\u00e9rie Horlog\u00e8re',
      'Consul of Switzerland',
      'Cornavin',
      'Cornelius & Cie',
      'Cort\u00e9bert',
      'Corum',
      'Corvus',
      'Costro',
      'Cover',
      'Crehor',
      'Crepas',
      'Croce',
      'Crossover',
      'Croton',
      'Crush Watch',
      'Cubus',
      'Cuervo y Sobrinos',
      'Curtis & Co.',
      'Cvstos',
      'Cyclos',
      'Cyma',
      'Cyril Ratel',
      'Cyrus Geneve',
      "D'Aguet",
      'D.Atlantis',
      'D. Dornbl\u00fcth und S\u00f6hn',
      'D.Factory',
      'DWISS',
      'DWatch',
      'Damasko',
      'Daniel Mink',
      'Daniel Roth',
      'Dark Rush',
      'Darwil',
      'Datum',
      'Davegoeslove',
      'David Yurman',
      'Davidoff',
      'Davosa',
      'De Beers',
      'De Bethune',
      'De Boulay & Korn',
      'De Grisogono',
      'De Motu',
      'DeLaCour',
      'DeLaneau',
      'DeWitt',
      'Debaufre',
      'Decima Mas',
      'Deep Blue',
      'Delaloye',
      'Delance',
      'Delbana',
      'Delma',
      'Delvina',
      'Demos',
      'Dent',
      'Designer del Tempo',
      'Devon',
      'Devon Works',
      'Di Lenardo &. Co',
      'DiBur',
      'Diafuego',
      'Dietrich',
      'Dievas',
      'Dior',
      'Dirk Dornblueth',
      'Disney',
      'Diva',
      'Division-Furtive',
      'Dodane',
      'Dodane 1857',
      'Dogma',
      'Dolce & Gabbana',
      'Dolphin',
      'Donald W. Corson',
      'Doxa',
      'Dreyfuss & Co.',
      'Drops',
      'Du Bois',
      'DuBois 1785',
      'DuBois et fils',
      'Dubey & Schaldenbrand',
      'Duc Decreon De Saint Pierre',
      'Duc Oger',
      'Dufeau',
      'Dugena',
      'Dunhill',
      'Dunlop',
      'Durabo',
      'Dussert',
      'Duward',
      'Dyrberg/Kern',
      'D\u00fcrmeister',
      'EDC',
      'EPMartin',
      'ESQ',
      'ESQ Movado',
      'Ebel',
      'Eberhard & Co.',
      'Ecclissi',
      'Eddie Bauer',
      'Edmond',
      'Edouard Lauzieres',
      'Edox',
      'Egon Hummel',
      'Election',
      'Elgin Watch Company',
      'Elini',
      'Elite',
      'Elle',
      'Ellicott',
      'Emas',
      'Emboss Time',
      'Emile Pequignet',
      'Empire',
      'Endura',
      'Enicar',
      'Enigma',
      'Enila',
      'Ennebi',
      'Enzo Mechana',
      'Epos',
      'Equipe',
      'Erhard Junghans',
      'Erieff and Sons',
      'Erken',
      'Ernest Borel',
      'Ernst Benz',
      'Erwin Sattler',
      'Essex',
      'Esso',
      'Eterna',
      'Eterna-Matic',
      'Eva Leube',
      'Evilard',
      'Excellanc',
      'Exotic',
      'Extatico',
      'F.P. Journe',
      'FUBU',
      'Faberg\u00e9',
      'Fashion Time',
      'Favre-Leuba',
      'Feinwerk',
      'Feldo',
      'Fendi',
      'Ferrari',
      'Festina',
      'Fiber',
      'Fitzroy',
      'Fiyta',
      'Fludo',
      'Forget',
      'Formex',
      'Fortaine',
      'Fortis',
      'Forum',
      'Foxtime Instruments',
      'Franc Vila',
      'Franchi Menotti',
      'Franck Muller',
      'Francois Rotier',
      'Frank Heydrich',
      'Franklin Mint',
      'Freestyle',
      'Freeze',
      'Freiderick Stein',
      'Fromanteel',
      'Fr\u00e9d\u00e9ric Jouvenot',
      'Fr\u00e9d\u00e9rique Constant',
      'Full Carbon',
      'Fuller',
      'G-Shock',
      'GERSI',
      'GSD (Greg Stevens Design)',
      'GSX',
      'GaG\u00e0 Milano',
      'Gallet',
      'Game Time',
      'Gard\u00e9',
      'Garmin',
      'Gebson',
      'Geiger',
      'Genaldi',
      'Genesis',
      'Geneva',
      'Geneve',
      'Genie',
      'Georg J Von Burg',
      'Georg Jensen',
      'Geovani',
      'Gerg\u00e9',
      'Germano & Walter',
      'Gevril',
      'Giantto',
      'Gigandet',
      'Ginault',
      'Gio Monaco',
      'Giorgio Armani',
      'Girard-Perregaux',
      'Giuliano Mazzuoli',
      'Glam Rock',
      'Glash\u00fctte Original',
      'Glycine',
      'Golana',
      'Golay Spierer',
      'Goldpfeil',
      'Graf',
      'Graff',
      'Graham',
      'Greenland',
      'Greiner',
      'Grenacher',
      'Gresso',
      'Greubel Forsey',
      'Grimoldi',
      'Gronefeld',
      'Grovana',
      'Gruen',
      'Gucci',
      'Guepard',
      'Guess',
      'Guillermin Mollet',
      'Guinand',
      'Gul',
      'Gustafsson & Sj\u00f6gren',
      'Guy Ellia',
      'Guy Laroche',
      'G\u00e9rald Genta',
      'G\u00fcbelin',
      'H.Moser & Cie.',
      'HD3',
      'HD3 Complication',
      'HMP',
      'HTO',
      'HTO Watches',
      'HYT',
      'Habring2',
      'Hacher',
      'Hadleigh',
      'Hadley-Roma',
      'Haemmer',
      'Hager',
      'Hajime Asaoka',
      'Halbard Rioga',
      'Halda Watch Co.',
      'Haldi',
      'Haldimann',
      'Halios',
      'Hamilton',
      'Hampden',
      'Hana',
      'Handwerk',
      'Hanhart',
      'Hanowa',
      'Harley-Davidson',
      'Harry Winston',
      'Harwood',
      'Haste',
      'Haurex',
      'Hautlence',
      'Hebdomas',
      'Hebe',
      'Hebe Watch',
      'Hedelius',
      'Heidi Daus',
      'Heinrich Geisen',
      'Helbros',
      'Helix',
      'Helson Watch Co.',
      'Helvetia',
      'Hemess',
      'Hentschel',
      'Herc',
      'Heritage',
      'Herm\u00e8s',
      'Herve Stephane Vincent',
      'Heuer',
      'Hexa',
      'HighStreet',
      'Hirsch',
      'Hislon',
      'Holdermann und Sohn',
      'Horae',
      'HoroSwiss',
      'HourPower',
      'Howard',
      'Huber',
      'Hublot',
      'Hudson',
      'Huet Brothers',
      'Hugex',
      'Huguenin',
      'Hysek',
      'I.T.A.',
      'IAXA',
      'ICE Nation',
      'IWC',
      'IWI',
      'IceLinkWatch',
      'Icetek',
      'Ideatempo',
      'Iguzzini',
      'Ike watch',
      'Ikepod',
      'Illinois',
      'Imado',
      'Indus',
      'Inex of Scandinavia',
      'Infantry Co.',
      'Ingersoll',
      'Insignum',
      'Instruments & Mesures du Temps',
      'Integral',
      'Intex Digital',
      'Intima',
      'Invicta',
      'Itay Noy',
      'Ixia',
      'J. Chevalier',
      'J.Springs',
      'JACK SPADE',
      'JB-Gioacchino',
      'JB Viot',
      'JS Watch co.',
      'Jacob & Co.',
      'Jacob Jensen',
      'Jacob Zech',
      'Jacques Etoile',
      'Jacques Lemans',
      'Jaeger',
      'Jaeger-LeCoultre',
      'Jaermann & St\u00fcbi',
      'Jaguar',
      'James Avery',
      'Japy',
      'Jaques Richal',
      'Jaquet-Droz',
      'Jean Dunand',
      'Jean Lassale',
      'Jean-Mairet & Gillman',
      'Jean-Marc Garel',
      'Jean Marcel',
      'Jean Perret',
      'Jean-Pierre Lepine',
      "Jean d'Eve",
      'JeanRichard',
      'Jens',
      'Jet Set',
      'Jetzer et Fils',
      'Jiusko',
      'Jochen Benzinger',
      'Joe Rodeo',
      'Joerg Schauer',
      'John Hardy',
      'John Isaac',
      'John Wind',
      'Jojo',
      'Jonas Brothers',
      'Jordi',
      'Jorg Gray',
      'Jorg Hysek',
      'Jos',
      'Joseph & Thomas Windmills',
      'Joshua & Sons',
      'Jovial',
      'Jowissa',
      'Jubilar',
      'Judex',
      'Judith Ripka',
      'Jules J\u00fcrgensen',
      'Junghans',
      'Junkers',
      'Justex',
      'Juvenia',
      'JuveniaiTime',
      'KERA',
      'KPM Watch Company',
      'KULTUhR',
      'Kadloo',
      'Kahn',
      'Kahuna',
      'Kaiser',
      'Kari Voutilainen',
      'Karl Falk',
      'Kazimon',
      'Kazimon by Kazim Kenzo',
      'Kees Engelbarts',
      'Kelek',
      'Kennett',
      'Kentex',
      'Kiber',
      'Kienzle',
      'Kipling',
      'Kiu Tai Yu',
      'Klaus Kobec',
      'Kleber Uhrenatelier',
      'Klimt',
      'Kobold',
      'Kobold Watch Company',
      'Kolber',
      'Kolber Gen\u00e8ve',
      'Konstantin Chaykin',
      'KoonySun',
      'Korloff',
      'Korloff Paris',
      'Korsbek Watch Company',
      'Kraftworxs',
      'Kremke Watch Co.',
      'Krieger',
      'Kronsegler',
      'Krug-Ba\u00fcmen',
      'Kudoke Uhren',
      'Kurt Schaffo',
      'Kyboe',
      "L'Duchen",
      "L'Ep\u00e9e",
      'L L Bean',
      'L.Leroy',
      'LOV',
      'LUM-TEC',
      'Lacher',
      'Laco',
      'Ladoire',
      'Lafuma',
      'Laks',
      'Lamborghini',
      'Lambretta',
      'Lancaster',
      'Lancia',
      'Lanco',
      'Lang & Heyne',
      'Lars Larsen',
      'Lassale',
      'Latitude',
      'Laurent Ferrier',
      'Le Cheminant (Swiss Watches)',
      'Le Ch\u00e2teau',
      'Le Phare',
      'Le Tandy',
      'Le Vian',
      'LeCoultre',
      'Leandri',
      'Leinfelder',
      'Lemania',
      'Leonard',
      'Leonidas',
      'Leschot',
      'Lessant',
      'Leumas',
      'Levrette',
      'Lia Sophia',
      'Limes',
      'Limes (D)',
      'Limes (US)',
      'Limit',
      'Lindburgh + Benson',
      'Linde Werdelin',
      'Lip',
      'Lissignol',
      'Liverani',
      'Liz Claiborne',
      'Lobor',
      'Locman',
      'Lois Hill',
      'Longines',
      'Longio',
      'Lorenz',
      'Lorus',
      'Lotterman und Sh\u00f6ne',
      'Lotus',
      'Louann',
      'Louis Bolle',
      'Louis Chevrolet',
      'Louis Erard',
      'Louis Moinet',
      'Louis Pion',
      'Louis Vuitton',
      'Lowin Cologne',
      'Lucien Piccard',
      'Lucien Rochat',
      'Ludek Seryn',
      'Ludovic Ballouard',
      'Luminox',
      'Lutolf Philip',
      'M C Uhren',
      'M.E.C.',
      'M.G.C.',
      'M & M',
      'M&M Swiss Watch',
      'MAR',
      'MAT',
      'MB&F',
      'MB Watches',
      'MCT',
      'MERCURY',
      'MIH',
      'MIMO',
      'MK II',
      'MSC Uhren',
      'MTM',
      'MVMT',
      'MWC (Military Watch Co.)',
      'MZI Watch Co.',
      'Magalex',
      'Magellan',
      'Magrette',
      'Manoir',
      'Manufacture Contemporaine du Temps',
      'Manufacture Royal',
      'Maranello',
      'Marathon',
      'Marc',
      'Marc Jenni',
      'Marc & Sons',
      'Marc by Marc Jacobs',
      'Marcel Boucher',
      'Marcello C.',
      'Marco Brogsitter',
      'Marco Max',
      'Marco Polo World Time Watches',
      'Margi',
      'Marlen',
      'Marli',
      'Martin Braun',
      'Marvin',
      'Mathey-Tissot',
      'Matthew Norman',
      'Matthias Fuchs',
      'Maty',
      'Mauboussin',
      'Maurice Lacroix',
      'Maurice de Mauriac',
      'Max Ren\u00e9',
      'Maxima',
      'Maximal Art',
      'Maximilian',
      'Ma\u00eetres du Temps',
      'McGonigle',
      'Meccaniche Veloci',
      'Mechanische Zeitinstrumente',
      'Meer',
      'Meister',
      'Meister Anker',
      'Meistersinger',
      'Mellerio dits Meller',
      'Memorigin',
      'Memphis Belle',
      'Mepa',
      'Mercedes-Benz',
      'Mercure',
      'Mermod Fr\u00e8res',
      'Metal.ch',
      'Meyers',
      'Mica',
      'Michael Anthony',
      'Michael Bittel',
      'Michal Negrin',
      "MicheSun Pilot's Watches",
      'Michel Herbelin',
      'Michel Jordi',
      'Michele',
      'Michele Watches',
      'Mido',
      'Mikimoto',
      'Military European Company',
      'Milleret',
      'Milus',
      'Minerva',
      'Mobilia',
      'Momentus',
      'MomoDesign',
      'Mondaine',
      'Mondia',
      'Monet',
      "Mont d'Or",
      'Montana Watch Company',
      'Montano',
      'Montblanc',
      'Montega',
      'Montrek',
      'Montres de Luxe',
      'MoonWatch',
      'Morellato',
      'Morgan',
      'Morpheus',
      'Mortima',
      'Movado',
      'Mr Jones Watches',
      'MuDu',
      'Mulco',
      'Murex',
      'Mustang',
      'M\u00e9tal Chronom\u00e9trie',
      'M\u00fchle Glash\u00fctte',
      'N',
      'N.B. Y\u00e4eger',
      'N.O.A.',
      'NFW',
      'NICE',
      'NakedWatch',
      'NauticFish',
      'Nautica',
      'Nautilus',
      'Nava',
      'Navitec',
      'Navy Seals',
      'Nekta',
      'Nelka',
      'Nepro',
      'Nicolas Delaloye',
      'Niessing',
      'Nikuru',
      'Nina Ricci',
      'Nisus',
      'Nite',
      'Nivada',
      'Nivrel',
      'Nixon',
      'Nobel',
      'Nolan Miller',
      'Nomos',
      'Nooka',
      'Noon',
      'Normal Timepieces',
      'North Eagles',
      'Nots',
      'Nouvelle Horlogerie Calabrese (NHC)',
      'Novus by National Semiconductor',
      'Noxos',
      'Nubeo',
      'O.D.M.',
      'OASIS',
      'OCTO',
      'ODES',
      'ODM',
      'OFFSHORE Professional Classic Chronographs Ltd.',
      'OHSEN',
      'OREFICI',
      'Obris Morgan',
      'Ocean7 Watch Co',
      'Oceanaut',
      'Oceaneva',
      'Oceanus',
      'Officina',
      'Officina del Tempo',
      'Officine Panerai',
      'Offshore Limited',
      'Ogival',
      'Olaf Heichert',
      'Old Florence',
      'Olivier Streli',
      'Ollech & Wajs',
      'Olma',
      'Omax',
      'Omega',
      'OnMotion',
      'Optima',
      'Orator',
      'Orbita',
      'Orfina',
      'Orient',
      'Orient Japan',
      'Orient USA',
      'Orient Watch Co.',
      'Oris',
      'Orpheo',
      'Orsa',
      'Orvin',
      'Osco',
      'Otium',
      'Otto Berger',
      'Otto Schlund',
      'Ovel',
      'Oxette',
      'P Swiss',
      'PCT Horlogerie',
      'PZero',
      'PZero (Pirelli)',
      'Panerai',
      'Pansar',
      'Pantar',
      'Paolo Gucci',
      'Parmigiani Fleurier',
      'Parrera',
      'Pasquale Bruni',
      'Patek Philippe',
      'Patton',
      'Paul Buhr\u00e9',
      'Paul Ditsheim',
      'Paul Frank',
      'Paul Gerber',
      'Paul Picot',
      'Paul Portinoux',
      'Paul Smith',
      'Pearl',
      'Pebble',
      'Pedro Izquierdo',
      'Pedum',
      'Pellikaan timing',
      'Pequignet',
      'Perig\u00e1um',
      'PerpetuaL',
      'Perrelet',
      'Perseo',
      'Persopolis',
      'Peter Tanisman',
      'Peugeot',
      'Philip Stein',
      'Philip Watch',
      'Philipp Plein',
      'Philippe & Co.',
      'Philippe Dufour',
      'Philippe Pechard',
      'Philippe Rosen',
      'Piaget',
      'Picard & Cie',
      'Pierce 1883',
      'Pierre Balmain',
      'Pierre DeRoche',
      'Pierre Junod',
      'Pierre Kunz',
      'Pierre Lannier',
      'Pilo & Co.',
      'Pilot',
      'Piquot Meridien',
      'Pirelli',
      'Pita',
      'Playboy',
      'Plazeon',
      'Polanti',
      'Polar',
      'Poljot',
      'Poljot International',
      'Polo mark',
      'Pomellato',
      'Pontiac',
      'PopTime',
      'Porsche Design',
      'Portas',
      'Poseidon',
      'Potens',
      'Praesto',
      'Prano',
      'Precimax',
      'Premiers',
      'Premium Watches Pforzheim',
      'Prestige de Gen\u00e8ve',
      'Prim',
      'Prima Ancre',
      'Primo',
      'Prisma',
      'Pro-Hunter',
      'Prometheus',
      'Pronto',
      'Pryngeps',
      'Pr\u00e9cibel',
      'Pulsar',
      'P\u00e8re de Temps',
      'QRVE',
      'Quadtec',
      'Quantum',
      'Quantuz',
      'Quiksilver',
      'Quinting',
      'R G M',
      'R.O.1',
      'R.X.W',
      'RG512',
      'RGM',
      'RHL',
      'ROWI',
      'RPM',
      'RSW',
      'Racer',
      'Rachel Zoe',
      'Radiant',
      'Rado',
      'Raid Watch',
      'Raidillon',
      'Raimundo Davalos',
      'Rainer Brand',
      'Rainer Nienaber',
      'Ralf Tech',
      'Ram',
      'Ravel',
      'Ravisa',
      'Raymond Weil',
      'Reaction',
      'Reactor',
      'Rebellion',
      'Reconvilier',
      'Record',
      'Red Monkey',
      'Red8',
      'RedSea',
      'Reedsco',
      'Regalle',
      'Regent',
      'Regnier Paris',
      'Relic',
      'Remy Cruz',
      'Renato',
      'Resco',
      'Ressence',
      'Revelation',
      'Revue Thommen',
      'Rexa',
      'Rheinmeister',
      'Richard & Co.',
      'Richard Mille',
      'Riedenschild',
      'Rinovati',
      'Rios1931',
      'Ritmo Mundo',
      'River Island',
      'Roamer',
      'Roberge',
      'Robert',
      'Robert Lee Morris',
      'Robert Lighton',
      'Roberto Bianci',
      'Roberto Cavalli',
      'Rocawear',
      'Rockwell',
      'Rodania',
      'Rodenstein',
      'Rodolphe',
      'Roger Cornet',
      'Roger Dubuis',
      'Roger W. Smith Ltd.',
      'Roland Kemmner',
      'Rolex',
      'Rolf Lang',
      'Romago',
      'Romain Gauthier',
      'Romain Jerome',
      'Romanson',
      'Roots',
      'Rosendahl',
      'Roskopf',
      'Rossini',
      'Rosskopf',
      'Rotary',
      'Rothenschild',
      'Rousseau',
      'Roven Dino',
      'Roxy',
      'Rudge',
      'Rudis Sylva',
      'Rufus Gerard',
      'Ryser Kentfield',
      'S.Oliver',
      'S.T.A.M.P.S.',
      'S.T. Dupont',
      'S.U.F.',
      'S.U.G.',
      'SAS Watch Co',
      'SAWC',
      'SAWC (San Antonio Watch Company)',
      'SISU',
      'SWISS MILITARY WATCH',
      'Saint Honor\u00e9',
      'Salvatore Ferragamo',
      'Sandoz',
      'Sanrio/Hello Kitty',
      'Sarcar',
      'Saro Gem',
      'Sarpaneva',
      'Sartego',
      'Saskia maaike Bouvier',
      'Scalfaro',
      'Schaefer',
      'Schauble & S\u00f6hne',
      'Schaumburg',
      'Schaumburg Watch',
      'Schild',
      'Schmeltzer 1881',
      'Schneider Manufaktur',
      'Schofield',
      'Schuhren',
      'Schwarz Etienne',
      'Scorva',
      'Sea-God',
      'SeaSurfer',
      'Sector',
      'Seculus',
      'Sedona Watch Works',
      'Seiko',
      'Serket Watch Company',
      'Setima',
      'Sevenfriday',
      'Shark',
      'Sheffield',
      'Shellman',
      'Shinola',
      'Sicura',
      'SideWatch',
      'Sigma',
      'Silvana',
      'Sinn',
      'Sinobi',
      'Sj\u00f6\u00f6 Sandstr\u00f6m',
      'Skav',
      'Skywatch',
      'Smash',
      'Smith & Wesson',
      'Snyper',
      'So-Mine',
      'Solvil',
      'Sonbol',
      'Sonic Watche',
      'Sonic Watches',
      'Sordi',
      'Sothis',
      'Sottomarino Italia',
      'Soyuz',
      'Spazio24',
      'Speake-Marin',
      'Speidel',
      'Sprinto',
      'Squale',
      'St. Gallen',
      'St. Moritz',
      'St. Petersburg Collection Watches',
      'Stadlin',
      'Starck-Design',
      'Stauer',
      'Staurino Fratelli',
      'Steelco',
      'Steelcraft',
      'Steenman',
      'Stefan Johansson',
      'Steffen',
      'Steiner Limited',
      'Steinhart',
      'Steinhausen',
      'Steinway & Sons',
      'Steph',
      'Stolas',
      'Storm of London',
      'Stowa',
      'Street',
      'Strom',
      'St\u00fchrling Original',
      'Sub Chrono',
      'Sultana',
      'Suunto',
      'Suzuki',
      'Svend Andersen',
      'Swarovski',
      'Swatch',
      'Swinford',
      'Swiss Army',
      'Swiss Balance',
      'Swiss Cheese Watch',
      'Swiss Eagle',
      'Swiss Legend',
      'Swiss Military',
      'Swiss Precimax',
      'Swiss Timer',
      'Swiza',
      'TAG Heuer',
      'TB Buti',
      'THE ONE Binary',
      'TRIWA',
      'TW Steel',
      'TWC',
      'TX',
      'Tabbah',
      'Tack & Gybe',
      'TamTime',
      'Tavan',
      'Tavannes',
      'Techno Master',
      'TechnoMarine',
      'Technos',
      'Techn\u00e9',
      'Tecnotempo',
      'Ted Baker',
      'Tellus',
      'Tempest Watches',
      'Temption',
      'Tempvs Compvtare',
      'Tendence',
      'Terra Cielo Mare',
      'Terrain',
      'Terranaut',
      'Texas Instruments',
      'The Royal Diamonds',
      'Theorein',
      'Thiel Ruhla',
      'Thoma Design',
      'Thomas Gref',
      'Thomas Ninchritz',
      'Thomas Prescher',
      'Tiburon',
      'Ticino',
      'Tiffany',
      'Tiffany & Co.',
      'Timberland Watches',
      'Time Force',
      'Time-It',
      'Time by Design',
      'Timekeeper',
      'Tinelli',
      'Tiq',
      'Tiret',
      'Tissot',
      'Titan',
      'Titanium Cargo',
      'Titoni',
      'Tom Tailor',
      'Tonino Lamborghini',
      'Tool Watch',
      'Torgoen',
      'Tourby',
      'Tourneau',
      'Towson Watch Company',
      'Toy Watch',
      'Tradition',
      'Traser',
      'Tresor Paris',
      'Tressa',
      'Trevion',
      'Trintec',
      'Triumph',
      'Trixie',
      'Trofish',
      'Tsovet',
      'Tudor',
      'Tutima',
      'U-Boat',
      'UHR',
      'UNIQ',
      'UTS',
      'UTS-M\u00fcnchen',
      'Ublast',
      'Uhr-Kraft',
      'Ulysse Nardin',
      'Unbranded',
      'UniformWares',
      'Unikatuhren',
      'Union',
      'Union Glash\u00fctte',
      'Universal Gen\u00e8ve',
      'Urbach',
      'Urban J\u00fcrgensen',
      'Urban Picasso',
      'Uri Uri',
      'Uri Uri by Alessi',
      'Urwerk',
      'Utinam',
      'V.I.P. Time',
      'VOID',
      'Vabene',
      'Vacheron Constantin',
      'Valbray',
      'Valgine',
      'Valima',
      'Valjoux',
      'Van Cleef & Arpels',
      'Van Der Bauwede',
      'Van Ree Watches',
      'Van Speyk',
      "Van d'Hall",
      'Van der Gang',
      'Vanadium',
      'Vanceur',
      'Vangarde',
      'Velocitech',
      'Velona',
      'Vendoux',
      'Ventura',
      'Verdastro',
      'Vergo Avius',
      'Vernier',
      'Versace',
      'Vestal',
      'Vialli',
      'Vianney Halter',
      'Vicenterra',
      'Viceroy',
      'Victoria Wieck',
      'Victorinox',
      'Victorinox Swiss Army',
      'Vidus',
      'Vigoria Miletto',
      'Villemont',
      'Vincent Berard',
      'Vincent Calabrese',
      'Vincent Paris',
      'Violet',
      'Visage',
      'Vittorium',
      'Vivani',
      'Vixa',
      'Vizio',
      'Vizio by Movado',
      'Vogard',
      'Voil\u00e0',
      'Volker Vyskocil',
      'Volkswagen',
      'Volmax',
      'Volna',
      'Voltime',
      'Vostok',
      'Voumard',
      'Voutilainen',
      'Vuillemin Regnier',
      'Vulcain',
      'W.Bagus',
      'WM of Switzerland',
      'Wakmann',
      'Waldan',
      'Waldan International',
      'Waltham',
      'WeWood',
      'Web @nywhere',
      'Weide',
      'Weiss Watch Company',
      'Welder',
      'Wempe',
      'Wendler',
      'Wenger',
      'Werenbach',
      'West End Watch Co.',
      'Westar',
      'Westclox',
      'Whimsical Watches',
      'Wiegand',
      'Wintex',
      'Wittnauer',
      'Wohler',
      'Worldchronos',
      'Wyler',
      'Wyler Geneve',
      'Wyler Vetta',
      'XEN',
      'XTress',
      'Xanadu',
      'Xantia',
      'Xemex',
      'Xetum',
      'Xezo',
      'Xicorr',
      'YES',
      'Yantar',
      'Yema',
      'Yonger & Bresson',
      'York',
      'Yves Bertelin',
      'Yves Saint Laurent',
      'ZIIIRO',
      'Zandidoust',
      'Zannetti',
      'Zeitfluege',
      'Zeitfluegel',
      'Zeitwinkel',
      'Zenith',
      'Zeno',
      'Zeno-Watch Basel',
      'ZentRa',
      'Zenton',
      'Zeppelin',
      'Zinex Uhrenfabrik',
      'Zitura',
      'Zixen Watch Company',
      'Zodiac',
      'Zoobee',
      'Zzero',
      'a.b.art',
      'd.freemont',
      'd.m.h. (Dingemans Mechanische Horloges)',
      'i-toc',
      'iTime',
      'iXXXi',
      'idio:synchro',
      'ochs und junior',
      'red line',
      '\u00c9ric Tabarly',
    ],
  },
  {
    name: 'wmodel',
    label: 'Model',
    type: 'text',
    values: [
      '1966',
      '2000 Series',
      'Admiral',
      "Admiral's Cup",
      'Aerospace',
      'Air-King',
      'Alpine Eagle',
      'Altiplano',
      'Amvox',
      'Aquanaut',
      'Aquaracer',
      'Aquaracer 300M',
      'Aquaracer 500M',
      'Aquaracer Calibre 5',
      'Aquaracer Professional',
      'Aquatimer',
      'Aquis',
      'Arceau',
      'Artelier',
      'Astron',
      'Audi Sport',
      'Autavia',
      'Automatic Super Titanium',
      'Aviator 8',
      'B.Zero1',
      'BR 01',
      'BR 02',
      'BR 03',
      'BR 05',
      'BR V1',
      'BR V2',
      'Baignoire',
      'Ballon Bleu',
      'Bentley',
      'Big Bang',
      'Big Bang King',
      'Big Crown',
      'Big Crown Pointer Date',
      'Big Crown ProPilot',
      'Black Bay',
      'Black Bay 58',
      'Black Bay Gmt',
      'Black Croco',
      'Bubble',
      'Calatrava',
      'Calculator',
      'Calibre de Cartier',
      'Calibre de Cartier Diver',
      'Cape Cod',
      'Capeland',
      'Carbongold',
      'Carrera',
      'Carrera Calibre 5',
      'Carrera Calibre 6',
      'Carrera Calibre 16',
      'Carrera Calibre 1887',
      'Carrera Calibre Heuer 01',
      'Carrera Calibre Heuer 02',
      'Carrera Chronograph',
      'Carrera Lady',
      'Casablanca',
      'Cellini',
      'Chemin Des Tourelles Powermatic 80',
      'Chrono-Matic',
      'Chrono Orbs',
      'Chrono Round',
      'Chronofighter',
      'Chronograph 100M "Chronosphere"',
      'Chronoliner',
      'Chronomat',
      'Chronomat B01',
      'Chronomat Blackbird',
      'Chronomat Evolution',
      'Chronomat Vitesse',
      'Cintr\u00e9e Curvex',
      'Class',
      'Classic Fusion',
      'Classics',
      'Classics Art Deco',
      'Classics Carr\u00e9e',
      'Classics Delight',
      'Classics GMT',
      'Classics Heart Beat',
      'Classics Index',
      'Classics Lady',
      'Classics Moonphase',
      'Classima',
      'Classique',
      'Clifton',
      'Clifton Club',
      'Clipper',
      'Cl\u00e9',
      'Cockpit',
      'Code 11.59',
      'Colt',
      'Colt Chronograph',
      'Colt GMT',
      'Colt Lady',
      'Colt Military',
      'Colt Oceane',
      'Connected',
      'Conquest',
      'Conquistador',
      'Constellation',
      'Constellation Automatic',
      'Constellation Co-Axial',
      'Constellation Double Eagle',
      'Constellation F300Hz',
      'Constellation Manhattan',
      'Constellation Master Chronometer',
      'Constellation Megaquartz',
      'Constellation Mini',
      'Constellation Pie Pan',
      'Constellation Quadra',
      'Cosmograph Daytona',
      'Cosmograph Daytona Zenith',
      'Coutura',
      'Da Vinci',
      'Datejust',
      'Datejust II',
      'Datejust Lady',
      'Datejust Oysterquartz',
      'Datejust Turn-O-Graph',
      'Day-Date',
      'Daytona',
      'De Ville',
      'De Ville Automatic',
      'De Ville Chronoscope',
      'De Ville Classic',
      'De Ville Co-Axial',
      'De Ville Hour Vision',
      'De Ville Prestige',
      'De Ville Tr\u00e9sor',
      'Defy',
      'Defy Skyline',
      'Defy Xtreme',
      'Diagono',
      'Divers Sixty-Five',
      'Divers Sixty-Five Cotton Candy',
      'Dolcevita',
      'Drive',
      'Dynamic',
      'Dynamic Helix',
      'Dynamic Solar System',
      'El Primero',
      'El Primero Chronomaster',
      'Elegant',
      'Eliros',
      'Elite',
      'Ellipse',
      'Endurance Pro',
      'Evidenza',
      'Explorer',
      'Explorer II',
      'Ferrari',
      'Fifty Fathoms',
      'Flagship',
      'Formula 1',
      'Formula 1 Big Date',
      'Formula 1 Chronograph',
      'Freedom',
      'GMT-Master',
      'GMT-Master II',
      'GMT-Master II Batman',
      'GMT-Master II Coke',
      'GMT-Master II Pepsi',
      'GMT-Master Pepsi',
      'GST',
      'Galactic',
      'Gen\u00e8ve',
      'Gen\u00e8ve Dynamic',
      'Gen\u00e8ve Electronic',
      'Glamour',
      'Globemaster',
      'Globetrotter',
      'Gondolo',
      'Grand Carrera',
      'Grand Prix de Monaco Historique',
      'Grandevitesse',
      'Hampton',
      'Happy Diamonds',
      'Happy Sport',
      'Heritage',
      'Heritage Chronom\u00e9trie',
      'Heritage Spirit',
      'Heure H',
      'Highlife',
      'Highlife Automatic COSC',
      'Highlife COSC',
      'Highlife Heartbeat Automatic',
      'Highlife Lady',
      'Historiques',
      'Horological Smartwatch',
      'Hydro',
      'Hydroconquest',
      'Hydronaut',
      'Imperiale',
      'Ingenieur',
      'Ingenious',
      'Jazzmaster',
      'Jazzmaster Auto Chrono',
      'Jazzmaster Chrono Quartz',
      'Jazzmaster Day Date',
      'Jazzmaster Face-2-Face',
      'Jazzmaster Lady Quartz',
      'Jazzmaster Maestro',
      'Jazzmaster Open Heart',
      'Jazzmaster Performer Auto Chrono',
      'Jazzmaster Seaview',
      'Jazzmaster Thinline',
      'Jazzmaster Viewmatic',
      'Jules Audemars',
      'Jumbo',
      'Kelly',
      'Khaki',
      'Khaki Aviation',
      'Khaki Field',
      'Khaki GMT',
      'Khaki Navy',
      'Khaki Officer',
      'Khaki Pilot',
      'Khaki Pioneer',
      'King Power',
      'King Seiko',
      'Kirium',
      'L.U.C',
      'La Grande Classique',
      'Ladies Automatic Small Seconds',
      'LadyMatic Co-Axial',
      'Lange 1',
      'Laureato',
      'Legend Diver',
      'Liberty',
      'Linea',
      'Link',
      'Long Island',
      'Lucea',
      'Luminor',
      'Luminor 1950',
      'Luminor Base',
      'Luminor GMT',
      'Luminor Logo',
      'Luminor Marina',
      'Luminor Power Reserve',
      'Luminor Submersible',
      'MDM',
      'Manta Ray',
      'Marine',
      'Marine Star',
      'Master',
      'Master Banker',
      'Master Collection',
      'Master Compressor',
      'Master Control',
      'Master Geographic',
      'Master Square',
      'Master Ultra Thin',
      'Meisterst\u00fcck',
      'Memovox',
      'Milgauss',
      'Mille Miglia',
      'Monaco',
      'Monaco Calibre 6',
      'Monaco Calibre 11 Steve McQueen',
      'Monaco Calibre 12 Steve McQueen',
      'Monaco Calibre 17',
      'Montbrillant',
      'MoonSwatch - Mission on Earth',
      'MoonSwatch - Mission to Jupiter',
      'MoonSwatch - Mission to Mars',
      'MoonSwatch - Mission to Mercury',
      'MoonSwatch - Mission to Neptune',
      'MoonSwatch - Mission to Pluto',
      'MoonSwatch - Mission to Saturn',
      'MoonSwatch - Mission to Uranus',
      'MoonSwatch - Mission to Venus',
      'MoonSwatch - Mission to the Moon',
      'MoonSwatch - Mission to the Sun',
      'Must de Cartier',
      'Must de Cartier 21',
      'Must de Cartier Vendome',
      'M\u00e9dor',
      'Nantucket',
      'Nautilus',
      'Navitimer',
      'Navitimer Airborne',
      'Navitimer B01',
      'Navitimer GMT',
      'Navitimer Heritage',
      'Navitimer Montbrilliant 01 Limited',
      'Navitimer World',
      'Never Get Lost Carbonox',
      'Octo',
      'Old Navitimer',
      'Old Navitimer II',
      'Overseas',
      'Oyster Perpetual',
      'Oyster Perpetual Bubbleback',
      'Oyster Perpetual Date',
      'Oyster Perpetual Date Lady',
      'Oyster Perpetual Datejust',
      'Oyster Perpetual Lady',
      'Oyster Precision',
      'Oyster Speedking Precision',
      'Oysterdate Precision',
      'PRX',
      'PanoMatic',
      'Panthere',
      'Pasha',
      'Pasha Seatimer',
      'Patrimony',
      'Pelagos',
      'Pilot',
      'Polaris',
      'Polo',
      'Porsche Design',
      'Port Royal',
      'Portofino',
      'Portofino Chronograph',
      'Portugieser',
      'Premier',
      'Presage',
      'Presence',
      'PrimaLuna',
      'Prince Date',
      'Prince Oysterdate',
      'Princess',
      'Professional Diver 1000M "Tsunami"',
      'Promaster',
      'Promesse',
      'Prospex',
      'Radiomir',
      'Radiomir 3 Days',
      'Radiomir 10 days',
      'Radiomir 1940',
      'Radiomir Black Seal',
      'Railmaster',
      'Record',
      'Recraft',
      'Reverso',
      'Reverso Classique',
      'Reverso Lady',
      'Riviera',
      'Roadster',
      'Ronde',
      'Ronde Croisi\u00e8re de Cartier',
      'Ronde Louis',
      'Ronde Must',
      'Ronde Solo',
      'Royal',
      'Royal Oak',
      'Runabout',
      'SKX',
      'Santos',
      'Santos 100',
      'Santos Dumont',
      'Santos Galb\u00e9e',
      'Santos Octagon',
      'Santos Ronde',
      'Scuba Fifty Fathoms',
      'Sea-Dweller',
      'Sea-Dweller Deep Sea',
      'Sea-Hawk',
      '"Seadiving" 300M',
      'Seamaster',
      'Seamaster 30M',
      'Seamaster 120M',
      'Seamaster 300',
      'Seamaster 1948',
      'Seamaster Anakin Skywalker',
      'Seamaster Aqua Terra 150M',
      'Seamaster Chronograph',
      'Seamaster Cosmic',
      'Seamaster Day-Date',
      'Seamaster De Ville',
      'Seamaster Diver 200M',
      'Seamaster Diver 300M',
      'Seamaster F300Hz',
      'Seamaster Mariner',
      'Seamaster Memomatic Alarm',
      'Seamaster Olympic Games Collection',
      'Seamaster Planet Ocean 600M',
      'Seamaster Polaris',
      'Seamaster Professional 200M',
      'Seamaster Proplof 1200M',
      'Seamaster Railmaster',
      'Seamaster Sensor Quartz',
      'Seiko 5',
      'Seiko 5 Automatic Day-Date',
      'Senator',
      'Silverstone',
      'Sky-Dweller',
      'Slimline',
      'Slimline Moonphase',
      'Solotempo',
      'Speedmaster',
      'Speedmaster 57',
      'Speedmaster Broad Arrow',
      'Speedmaster Co-Axial',
      'Speedmaster Date',
      'Speedmaster Day-Date',
      'Speedmaster De Luxe',
      'Speedmaster Legend',
      'Speedmaster Mark II',
      'Speedmaster Mark IV',
      'Speedmaster Mark V',
      'Speedmaster Michael Schumacher',
      'Speedmaster Moon Phase',
      'Speedmaster Professional Moonwatch',
      'Speedmaster Racing',
      'Speedmaster Reduced',
      'Speedmaster Spacemaster',
      'Speedmaster Split-Seconds',
      'Speedmaster Triple Date',
      'Speedmaster X-33',
      'Spezimatic',
      'Spirit of Liberty',
      'Sporto',
      'Star',
      'Stellina',
      'Submariner',
      'Submariner Date',
      'Submariner Hulk',
      'Submariner No Date',
      'Submerged 30 ATM',
      'Summit',
      'SuperOcean Chronograph',
      'SuperOcean Heritage II',
      'SuperOcean II',
      'SuperOcean Professional',
      'Superocean',
      'Superocean Heritage',
      'Swordfish',
      'TT1',
      'TT3',
      'Tandem',
      'Tank',
      'Tank Am\u00e9ricaine',
      'Tank Anglaise',
      'Tank Fran\u00e7aise',
      'Tank Louis',
      'Tank MC',
      'Tank Must de Cartier',
      'Tank Solo',
      'Timewalker',
      'Tradition',
      'Transocean',
      'Twenty 4',
      'Type XX',
      'Ultra-Chron',
      'Unknown',
      'Vanguard',
      'Villeret',
      'Vintage',
      'Vintage 1945',
      'Vintage Rally',
      'Williams F1 Team',
      'Windrider',
      'Yacht-Master',
      'Yacht Timer',
    ],
  },
  {
    name: 'referenceNumber',
    label: 'Reference Number',
    type: 'text',
    values: [],
  },
  {
    name: 'gender',
    label: 'Gender',
    type: 'text',
    values: ['Men', 'Unisex', 'Women'],
  },
  {
    name: 'period',
    label: 'Period',
    type: 'text',
    values: [
      '1850-1900',
      '1901-1949',
      '1950-1959',
      '1960-1969',
      '1970-1979',
      '1980-1989',
      '1990-1999',
      '2000-2010',
      '2010-2020',
      '2011-present',
      '2020+',
      'Earlier than 1850',
    ],
  },
  {
    name: 'movement',
    label: 'Movement',
    type: 'text',
    values: [
      'Automatic',
      'Kinetic',
      'Manual winding',
      'Mechanical automatic',
      'Mechanical hand winding',
      'Mechanical key winding',
      'None',
      'Quartz',
      'Solar',
      'Tuning fork',
    ],
  },
  {
    name: 'caseMaterial',
    label: 'Case Material',
    type: 'text',
    values: [
      'Aluminium',
      'Argentan',
      'Bioceramic',
      'Bronze',
      'Carbon',
      'Ceramic',
      'Chromed',
      'Copper',
      'Gold',
      'Gold/Steel',
      'Gold-filled',
      'Gold-plated',
      'Gunmetal',
      'Metal',
      'Palladium',
      'Pink gold',
      'Plastic',
      'Platinum',
      'Polycarbon',
      'Silver',
      'Silverplated',
      'Stainless steel',
      'Steel',
      'Tantalum',
      'Titanium',
      'Tungsten',
      'White gold',
      'Yellow gold',
    ],
  },
  {
    name: 'caseDiameter',
    label: 'Case Diameter',
    type: 'text',
    values: [],
  },
  {
    name: 'condition',
    label: 'Condition',
    type: 'text',
    values: [
      'In working condition with visible traces of use',
      'Unworn',
      'Worn & in very good condition',
      'Worn with little to no signs of use',
    ],
  },
  {
    name: 'lengthOfWatchBand',
    label: 'Length of Watch Band',
    type: 'text',
    values: [],
  },
  {
    name: 'extras',
    label: 'Extras',
    type: 'text',
    values: ['Box', 'Certificate of originality', 'Documents', 'Full set', 'Travel box', 'Warranty'],
  },
  {
    name: 'shippedInsured',
    label: 'Shipped Insured',
    type: 'text',
    values: [],
  },
  {
    name: 'era',
    label: 'Era',
    type: 'text',
    values: ['Before 1400', '1400-1900', '1900-2000', 'After 2000'],
  },
];
