// src/components/chat/hooks/useChat.ts

import { useState, useEffect, useRef, useCallback } from 'react';
import { enqueueSnackbar } from 'notistack';
import timeWithMilliseconds from '../timeWithMilliseconds';
import { useTranslation } from 'react-i18next';
import { CurrentUser } from '../../../models/interfaces';
import { useLocation, useNavigate } from 'react-router-dom';
import apiClient from '../../apiClient'; // Import your Axios instance
import { handleAxiosError } from '../../../utils/handleAxiosError'; // Import the utility function
import { useAuth } from '../../../contexts/AuthContext';

interface Message {
  content: string;
  role: string;
  question_name?: string;
  progress?: string;
  _id?: string;
}

interface Session {
  session_name: string;
  session_id: string;
  chatHistory: Message[];
  playAudio: boolean;
  _id: string;
}

interface UseChatProps {
  sessionNumber: number;
  lastInteraction: Session | null;
  language: string;
  debug?: boolean;
  getChatContext: () => any;
  onOpenFilters: () => void;
  user: CurrentUser | null;
  onApplyFilters: (filters: any) => void;
  filters: any;
}

interface UseChatReturn {
  messages: Message[];
  inputText: string;
  setInputText: React.Dispatch<React.SetStateAction<string>>;
  playAudio: boolean;
  togglePlayAudio: () => void;
  sendMessage: (messageContent: string) => Promise<void>;
  deleteMessage: (id: number) => Promise<void>;
  editMessage: (id: number) => void;
  audioUrl: string;
  audioText: string;
  voiceRecordingFinish: (currentText: string) => void;
  getLanguage: () => string;
  voiceFinalTranscriptUpdate: (transcript: string) => void;
  voiceListeningStopped: () => void;
  getCurrentText: () => string;
  updateShowTalkLegend: (show: boolean) => void;
  clearAudioURL: () => void;
  talkStatusUpdate: (status: string) => void;
  inFlightRequest: boolean;
  submitCount: number;
  talkStatus: string;
  talkStatusRef: React.MutableRefObject<string>;
  clearAllMessages: () => void;
}

const useChat = ({
  sessionNumber,
  lastInteraction,
  language,
  debug = false,
  getChatContext,
  onOpenFilters,
  user,
  onApplyFilters,
  filters,
}: UseChatProps): UseChatReturn => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputText, setInputText] = useState('');
  const [playAudio, setPlayAudio] = useState(true);
  const [audioUrl, setAudioUrl] = useState('');
  const [audioText, setAudioText] = useState('');
  const [submitCount, setSubmitCount] = useState(0);
  const [talkStatus, setTalkStatus] = useState('');
  const talkStatusRef = useRef('');

  const inFlightRequest = useRef(false);
  const showTalkLegend = useRef(false);

  const location = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { getAccessToken, isLoggedIn, requireAuth } = useAuth(); // Access Auth0 functions

  const API_BASE_URL =
    window.location.origin === 'http://localhost:3000'
      ? 'http://localhost:5000/api'
      : process.env.REACT_APP_API_BASE_URL;

  // Function to get current input text
  const getCurrentText = () => {
    return inputText;
  };

  // Voice Command Handlers
  const voiceRecordingFinish = (currentText: string) => {
    if (debug) console.log(timeWithMilliseconds(), 'Voice recording finished called', inputText);
    if (currentText !== '') {
      handleSendMessage(currentText);
    } else {
      setInputText((prevInputText) => {
        if (prevInputText !== '') {
          // Append a marker or handle accordingly
          return prevInputText + 'VoiceCommand';
        }
        return prevInputText;
      });
      handleSendMessage(inputText);
    }
  };

  const voiceFinalTranscriptUpdate = async (transcript: string) => {
    if (debug) console.log(timeWithMilliseconds(), 'Voice final transcript update called', transcript);
    setInputText(transcript);
  };

  const voiceListeningStopped = () => {
    if (debug) console.log(timeWithMilliseconds(), 'Voice listening stopped');
    // Handle any cleanup if necessary
  };

  const getLanguageFunction = () => {
    return language;
  };

  // Show/Hide Talk Legend
  const updateShowTalkLegend = (show: boolean) => {
    if (messages.length === 1) {
      if (debug) console.log('Show legend', messages.length);
      showTalkLegend.current = show;
    }
  };

  // Talk Status Update
  const talkStatusUpdate = (status: string) => {
    if (status === '') {
      updateStatus(status);
      // Optionally, handle hiding status indicator
    } else {
      if (status !== talkStatus) {
        if (debug)
          console.log(
            timeWithMilliseconds(),
            'Talk status update called status:',
            status,
            ' current talkStatus:',
            talkStatus
          );
        updateStatus(status);
        // Optionally, handle showing status indicator
      }
    }
  };

  // Clear Audio URL
  const clearAudioURL = () => {
    setAudioUrl('');
    setAudioText('');
  };

  const clearAllMessages = () => {
    setMessages([]); // Assuming you have a setMessages state updater
    // If you need to perform additional cleanup, do it here
  };

  const updateStatus = (status: string) => {
    talkStatusRef.current = status;
    setTalkStatus(status);
  };

  // Initialize messages based on session
  useEffect(() => {
    if (lastInteraction) {
      setMessages(lastInteraction.chatHistory || []);
      setPlayAudio(lastInteraction.playAudio);
      // Handle other initializations if needed
    } else {
      // Set welcome message
      const welcomeMessage: Message = {
        content: sessionNumber === 0 ? t('chat.welcomeMessage') : t('chat.newSessionMessage'),
        role: 'assistant',
      };
      setMessages([welcomeMessage]);
    }
  }, [lastInteraction, sessionNumber, t]);

  // Handle filters and item selection from chat
  const handleFilterResponse = (filter: any) => {
    onApplyFilters(filter);
    if (location.pathname !== '/ai/search') {
      console.log('use Chat handleFilterResponse navigate / ');
      navigate('/ai/search');
    }
  };

  const openItem = (selectedItem: any) => {
    if (selectedItem) {
      navigate('/auction/' + selectedItem);
    }
  };

  const handleSendMessage = useCallback(
    async (messageContent: string) => {
      if (inFlightRequest.current) {
        if (debug) console.log('Request already in flight.');
        return;
      }

      const userMessage: Message = { content: messageContent.trim(), role: 'user' };
      if (!isLoggedIn) {
        messageContent = 'You must be logged in to access chat.';
        const botMessage: Message = { content: messageContent, role: 'assistant' };
        setMessages((prev) => [...prev, userMessage, botMessage]);
        enqueueSnackbar('You must be logged in to access chat.', { variant: 'warning' });
        inFlightRequest.current = false;
        return;
      }

      const botMessage: Message = { content: '', role: 'assistant' };
      setMessages((prev) => [...prev, userMessage, botMessage]);
      setInputText('');
      inFlightRequest.current = true;
      const chatContext = getChatContext();
      const body = {
        chatHistory: [...messages, userMessage],
        question: messageContent.trim(),
        playAudio: String(playAudio ? 1 : 0),
        language,
        sessionNumber,
        chatContext,
      };

      try {
        console.log('Sending message:', body);
        const accessToken = await getAccessToken(); // Get access token from Auth0

        const response = await fetch(`${API_BASE_URL}/armond/armond_session`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
          },
          body: JSON.stringify(body),
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        // Handle streaming response
        const reader = response.body?.getReader();
        if (!reader) throw new Error('No reader available');

        const decoder = new TextDecoder();
        let buffer = '';
        let accumulatedText = '';

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          const chunk = decoder.decode(value, { stream: true });
          buffer += chunk;

          // Split the buffer into JSON chunks based on '}{' boundary
          let boundary = buffer.indexOf('}{');
          while (boundary !== -1) {
            const completeJson = buffer.slice(0, boundary + 1);
            const remainingBuffer = buffer.slice(boundary + 1);

            try {
              const parsedChunk = JSON.parse(completeJson);
              accumulatedText += parsedChunk.content || '';
              setMessages((prev) =>
                prev.map((msg, idx) => (idx === prev.length - 1 ? { ...msg, content: accumulatedText } : msg))
              );

              // Handle audio if present
              if (parsedChunk.audioSession && playAudio) {
                setAudioUrl(parsedChunk.audioSession);
                setAudioText(accumulatedText);
                // Trigger audio playback logic if necessary
              }

              if (parsedChunk.command) {
                if (parsedChunk.command.filter) {
                  handleFilterResponse(parsedChunk.command.filter);
                }
                if (parsedChunk.command.selectedItem) {
                  openItem(parsedChunk.command.selectedItem);
                }
              }
            } catch (error) {
              if (debug) console.error('Failed to parse chunk:', error);
            }

            buffer = remainingBuffer;
            boundary = buffer.indexOf('}{');
          }
        }

        // Handle any remaining buffer
        if (buffer.trim()) {
          try {
            const parsedChunk = JSON.parse(buffer);
            accumulatedText += parsedChunk.content || '';
            setMessages((prev) =>
              prev.map((msg, idx) =>
                idx === prev.length - 1 ? { ...msg, content: accumulatedText, _id: parsedChunk._id } : msg
              )
            );

            if (parsedChunk.audioSession && playAudio) {
              setAudioUrl(parsedChunk.audioSession);
              setAudioText(accumulatedText);
            }
            if (parsedChunk.command) {
              if (parsedChunk.command.filter) {
                handleFilterResponse(parsedChunk.command.filter);
              }
              if (parsedChunk.command.selectedItem) {
                openItem(parsedChunk.command.selectedItem);
              }
            }
          } catch (error) {
            if (debug) console.error('Failed to parse final chunk:', error);
          }
        }
      } catch (error: any) {
        if (debug) console.error('Error sending message:', error);
        const message = handleAxiosError(error) || 'Failed to send message.';
        enqueueSnackbar(message, { variant: 'error' });
        // Optionally update message to reflect error
        setMessages((prev) =>
          prev.map((msg, idx) =>
            idx === prev.length - 1 ? { ...msg, content: 'Error: Failed to send message.' } : msg
          )
        );
      } finally {
        inFlightRequest.current = false;
      }
    },
    [
      messages,
      playAudio,
      language,
      sessionNumber,
      debug,
      getChatContext,
      onApplyFilters,
      navigate,
      location,
      getAccessToken,
    ]
  );

  const handleDeleteMessage = useCallback(
    async (id: number) => {
      try {
        const body = {
          id,
          chatHistory: messages,
          sessionNumber,
          _id: lastInteraction?._id || messages[messages.length - 1]._id || '',
        };

        const response = await apiClient.post('/armond/delete_message', body, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        if (debug) console.log('Message deleted:', response.data);
        // Assuming the server returns the updated chat history
        const updatedMessages = messages.filter((_, idx) => idx < id);
        setMessages(updatedMessages);
      } catch (error: any) {
        if (debug) console.error('Error deleting message:', error);
        const message = handleAxiosError(error) || 'Failed to delete message.';
        enqueueSnackbar(message, { variant: 'error' });
      }
    },
    [messages, sessionNumber, lastInteraction, debug]
  );

  const handleEditMessage = useCallback(
    (id: number) => {
      // Implement edit logic here
      // For simplicity, you might set the input text to the message content
      if (id >= 0 && id < messages.length) {
        setInputText(messages[id].content);
        handleDeleteMessage(id);
      }
    },
    [messages, handleDeleteMessage]
  );

  const togglePlayAudioHandler = useCallback(() => {
    setPlayAudio((prev) => !prev);
  }, []);

  return {
    messages,
    inputText,
    setInputText,
    playAudio,
    togglePlayAudio: togglePlayAudioHandler,
    sendMessage: handleSendMessage,
    deleteMessage: handleDeleteMessage,
    editMessage: handleEditMessage,
    audioUrl,
    audioText,
    voiceRecordingFinish,
    getLanguage: getLanguageFunction,
    voiceFinalTranscriptUpdate,
    voiceListeningStopped,
    getCurrentText,
    updateShowTalkLegend,
    clearAudioURL,
    talkStatusUpdate,
    inFlightRequest: inFlightRequest.current,
    submitCount,
    talkStatus,
    talkStatusRef,
    clearAllMessages,
  };
};

export default useChat;
