// src/components/chat/LanguageContext.tsx
import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface LanguageContextProps {
  language: string;
  changeLanguage: (lang: string) => void;
}

const LanguageContext = createContext<LanguageContextProps | undefined>(undefined);

export const useLanguage = (): LanguageContextProps => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};

interface LanguageProviderProps {
  children: ReactNode;
}

export const LanguageProvider: React.FC<LanguageProviderProps> = ({ children }) => {
  const { i18n } = useTranslation();

  const getInitialLanguage = (): string => {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      //return storedLanguage;
      return 'en-US';
    }

    const userLanguages = navigator.languages || [navigator.language];
    //const supportedLanguages = ['en-US', 'es-ES', 'he-IL'];
    const supportedLanguages = ['en-US'];
    return (
      // userLanguages.find((lang) => supportedLanguages.includes(lang)) || 'en-US'
      'en-US'
    );
  };

  const [language, setLanguage] = useState<string>(getInitialLanguage());

  const changeLanguage = (lang: string): void => {
    localStorage.setItem('language', 'en-US');
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };

  useEffect(() => {
    // const direction = language === 'he-IL' ? 'rtl' : 'ltr';
    document.documentElement.setAttribute('dir', 'ltr');
    //i18n.changeLanguage(language); // Ensure the i18n language is synced
    i18n.changeLanguage('en-US');
  }, [language, i18n]);

  return <LanguageContext.Provider value={{ language, changeLanguage }}>{children}</LanguageContext.Provider>;
};

export default LanguageContext;
