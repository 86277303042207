// src/components/add-auction/fields/auctionItemFields.ts
export const auctionItemFields = [
  {
    name: 'startingPrice',
    label: 'Starting Price',
    type: 'number',
    required: true,
    min: 0,
  },
  {
    name: 'reservePrice',
    label: 'Reserve Price',
    type: 'number',
    required: true,
    min: 0,
  },

  {
    name: 'buyItNowPrice',
    label: 'Buy it Now Price',
    type: 'number',
    min: 0,
  },
  {
    name: 'sellerUpraisal',
    label: 'Seller Upraisal',
    type: 'number',
    min: 0,
  },

  {
    name: 'category',
    label: 'Category',
    type: 'text',
    required: true,
    values: ['Watch', 'Jewelry', 'Diamond', 'Gemstone'],
  },
  { name: 'startDate', label: 'Preferd Auction Start Date', type: 'date', required: true },
  { name: 'endDate', label: 'Preferd Auction End Date', type: 'date', required: true },

  {
    name: 'expertEstimateMin',
    label: 'Expert Estimate Min',
    type: 'number',
    role: 'admin',
    min: 0,
  },
  {
    name: 'expertEstimateMax',
    label: 'Expert Estimate Max',
    type: 'number',
    role: 'admin',
    min: 0,
  },
  // auctionId: number;
  // supplierInternals: string;
  {
    name: 'auctionId',
    label: 'Auction ID',
    type: 'readonly',
  },
  {
    name: 'supplierInternals',
    label: 'Supplier Internals Info',
    type: 'text',
  },
];
