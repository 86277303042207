// src/components/PrivateRoute.tsx

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

interface PrivateRouteProps {
  children: React.ReactElement;
  requiredRoles?: string[]; // Made optional
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, requiredRoles }) => {
  const { user, isLoggedIn, loading } = useAuth();

  console.log('PrivateRoute', user, isLoggedIn, loading);

  if (loading) {
    // Optionally, render a loading spinner or skeleton here
    return null; // Or a spinner component
  }

  if (!isLoggedIn || !user) {
    // Redirect to login if not authenticated
    return <Navigate to="/auth/login" replace />;
  }

  if (requiredRoles && requiredRoles.length > 0) {
    if (!user.role || !requiredRoles.includes(user.role)) {
      // User does not have the required role; redirect to unauthorized page or home
      return <Navigate to="/" replace />;
    }
  }

  // Render the child component if authenticated and authorized
  return children;
};

export default PrivateRoute;
