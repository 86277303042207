// src/components/add-auction/AuctionDetailsForm.tsx

import React, { useState, useEffect, useRef, Suspense, forwardRef } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  CircularProgress,
  TextField,
  FormHelperText,
  Button,
  Box,
  Snackbar,
  Alert as MuiAlert,
  AlertProps,
} from '@mui/material';
// Keep your existing Grid2 import
import { Grid2 } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { productFields } from './fields/productFields';
import dayjs, { Dayjs } from 'dayjs';
import { auctionItemFields } from './fields/auctionItemFields';
import { FieldDefinition, HandleSelectChangeType } from './renderFields';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import RenderFields from './renderFields';
import { CurrentUser, AuctionItem } from '../../models/interfaces';
import { getAllowedStatusTransitions, isValidStatusTransition } from './statusUtils';
import TextFieldComponent, { TextFieldProps } from '@mui/material/TextField';

// Lazy load DateTimePicker and LocalizationProvider
const DateTimePicker = React.lazy(() =>
  import('@mui/x-date-pickers/DateTimePicker').then((module) => ({
    default: module.DateTimePicker,
  }))
);
const LocalizationProvider = React.lazy(() =>
  import('@mui/x-date-pickers/LocalizationProvider').then((module) => ({
    default: module.LocalizationProvider,
  }))
);

// Snackbar Alert component
const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface AuctionDetailsFormProps {
  auctionItem: AuctionItem;
  setAuctionItem: React.Dispatch<React.SetStateAction<AuctionItem>>;
  handleAuctionItemChange: (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{ name?: string; value: unknown }>
  ) => void;
  handleProductChange: (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{ name?: string; value: unknown }>
  ) => void;
  handleProductTypeSelection: (e: SelectChangeEvent<string>) => void;
  selectedProductType: string;
  getProductTypeFields: () => FieldDefinition[];
  renderFields: React.ComponentType<any>;
  user: CurrentUser;
  handleSelectChange?: HandleSelectChangeType;
  canAnalyze: () => boolean;
  handleAnalyze: () => void;
}

const AuctionDetailsForm: React.FC<AuctionDetailsFormProps> = ({
  auctionItem,
  setAuctionItem,
  handleAuctionItemChange,
  handleProductChange,
  handleProductTypeSelection,
  selectedProductType,
  getProductTypeFields,
  renderFields: RenderFieldsComponent,
  user,
  canAnalyze,
  handleAnalyze,
}) => {
  // **Move the `error` state inside the component**
  const [error, setError] = useState(true);

  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs(auctionItem.startDate || new Date()));
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs(auctionItem.endDate || new Date()));

  // Include the 'status' field by not excluding it from the fields array
  const myAuctionItemFields = auctionItemFields.filter(
    (field) => field.name !== 'startDate' && field.name !== 'endDate' && field.name !== 'category'
    // Removed: && field.name !== 'status' // Now include 'status'
  );

  const handleSelectProductChange = (e: SelectChangeEvent<string>) => {
    const name = e.target.name;
    const value = e.target.value;

    setAuctionItem((prev) => ({
      ...prev,
      product: {
        ...prev.product,
        [name]: value,
      },
    }));
  };

  // Create a ref for the new fields section
  const newFieldsRef = useRef<HTMLDivElement>(null);

  const handleProductTypeChange = (e: SelectChangeEvent<string>) => {
    const value = e.target.value;
    if (value) {
      setError(false); // Reset error if a valid selection is made
    } else {
      setError(true); // Set error if no selection
    }
    // Handle product type selection logic
    handleProductTypeSelection(e);
    handleSelectProductChange(e);
  };

  useEffect(() => {
    if (startDate && endDate) {
      const strStartDate = dayjs(startDate).toISOString();
      const strEndDate = dayjs(endDate).toISOString();
      setAuctionItem((prev: any) => ({
        ...prev,
        startDate: strStartDate,
        endDate: strEndDate,
      }));
    }
  }, [startDate, endDate, setAuctionItem]);

  // Determine available status options
  const availableStatuses = React.useMemo(() => {
    return getAllowedStatusTransitions(auctionItem.status, user.role);
  }, [auctionItem.status, user.role]);

  // Snackbar state and handlers
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info'>('success'); // Updated to include 'info'

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  useEffect(() => {
    if (auctionItem.product && auctionItem.product.productType) {
      setError(false);
    }
  }, [auctionItem.product]);

  return (
    <>
      {/* Category Selector */}
      <FormControl
        fullWidth
        variant="outlined"
        sx={{ marginTop: '16px' }}
        error={error} // Apply error styling based on state
        required
      >
        <InputLabel id="product-type-label">Item Category</InputLabel>
        <Select
          labelId="product-type-label"
          label="Item Category"
          name="productType"
          value={selectedProductType}
          onChange={handleProductTypeChange}
          sx={{
            '& .MuiOutlinedInput-root': {
              fontFamily: 'Muli, Arial, Helvetica, sans-serif',
              fontSize: '14px',
            },
            '& .MuiSelect-select': {
              padding: '10.5px 14px',
            },
          }}
        >
          {/* Optional: Add a default "None" option */}
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="Diamond">Diamond</MenuItem>
          <MenuItem value="Gemstone">Gemstone</MenuItem>
          <MenuItem value="Watch">Watch</MenuItem>
          <MenuItem value="Jewelry">Jewelry</MenuItem>
        </Select>
        {/* Display helper text when there's an error */}
        {error && <FormHelperText>Please select an item category.</FormHelperText>}
      </FormControl>

      <Box mt={2}>
        {selectedProductType !== '' && canAnalyze() && (
          <Grid2 container spacing={2} alignItems="center">
            <Typography variant="h6">Analyze the images with Armond AI:</Typography>
            <Button variant="contained" color="success" onClick={handleAnalyze} sx={{ ml: 2 }}>
              Analyze
            </Button>
          </Grid2>
        )}
      </Box>

      <Box mt={2}>
        {/* RenderFields for Auction Status and other fields */}
        <RenderFieldsComponent
          fields={[
            // Include 'status' field with both Admin and Supplier roles
            {
              name: 'status',
              label: 'Auction Status',
              type: 'select',
              required: true,
              roles: ['Admin', 'Supplier'], // Updated to include both roles
            },
            // Add other fields as needed
          ]}
          handleChange={handleAuctionItemChange}
          handleSelectChange={(e: SelectChangeEvent<any>) => {
            const fieldName = e.target.name;
            const value = e.target.value as string;

            if (fieldName === 'status') {
              if (value === auctionItem.status) {
                // No change in status; do nothing
                setSnackbarMessage('Status remains unchanged.');
                setSnackbarSeverity('info');
                setOpenSnackbar(true);
                return;
              }

              if (isValidStatusTransition(auctionItem.status, value, user.role)) {
                setAuctionItem((prev) => ({
                  ...prev,
                  status: value,
                }));
                // Show success Snackbar
                setSnackbarMessage('Status updated successfully.');
                setSnackbarSeverity('success');
                setOpenSnackbar(true);
              } else {
                // Provide user feedback for invalid transition
                setSnackbarMessage('Invalid status transition.');
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
              }
            }
            // No else block since only 'status' is handled here
          }}
          values={auctionItem}
          user={user}
        />
      </Box>

      <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          Auction Details
        </Typography>
        <RenderFieldsComponent
          fields={myAuctionItemFields}
          handleChange={handleAuctionItemChange}
          handleSelectChange={handleAuctionItemChange} // Assuming similar handler
          values={auctionItem}
          user={user}
        />
      </Box>

      <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          Preferred Auction Dates
        </Typography>

        <Grid2 container spacing={2}>
          <Suspense fallback={<CircularProgress />}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid2 size={{ sm: 12, md: 6 }}>
                <DateTimePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </Grid2>
              <Grid2 size={{ sm: 12, md: 6 }}>
                <DateTimePicker
                  label="End Date"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </Grid2>
            </LocalizationProvider>
          </Suspense>
        </Grid2>
      </Box>

      <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          Product Details
        </Typography>

        <RenderFieldsComponent
          fields={productFields}
          handleChange={handleProductChange}
          handleSelectChange={handleProductTypeSelection} // Add this line
          values={auctionItem.product}
          user={user}
        />
      </Box>

      {selectedProductType && (
        <Box mt={4} ref={newFieldsRef}>
          <Typography variant="h6" gutterBottom>
            {selectedProductType} Details
          </Typography>
          <RenderFieldsComponent
            fields={getProductTypeFields()}
            handleChange={handleProductChange}
            handleSelectChange={handleProductChange} // Correct handler
            values={auctionItem.product}
            user={user}
          />
        </Box>
      )}

      {/* Snackbar for feedback */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AuctionDetailsForm;
