// src/components/AuctionCard.tsx

import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, IconButton, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { AlignHorizontalCenter, Favorite, FavoriteBorder } from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext'; // Import useAuth
import { enqueueSnackbar } from 'notistack'; // Import enqueueSnackbar for notifications
import apiClient from './apiClient'; // Import your Axios instance
import { handleAxiosError } from '../utils/handleAxiosError'; // Import the utility function
import { AuctionItem } from '../models/interfaces';
import './AuctionCard.css';
import CountdownTimer from './CountdownTimer';

interface AuctionCardProps {
  item: AuctionItem;
}

const AuctionCard: React.FC<AuctionCardProps> = ({ item }) => {
  const [isFavorite, setIsFavorite] = useState<boolean>(item.isFavorite || false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentBid, setCurrentBid] = useState<number>(item.currentBid);

  const { requireAuth, isLoggedIn } = useAuth(); // Get Auth0 functions

  // Update currentBid if item.currentBid changes
  useEffect(() => {
    setCurrentBid(item.currentBid);
  }, [item.currentBid]);

  const handleToggleFavorite = async () => {
    try {
      await requireAuth(); // Ensure authentication
      if (!isLoggedIn) {
        enqueueSnackbar('You must be logged in to perform this action.', {
          variant: 'warning',
        });
        return;
      }

      setIsFavorite((prev) => !prev); // Optimistic UI update
      setIsLoading(true);

      const apiCall = isFavorite
        ? apiClient.delete(`/auction-items/${item._id}/favorite`)
        : apiClient.post(`/auction-items/${item._id}/favorite`);

      await apiCall;
      setIsLoading(false);
      enqueueSnackbar(isFavorite ? 'Removed from favorites.' : 'Added to favorites successfully!', {
        variant: 'success',
      });
    } catch (error: any) {
      console.error('Error updating favorite:', error);
      const message = handleAxiosError(error) || 'Failed to update favorite.';
      enqueueSnackbar(message, { variant: 'error' });
      setIsFavorite((prev) => !prev); // Revert state if error occurs
      setIsLoading(false);
    }
  };

  return (
    <div className="auction-card-container">
      <Card className="auction-card">
        <CardContent className="auction-card-content">
          <div className="favorite-icon-container">
            {isLoading ? (
              <CircularProgress size={24} />
            ) : (
              <IconButton onClick={handleToggleFavorite} aria-label="toggle favorite">
                {isFavorite ? <Favorite color="error" /> : <FavoriteBorder color="primary" />}
              </IconButton>
            )}
          </div>
          {/* Wrap the img with Link to make it clickable */}
          <Link to={`/auction/${item._id}`} state={{ scrollToTop: true }}>
            <img src={item.img} alt={item.product.title} className="auction-card-image" />
          </Link>
          <div className="auction-card-text" style={{ alignContent: 'center' }}>
            <Typography
              className="auction-card-title"
              sx={{
                fontSize: {
                  xs: '1rem',
                  sm: '1rem',
                  md: '1.2rem',
                  lg: '1.3rem',
                },
              }}
            >
              {item.product.title}
            </Typography>

            <div className="auction-card-bid-timer">
              <Typography
                className="auction-card-current-bid"
                sx={{
                  fontSize: {
                    xs: '1rem',
                    sm: '1rem',
                    md: '1.2rem',
                    lg: '1.3rem',
                  },
                }}
              >
                Current Bid: <b>${currentBid}</b>
              </Typography>
              <CountdownTimer endDate={item.endDate} />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default AuctionCard;
