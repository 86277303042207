// /src/components/onboarding/MobileVerificationContext.tsx

import React, { createContext, useContext, useState } from 'react';

interface MobileVerificationContextType {
  isSending: boolean;
  setIsSending: (value: boolean) => void;
  isVerifying: boolean;
  setIsVerifying: (value: boolean) => void;
}

const MobileVerificationContext = createContext<MobileVerificationContextType | undefined>(undefined);

interface MobileVerificationProviderProps {
  children: React.ReactNode;
}

export const MobileVerificationProvider: React.FC<MobileVerificationProviderProps> = ({ children }) => {
  const [isSending, setIsSending] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);

  return (
    <MobileVerificationContext.Provider value={{ isSending, setIsSending, isVerifying, setIsVerifying }}>
      {children}
    </MobileVerificationContext.Provider>
  );
};

export const useMobileVerification = () => {
  const context = useContext(MobileVerificationContext);
  if (!context) {
    throw new Error('useMobileVerification must be used within a MobileVerificationProvider');
  }
  return context;
};
