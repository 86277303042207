// src/components/chat/TalkInterface.tsx

import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faVolumeUp, faHandPaper, faMicrophone } from '@fortawesome/free-solid-svg-icons';
import './TalkInterface.css';
import { useRecognition } from './useRecognition';
import timeWithMilliseconds from './timeWithMilliseconds';
import { Grid2 } from '@mui/material';
import { el, ta } from 'date-fns/locale';

interface TalkInterfaceProps {
  voiceRecordingFinish: (currentText: string) => void;
  getLanguage: () => string;
  voiceFinalTranscriptUpdate: (transcript: string) => void;
  voiceListeningStopped: () => void;
  getCurrentText: () => string;
  audioUrl: string;
  audioText: string;
  voiceAllowedToPlay: boolean;
  updateShowTalkLegend: (show: boolean) => void;
  talkStatusUpdate: (status: string) => void;
  clearAudioURL: () => void;
  inFlightRequest: boolean; // Changed to boolean
  submitCount: number;
}

interface RecognitionType {
  stopListening: (reason: string) => () => void; // Changed to require 'reason'
  startListening: () => void;
  clearTranscript: () => void;
}

function TalkInterface({
  voiceRecordingFinish,
  getLanguage,
  voiceFinalTranscriptUpdate,
  voiceListeningStopped,
  getCurrentText,
  audioUrl,
  audioText,
  voiceAllowedToPlay,
  updateShowTalkLegend,
  clearAudioURL,
  talkStatusUpdate,
  inFlightRequest, // Expecting a boolean
  submitCount,
}: TalkInterfaceProps) {
  const [listening, setListening] = useState<boolean>(false);
  const { t } = useTranslation();

  const audioContextRef = useRef<AudioContext | null>(null);
  const isPlaying = useRef<boolean>(false);
  const playbackText = useRef<string>('');
  const audioSourceRef = useRef<AudioBufferSourceNode | null>(null);
  const gainNodeRef = useRef<GainNode | null>(null);
  const secondPartAudioUrl = useRef<string>('');
  const API_BASE_URL =
    window.location.origin === 'http://localhost:3000'
      ? 'http://localhost:5000/api'
      : process.env.REACT_APP_API_BASE_URL || '';
  const swEchoCancellation = useRef<boolean>(false);
  const lastURL = useRef<string>('');
  const lastAudioURL = useRef<string>('');

  const debug = true;

  // New ref for playback
  const playbackSourceRef = useRef<AudioBufferSourceNode | null>(null);

  // Keep this for microphone input

  const stopAudio = useCallback(() => {
    if (debug) console.log(timeWithMilliseconds(), 'Force Stopping Audio in stopAudio...');
    clearAudioURL();
    secondPartAudioUrl.current = '';
    playbackText.current = '';
    if (playbackSourceRef.current) {
      playbackSourceRef.current.stop();
    }
    isPlaying.current = false;
  }, [clearAudioURL, debug]);

  const recognitionRef = useRef<RecognitionType>(
    useRecognition({
      getLanguage,
      voiceRecordingFinish,
      voiceFinalTranscriptUpdate,
      voiceListeningStopped,
      getCurrentText,
      audioSourceRef,
      isPlaying,
      playbackText,
      audioContextRef,
      setListeningState: setListening, // Pass setListening directly
      stopAudio,
      swEchoCancellation,
      talkStatusUpdate,
      inFlightRequest, // Expecting boolean
      submitCount,
    })
  );

  const sendTranscript = useCallback(() => {
    if (debug) console.log(timeWithMilliseconds(), '----------- 3. Sending Transcript...');
    recognitionRef.current.stopListening('sendTranscript')();
    voiceRecordingFinish('');
  }, [debug, voiceRecordingFinish]);

  const playAudio = useCallback(
    async (url: string, text: string) => {
      if (audioContextRef.current && audioContextRef.current.state === 'closed') {
        audioContextRef.current = new (window.AudioContext || (window as any).webkitAudioContext)();
      }
      if (!audioContextRef.current) {
        audioContextRef.current = new (window.AudioContext || (window as any).webkitAudioContext)();
      }

      if (url.includes('_')) {
        const FirstPartURL = url.substring(0, url.indexOf('_'));
        const SecondPartURL = url.substring(url.indexOf('_') + 1);
        url = FirstPartURL;
        secondPartAudioUrl.current = SecondPartURL;
      }
      url = API_BASE_URL + '/audio/audio_response/' + url;
      if (lastURL.current === url) {
        return;
      }

      isPlaying.current = true;
      playbackText.current = text;

      lastURL.current = url;
      if (debug) console.log(timeWithMilliseconds(), 'Fetching audio response', url, text);
      talkStatusUpdate('getting-audio');
      try {
        const response = await fetch(url);
        if (response.status !== 200) {
          if (debug)
            console.log(
              timeWithMilliseconds(),
              'Error in fetching audio response',
              url,
              response.status,
              response.statusText
            );
          isPlaying.current = false;
          playbackText.current = '';
          return;
        }
        const arrayBuffer = await response.arrayBuffer();
        if (arrayBuffer.byteLength === 0) {
          console.error('Received empty ArrayBuffer');
          isPlaying.current = false;
          playbackText.current = '';
          return;
        }

        try {
          const audioBuffer = await audioContextRef.current!.decodeAudioData(arrayBuffer);

          const source = audioContextRef.current!.createBufferSource();
          source.buffer = audioBuffer;
          audioSourceRef.current = source;

          const gainNode = audioContextRef.current!.createGain();
          gainNodeRef.current = gainNode;
          source.connect(gainNode);
          gainNode.connect(audioContextRef.current!.destination);

          source.start();
          if (debug) console.log(timeWithMilliseconds(), 'Playing audio 2', url, text);
          talkStatusUpdate('playing-audio');
          source.onended = () => {
            if (debug) console.log(timeWithMilliseconds(), 'Audio ended', url, text, secondPartAudioUrl.current);
            if (secondPartAudioUrl.current !== '') {
              const remainingAudioURL = secondPartAudioUrl.current;
              secondPartAudioUrl.current = '';
              playAudio(remainingAudioURL, text);
              return;
            } else {
              clearAudioURL();
            }
            if (listening) {
              talkStatusUpdate('start-listening');
            } else {
              talkStatusUpdate('stop-listening');
            }
            if (isPlaying.current) {
              isPlaying.current = false;
              playbackText.current = '';
              audioSourceRef.current = null;
              gainNodeRef.current = null;
              if (swEchoCancellation.current) {
                recognitionRef.current.clearTranscript();
              }
            }
          };
        } catch (error) {
          console.error('Error decoding audio data:', error);
          isPlaying.current = false;
          playbackText.current = '';
        }
      } catch (error) {
        console.error('Error fetching or handling audio data:', error);
        isPlaying.current = false;
        playbackText.current = '';
      }
    },
    [API_BASE_URL, clearAudioURL, debug, talkStatusUpdate]
  );

  const initializeAudioContext = useCallback(() => {
    if (!audioContextRef.current || audioContextRef.current.state === 'closed') {
      audioContextRef.current = new (window.AudioContext || (window as any).webkitAudioContext)();
    } else if (audioContextRef.current.state === 'suspended') {
      audioContextRef.current.resume();
    }
  }, []);

  useEffect(() => {
    if (isPlaying.current && audioUrl === '') {
      if (audioSourceRef.current) {
        audioSourceRef.current.stop();
      }
      isPlaying.current = false;
      playbackText.current = '';
      if (swEchoCancellation.current) {
        recognitionRef.current.clearTranscript();
      }
    }
    if (audioUrl && audioText) {
      if (audioUrl === lastAudioURL.current) {
        return;
      }
      lastAudioURL.current = audioUrl;
      playAudio(audioUrl, audioText);
    }
  }, [audioUrl, audioText, playAudio]);

  useEffect(() => {
    if (!voiceAllowedToPlay && isPlaying.current) {
      stopAudio();
    }
  }, [voiceAllowedToPlay, stopAudio]);

  const clickStartTalk = () => {
    initializeAudioContext();
    recognitionRef.current.startListening();
    setListening(true);
    talkStatusUpdate('start-listening');
  };

  const stopListeningPressed = useCallback(() => {
    recognitionRef.current.stopListening('User pressed stop')();
    talkStatusUpdate('stop-listening');
  }, []);

  return (
    <div className="talk-interface">
      {!listening ? (
        <button
          onClick={() => clickStartTalk()}
          className="chat-control-button press-to-talk-button"
          aria-label="Press to talk"
          title="Press to talk" // Tooltip added here
        >
          <FontAwesomeIcon icon={faMicrophone} />
          <span className="tooltip">Press to talk</span> {/* Balloon overlay */}
        </button>
      ) : (
        <Grid2 container spacing={1}>
          <Grid2>
            <button
              onClick={stopListeningPressed}
              className="chat-control-button talk-stop-button"
              aria-label="Stop talking"
              title="Stop talking" // Tooltip added here
            >
              <FontAwesomeIcon icon={faHandPaper} />
              <span className="tooltip">Stop</span>
            </button>
          </Grid2>
          <Grid2>
            <button
              onClick={sendTranscript}
              className="chat-control-button talk-send-button"
              aria-label="Send transcript"
              title="Send transcript" // Tooltip added here
            >
              <FontAwesomeIcon icon={faPaperPlane} />
              <span className="tooltip">Send</span>
            </button>
          </Grid2>
        </Grid2>
      )}
    </div>
  );
}

export default TalkInterface;
