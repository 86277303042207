// src/components/icons/GoogleIcon.tsx

import React from 'react';

const GoogleIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 533.5 544.3" width="20px" height="20px" {...props}>
    <path
      fill="#4285F4"
      d="M533.5 278.4c0-17.4-1.5-34-4.4-50.1H272v95.3h146.9c-6.4 34.5-25.1 63.6-53.7 83.1v68h86.8c50.7-46.7 80.2-115.3 80.2-200.3z"
    />
    <path
      fill="#34A853"
      d="M272 544.3c72.7 0 133.6-24.1 178.1-65.3l-86.8-68c-24.1 16.2-55.3 25.7-91.3 25.7-70 0-129.1-47.3-150.3-110.8H35.3v69.9c44.3 87.8 135.3 147.6 236.7 147.6z"
    />
    <path
      fill="#FBBC05"
      d="M121.7 324.3c-10.6-31.4-10.6-65.4 0-96.8V158.5H35.3C9.7 200.3 0 248.4 0 298c0 49.6 9.7 97.7 35.3 139.5l86.4-113.2z"
    />
    <path
      fill="#EA4335"
      d="M272 107.3c38.4 0 73 13.2 100.3 39.1l75.2-75.2C411.3 25.8 347.6 0 272 0 170.6 0 79.6 59.8 35.3 147.6l86.4 113.2C142.9 154.6 202 107.3 272 107.3z"
    />
  </svg>
);

export default GoogleIcon;
