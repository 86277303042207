// src/components/auction/AuctionListPage/AuctionRow.tsx

import React from 'react';
import { TableRow, TableCell, IconButton, Link as MUILink, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import { useNavigate, Link } from 'react-router-dom';
import './AuctionRow.css'; // Ensure this path is correct

interface AuctionItem {
  _id: string;
  product: { title: string; description: string };
  customerSKU: string;
  currentBid: number;
  startingPrice: number;
  status: string;
  startDate: string;
  endDate: string;
  isHotListed: boolean;
  img: string;
}

interface AuctionRowProps {
  auction: AuctionItem;
  onDelete: (auctionId: string) => void;
  onToggleHotList: (auctionId: string) => void; // Added prop
}

const AuctionRow: React.FC<AuctionRowProps> = ({ auction, onDelete, onToggleHotList }) => {
  const navigate = useNavigate();

  // Function to format dates without the year, respecting user's locale
  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: 'numeric',
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  // Function to format numbers with thousands separators and no decimals
  const formatNumber = (num: number) => {
    return num.toLocaleString(undefined, { maximumFractionDigits: 0 });
  };

  return (
    <TableRow>
      {/* Title */}
      <TableCell>
        <MUILink component={Link} to={`/auction/${auction._id}`} underline="always" color="primary">
          {auction.product.title}
        </MUILink>
      </TableCell>
      {/* Description */}
      <TableCell>
        <Tooltip title={auction.product.description} arrow>
          <span className="description-cell">{auction.product.description}</span>
        </Tooltip>
      </TableCell>
      {/* SKU */}
      <TableCell>
        <Tooltip
          title={
            <img
              src={auction.img}
              alt={`Image for SKU ${auction.customerSKU}`}
              style={{ maxWidth: '100px', maxHeight: '100px' }}
            />
          }
          arrow
          placement="top" // You can adjust the placement as needed
        >
          <span style={{ cursor: 'pointer' }}>{auction.customerSKU || 'N/A'}</span>
        </Tooltip>
      </TableCell>
      {/* Current Bid */}
      <TableCell align="right">${formatNumber(auction.currentBid)}</TableCell>
      {/* Starting Price */}
      <TableCell align="right">${formatNumber(auction.startingPrice)}</TableCell>
      {/* Status */}
      <TableCell>{auction.status}</TableCell>
      {/* Start Date */}
      <TableCell>{formatDate(auction.startDate)}</TableCell>
      {/* End Date */}
      <TableCell>{formatDate(auction.endDate)}</TableCell>
      {/* Actions */}
      <TableCell align="right">
        <IconButton color="primary" onClick={() => navigate(`/edit-auction/${auction._id}`)}>
          <EditIcon />
        </IconButton>
        <IconButton color="warning" onClick={() => onDelete(auction._id)}>
          <DeleteIcon />
        </IconButton>
        <IconButton
          onClick={() => onToggleHotList(auction._id)}
          aria-label={auction.isHotListed ? 'Remove from Hot List' : 'Add to Hot List'}
        >
          <WhatshotIcon color={auction.isHotListed ? 'error' : 'disabled'} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default AuctionRow;
