// src/theme.ts

import { createTheme } from '@mui/material/styles';

// Extend Typography Variants to include custom variants if needed
declare module '@mui/material/styles' {
  interface TypographyVariants {
    h1Ibrand: React.CSSProperties;
    h2Ibrand: React.CSSProperties;
    h3Ibrand: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    h1Ibrand?: React.CSSProperties;
    h2Ibrand?: React.CSSProperties;
    h3Ibrand?: React.CSSProperties;
  }
}

// Update Typography's variant prop options to include custom variants
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1Ibrand: true;
    h2Ibrand: true;
    h3Ibrand: true;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#6200ea', // Updated to match Figma's primary color
    },
    secondary: {
      main: '#5627FF', // Updated to match Figma's secondary color
    },
    success: {
      main: '#28a745',
    },
    error: {
      main: '#dc3545',
    },
    background: {
      paper: '#ffffff',
      default: '#ffffff',
    },
    text: {
      primary: '#000000',
      secondary: '#666666',
    },
  },
  typography: {
    fontFamily: 'Museo Sans Cyrl, sans-serif', // Default font
    h1Ibrand: {
      fontFamily: 'ibrand, Museo Sans Cyrl, sans-serif',
      fontSize: '96px',
      fontWeight: 400,
      lineHeight: '1.2',
    },
    h2Ibrand: {
      fontFamily: 'ibrand, Museo Sans Cyrl, sans-serif',
      fontSize: '64px',
      fontWeight: 400,
      lineHeight: '1.2',
    },
    h3Ibrand: {
      fontFamily: 'ibrand, Museo Sans Cyrl, sans-serif',
      fontSize: '48px',
      fontWeight: 400,
      lineHeight: '1.2',
    },
    h4: {
      fontSize: '48px',
      fontWeight: 500,
      lineHeight: '1.3',
    },
    h5: {
      fontSize: '40px',
      fontWeight: 500,
      lineHeight: '1.3',
    },
    h6: {
      fontSize: '36px',
      fontWeight: 500,
      lineHeight: '1.3',
    },
    subtitle1: {
      fontSize: '32px',
      fontWeight: 500,
      lineHeight: '1.4',
    },
    subtitle2: {
      fontSize: '24px',
      fontWeight: 500,
      lineHeight: '1.4',
    },
    body1: {
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '1.5',
    },
    body2: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '1.5',
    },
    // Define other variants as needed
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: 'none',
          marginBottom: '8px',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: '8px 12px',
        },
        content: {
          margin: '0',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '0 12px 8px',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          paddingLeft: '12px',
          fontSize: '16px',
          color: '#222',
          display: '-webkit-box',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
          wordBreak: 'break-all',
          WebkitLineClamp: '1',
          WebkitBoxOrient: 'vertical',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#6200ea', // Updated to match primary color
          '&.Mui-checked': {
            color: '#6200ea',
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: '#6200ea',
          padding: '8px 0',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: '#222',
          fontSize: '14px',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#666666',
          fontSize: '14px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#e0e0e0',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#6200ea',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#6200ea',
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          '@media (min-width:900px)': {
            maxWidth: '100vw',
          },
          '@media (max-width:900px)': {
            padding: '0',
            margin: '0',
          },
        },
      },
    },
    // Add additional component overrides as needed
  },
});

export default theme;
