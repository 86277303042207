// src/components/settings/ProfileTabs.tsx

import React from 'react';
import { Tabs, Tab, Box, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonalInfo from './PersonalInfo';
import ContactInfo from './ContactInfo';
import AddressInfo from './AddressInfo';
import Preferences from './Preferences';
import AccountDetails from './AccountDetails';
import { UserSettings } from '../../models/UserSettings';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import './styles/ProfileTabs.css';

interface ProfileTabsProps {
  userSettings: UserSettings;
  setUserSettings: React.Dispatch<React.SetStateAction<UserSettings>>;
  originalSettings: UserSettings;
}

const ProfileTabs: React.FC<ProfileTabsProps> = ({ userSettings, setUserSettings, originalSettings }) => {
  const [value, setValue] = React.useState<number>(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const sections = [
    {
      label: 'Personal Info',
      component: (
        <PersonalInfo
          personalInfo={userSettings.personalInfo}
          setUserSettings={setUserSettings}
          originalPersonalInfo={originalSettings.personalInfo}
        />
      ),
    },
    {
      label: 'Contact Info',
      component: (
        <ContactInfo
          contactInfo={userSettings.contactInfo}
          setUserSettings={setUserSettings}
          originalContactInfo={originalSettings.contactInfo}
        />
      ),
    },
    {
      label: 'Addresses',
      component: (
        <AddressInfo
          addressInfo={userSettings.addressInfo}
          setUserSettings={setUserSettings}
          originalAddressInfo={originalSettings.addressInfo}
        />
      ),
    },
    {
      label: 'Preferences',
      component: (
        <Preferences
          preferences={userSettings.preferences}
          setUserSettings={setUserSettings}
          originalPreferences={originalSettings.preferences}
        />
      ),
    },
    {
      label: 'Account Details',
      component: (
        <AccountDetails
          accountDetails={userSettings.accountDetails}
          setUserSettings={setUserSettings}
          originalAccountDetails={originalSettings.accountDetails}
        />
      ),
    },
  ];

  return (
    <>
      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {isMobile ? (
          <Box>
            {sections.map((section, index) => (
              <Accordion key={index} expanded={value === index} onChange={() => setValue(index)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{section.label}</Typography>
                </AccordionSummary>
                <AccordionDetails>{section.component}</AccordionDetails>
              </Accordion>
            ))}
          </Box>
        ) : (
          <Box className="tab-container">
            <Tabs
              value={value}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="profile settings tabs"
            >
              {sections.map((section, index) => (
                <Tab key={index} label={section.label} />
              ))}
            </Tabs>
            <Box p={3} sx={{ maxHeight: '70vh', overflowY: 'auto' }}>
              {sections[value].component}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ProfileTabs;
