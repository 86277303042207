// /src/numberUtils.ts
/**
 * Configuration options for formatting numbers.
 */
interface FormatOptions {
  decimalScale?: number;
  fixedDecimalScale?: boolean;
  allowNegative?: boolean;
  decimalSeparator?: string;
  thousandSeparator?: string;
}

/**
 * Configuration options for parsing formatted numbers.
 */
interface ParseOptions {
  decimalSeparator?: string;
  thousandSeparator?: string;
}

/**
 * Formats a number or numeric string according to the specified options.
 *
 * @param value - The number or numeric string to format.
 * @param options - Formatting options.
 * @returns The formatted number as a string.
 */
export function formatNumber(value: number | string, options: FormatOptions = {}): string {
  const {
    decimalScale = 2,
    fixedDecimalScale = true,
    allowNegative = false,
    decimalSeparator = '.',
    thousandSeparator = ',',
  } = options;

  // Convert the input to a number
  let number = typeof value === 'string' ? parseFloat(value) : value;

  // Handle invalid numbers
  if (isNaN(number)) {
    number = 0;
  }

  // Enforce non-negative if not allowed
  if (!allowNegative) {
    number = Math.max(0, number);
  }

  // Fix the number to the specified decimal scale
  let fixedNumber = number.toFixed(decimalScale);

  // Split the number into integer and decimal parts
  const [integerPart, decimalPart] = fixedNumber.split('.');

  // Add thousand separators to the integer part
  const integerWithSeparators = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);

  // Combine integer and decimal parts with the specified decimal separator
  return fixedDecimalScale
    ? `${integerWithSeparators}${decimalSeparator}${decimalPart}`
    : `${integerWithSeparators}${decimalPart ? decimalSeparator + decimalPart : ''}`;
}

/**
 * Parses a formatted number string back to a numeric value.
 *
 * @param formattedValue - The formatted number string.
 * @param options - Parsing options.
 * @returns The numeric value.
 */
export function parseFormattedNumber(formattedValue: string, options: ParseOptions = {}): number {
  const { decimalSeparator = '.', thousandSeparator = ',' } = options;

  // Remove thousand separators
  const regexThousand = new RegExp(`\\${thousandSeparator}`, 'g');
  let normalized = formattedValue.replace(regexThousand, '');

  // Replace decimal separator with a dot for parsing
  if (decimalSeparator !== '.') {
    const regexDecimal = new RegExp(`\\${decimalSeparator}`);
    normalized = normalized.replace(regexDecimal, '.');
  }

  const number = parseFloat(normalized);

  return isNaN(number) ? 0 : number;
}
