// src/components/settings/UserProfileSettingsPage.tsx

import React, { useEffect, useState } from 'react';
import { Container, Typography, Paper, Button, Snackbar, Alert, CircularProgress, Box } from '@mui/material';
import ProfileTabs from './ProfileTabs';
import { UserSettings } from '../../models/UserSettings';
import { useCookies } from 'react-cookie';
import './styles/UserProfileSettingsPage.css';
import apiClient from '../apiClient';
import { handleAxiosError } from '../../utils/handleAxiosError';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const UserProfileSettingsPage: React.FC = () => {
  const [cookies] = useCookies(['blinglong']);
  const [originalSettings, setOriginalSettings] = useState<UserSettings | null>(null);
  const [userSettings, setUserSettings] = useState<UserSettings | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchUserSettings = async () => {
      try {
        const response = await apiClient.get('/user/profile', {
          headers: {
            Authorization: `Bearer ${cookies.blinglong}`,
          },
        });

        const userData = response.data;

        // Combine countryCode and phoneNumber into a full phone number
        const fullPhoneNumber =
          userData.countryCode && userData.phoneNumber ? `${userData.countryCode}${userData.phoneNumber}` : '';

        const settings: UserSettings = {
          personalInfo: {
            firstName: userData.firstName || '',
            lastName: userData.lastName || '',
            displayName: userData.displayName || '',
            dateJoined: userData.dateJoined || '',
          },
          contactInfo: {
            email: userData.email || '',
            phoneNumber: fullPhoneNumber,
          },
          addressInfo: {
            billingAddress: userData.billingAddress || {
              street: '',
              city: '',
              state: '',
              zip: '',
              country: '',
            },
            shippingAddress: userData.shippingAddress || {
              street: '',
              city: '',
              state: '',
              zip: '',
              country: '',
            },
          },
          preferences: {
            hobbies: userData.hobbies || '',
            receiveNewsletters: userData.receiveNewsletters || false,
            receivePromotions: userData.receivePromotions || false,
            receiveUpdates: userData.receiveUpdates || false,
          },
          accountDetails: {
            dateJoined: userData.dateJoined || '',
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: '',
          },
          role: userData.role || 'LoginUser',
        };

        setOriginalSettings(settings);
        setUserSettings(settings);
      } catch (error: any) {
        console.error('Error fetching user settings:', error);
        const message = handleAxiosError(error) || 'Failed to fetch user data.';
        setErrorMessage(message);

        const blankSettings: UserSettings = {
          personalInfo: {
            firstName: '',
            lastName: '',
            displayName: '',
            dateJoined: '',
          },
          contactInfo: {
            email: '',
            phoneNumber: '',
          },
          addressInfo: {
            billingAddress: {
              street: '',
              city: '',
              state: '',
              zip: '',
              country: '',
            },
            shippingAddress: {
              street: '',
              city: '',
              state: '',
              zip: '',
              country: '',
            },
          },
          preferences: {
            hobbies: '',
            receiveNewsletters: false,
            receivePromotions: false,
            receiveUpdates: false,
          },
          accountDetails: {
            dateJoined: '',
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: '',
          },
          role: 'LoginUser',
        };
        setOriginalSettings(blankSettings);
        setUserSettings(blankSettings);
      }
    };

    fetchUserSettings();
  }, [cookies.blinglong]);

  const handleSave = async () => {
    if (!userSettings) return;
    setIsSaving(true);
    try {
      const { phoneNumber } = userSettings.contactInfo;
      let countryCode = '';
      let mobileNumber = '';

      if (phoneNumber) {
        const parsedNumber = parsePhoneNumberFromString(phoneNumber);
        if (parsedNumber && parsedNumber.isValid()) {
          countryCode = `+${parsedNumber.countryCallingCode}`;
          mobileNumber = parsedNumber.nationalNumber;
        } else {
          throw new Error('Invalid phone number format.');
        }
      }

      const settingsToUpdate = {
        personalInfo: userSettings.personalInfo,
        contactInfo: {
          email: userSettings.contactInfo.email,
          countryCode,
          phoneNumber: mobileNumber,
        },
        addressInfo: userSettings.addressInfo,
        preferences: userSettings.preferences,
      };

      await apiClient.put('/user/settings', settingsToUpdate, {
        headers: {
          Authorization: `Bearer ${cookies.blinglong}`,
        },
      });
      setOriginalSettings(userSettings);
      setSuccessMessage('Settings saved successfully!');
    } catch (error: any) {
      console.error('Error saving user settings:', error);
      const message = handleAxiosError(error) || 'Failed to save settings.';
      setErrorMessage(message);
    } finally {
      setIsSaving(false);
    }
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage('');
    setSuccessMessage('');
  };

  if (!userSettings) {
    return (
      <Container className="loading-container">
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container
      maxWidth="md"
      className="user-profile-settings-main-container"
      sx={{
        padding: isMobile ? '16px' : '24px',
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom>
        User Profile Settings
      </Typography>
      <Paper
        elevation={3}
        className="paper-container"
        sx={{
          padding: isMobile ? '16px' : '24px',
          overflow: 'hidden', // Prevents outer scrollbar
          display: 'flex',
          flexDirection: 'column',
          height: isMobile ? 'auto' : '80vh', // Adjust height as needed
        }}
      >
        <Box sx={{ flex: 1, overflowY: 'auto' }}>
          <ProfileTabs
            userSettings={userSettings}
            setUserSettings={setUserSettings as React.Dispatch<React.SetStateAction<UserSettings>>}
            originalSettings={originalSettings!}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingTop: '16px',
          }}
        >
          <Button variant="contained" color="primary" onClick={handleSave} disabled={isSaving}>
            {isSaving ? <CircularProgress size={24} /> : 'Save All Changes'}
          </Button>
        </Box>
      </Paper>

      {/* Success Snackbar */}
      <Snackbar
        open={Boolean(successMessage)}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>

      {/* Error Snackbar */}
      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default UserProfileSettingsPage;
