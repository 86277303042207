// src/components/users/UserListPage.tsx

import React, { useState, useEffect, useMemo } from 'react';
import {
  Container,
  Typography,
  Paper,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Snackbar,
  Alert,
  Pagination,
  TableSortLabel,
  CircularProgress,
  Box,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';
import './UserListPage.css'; // Import the CSS file
import { User } from '../../models/interfaces';
import apiClient from '../apiClient'; // Import your Axios instance
import { handleAxiosError } from '../../utils/handleAxiosError'; // Import the utility function

const UserListPage: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [sortField, setSortField] = useState<'email' | 'firstName' | 'lastName' | 'displayName' | 'role'>('email');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();
  const limit = 50;

  // Debounced search input handler
  const debouncedSearch = useMemo(
    () =>
      debounce((query: string) => {
        setSearchQuery(query);
        setPage(1);
      }, 500),
    []
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const fetchUsers = async (
    pageNumber = 1,
    query = '',
    sortField: 'email' | 'firstName' | 'lastName' | 'displayName' | 'role' = 'email',
    sortOrder: 'asc' | 'desc' = 'asc'
  ) => {
    setLoading(true);
    try {
      const response = await apiClient.get<{ users: User[]; totalPages: number }>('/user/list', {
        params: {
          q: query,
          page: pageNumber,
          limit: limit,
          sortField: sortField,
          sortOrder: sortOrder,
        },
        paramsSerializer: (params) => {
          return new URLSearchParams(params as any).toString();
        },
      });

      if (response.data && Array.isArray(response.data.users)) {
        setUsers(response.data.users);
        setTotalPages(response.data.totalPages);
      } else {
        console.error('Invalid data structure:', response.data);
        setErrorMessage('Invalid data structure received from server');
        setUsers([]); // Ensure users is an array
        setTotalPages(1); // Reset totalPages
      }
    } catch (error: any) {
      console.error('Error fetching users:', error);
      const message = handleAxiosError(error) || 'Failed to fetch users';
      setErrorMessage(message);
      setUsers([]); // Ensure users is an array
      setTotalPages(1); // Reset totalPages
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers(page, searchQuery, sortField, sortOrder);
  }, [page, searchQuery, sortField, sortOrder]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(e.target.value);
  };

  const handleDelete = async (id: string) => {
    if (!window.confirm('Are you sure you want to delete this user?')) {
      return;
    }

    try {
      await apiClient.delete(`/user/${id}`);
      setUsers((prev) => prev.filter((user) => user._id !== id));
      setSuccessMessage('User deleted successfully');
    } catch (error: any) {
      console.error('Error deleting user:', error);
      const message = handleAxiosError(error) || 'Failed to delete user';
      setErrorMessage(message);
    }
  };

  const handleSort = (field: 'email' | 'firstName' | 'lastName' | 'role') => {
    if (sortField === field) {
      // Toggle sort order
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
    setPage(1); // Reset to first page on sort
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage('');
    setSuccessMessage('');
  };

  return (
    <div className="user-list-page-container">
      <Container className="user-main-container">
        <Typography variant="h4" component="h1" gutterBottom>
          User Management
        </Typography>

        <TextField label="Search Users" onChange={handleSearchChange} fullWidth margin="normal" variant="outlined" />

        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={sortField === 'email'}
                      direction={sortField === 'email' ? sortOrder : 'asc'}
                      onClick={() => handleSort('email')}
                    >
                      Email
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortField === 'firstName'}
                      direction={sortField === 'firstName' ? sortOrder : 'asc'}
                      onClick={() => handleSort('firstName')}
                    >
                      First Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortField === 'lastName'}
                      direction={sortField === 'lastName' ? sortOrder : 'asc'}
                      onClick={() => handleSort('lastName')}
                    >
                      Last Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortField === 'role'}
                      direction={sortField === 'role' ? sortOrder : 'asc'}
                      onClick={() => handleSort('role')}
                    >
                      Role
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <TableRow key={user._id}>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.firstName}</TableCell>
                    <TableCell>{user.lastName}</TableCell>
                    <TableCell>{user.role}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        color="primary"
                        onClick={() => navigate(`/edit-user/${user._id}`)}
                        aria-label={`edit user ${user.email}`}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => handleDelete(user._id)}
                        aria-label={`delete user ${user.email}`}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {users.length === 0 && !loading && (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No users found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Loading Indicator */}
          {loading && (
            <Box display="flex" justifyContent="center" alignItems="center" padding="16px">
              <CircularProgress />
            </Box>
          )}

          {/* Pagination */}
          {!loading && totalPages > 1 && (
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              className="paginationContainer"
              color="primary"
              showFirstButton
              showLastButton
              sx={{ display: 'flex', justifyContent: 'center', padding: '16px' }}
            />
          )}
        </Paper>

        {/* Success Snackbar */}
        <Snackbar
          open={Boolean(successMessage)}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
            {successMessage}
          </Alert>
        </Snackbar>

        {/* Error Snackbar */}
        <Snackbar
          open={Boolean(errorMessage)}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>
      </Container>
    </div>
  );
};

export default UserListPage;
