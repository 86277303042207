// src/components/login/OAuth2RedirectHandler.tsx

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Corrected import
import { useAuth } from '../../contexts/AuthContext';
import { Box, CircularProgress, Typography, Alert, useTheme, useMediaQuery } from '@mui/material';

const OAuth2RedirectHandler: React.FC = () => {
  const navigate = useNavigate();
  const { setAuthTokens } = useAuth();
  const [error, setError] = React.useState<string>('');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const handleOAuthResponse = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const errorParam = urlParams.get('error');

      if (errorParam) {
        setError(`Authentication error: ${errorParam}`);
        console.error(`Authentication error: ${errorParam}`);
        navigate('/auth/login');
        return;
      }

      if (code) {
        try {
          const domain = process.env.REACT_APP_COGNITO_DOMAIN;
          const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
          const redirectUri = process.env.REACT_APP_REDIRECT_URI || 'http://localhost:3000/oauth2/idpresponse';
          const codeVerifier = localStorage.getItem('code_verifier');
          if (!codeVerifier) {
            setError('Code verifier not found.');
            console.error('Code verifier not found.');
            navigate('/auth/login');
            return;
          }

          const body = new URLSearchParams();
          body.append('grant_type', 'authorization_code');
          body.append('client_id', clientId || '');
          body.append('code', code);
          body.append('redirect_uri', redirectUri);
          body.append('code_verifier', codeVerifier); // Include the code_verifier

          console.log('Exchanging code for tokens:', {
            domain,
            clientId,
            redirectUri,
            code,
            codeVerifier,
          });

          const response = await fetch(`https://${domain}/oauth2/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: body.toString(),
          });

          const responseBody = await response.text();
          console.log('Token endpoint response:', response.status, responseBody);

          if (!response.ok) {
            let errorMessage = 'Unknown error';
            try {
              const errorData = JSON.parse(responseBody);
              errorMessage = errorData.error_description || errorData.error || errorMessage;
            } catch (e) {
              console.error('Failed to parse error response:', responseBody);
            }
            setError(`Token exchange failed: ${errorMessage}`);
            console.error('Token exchange failed:', responseBody);
            navigate('/auth/login');
            return;
          }

          const data = JSON.parse(responseBody);

          if (data.access_token && data.id_token) {
            // Store tokens securely (consider using more secure storage mechanisms)
            localStorage.setItem('access_token', data.access_token);
            localStorage.setItem('id_token', data.id_token);
            localStorage.setItem('refresh_token', data.refresh_token || '');

            // Check if onboarding is completed
            const decodedToken: any = jwtDecode(data.id_token);
            setAuthTokens({
              accessToken: data.access_token,
              idToken: data.id_token,
              refreshToken: data.refresh_token || '',
              user: decodedToken,
            });

            // Fetch user profile to get onboarding status
            try {
              const profileResponse = await fetch('/api/user/profile', {
                headers: {
                  Authorization: `Bearer ${data.access_token}`,
                },
              });
              const profileData = await profileResponse.json();
              if (profileData.hasCompletedOnboarding) {
                navigate('/');
              } else {
                navigate('/auth/onboarding');
              }
            } catch (error) {
              console.error('Error fetching user profile:', error);
              navigate('/');
            }

            // Remove the code_verifier from storage as it's no longer needed
            localStorage.removeItem('code_verifier');

            // Remove the authorization code from the URL to prevent reprocessing
            window.history.replaceState({}, document.title, window.location.pathname);
          } else {
            setError('Token response does not contain access_token and id_token.');
            console.error('Token response:', data);
            navigate('/auth/login');
          }
        } catch (error: any) {
          setError(`Error exchanging code for tokens: ${error.message}`);
          console.error('Error exchanging code for tokens:', error);
          navigate('/auth/login');
        }
      } else {
        setError('No code parameter found in URL.');
        console.error('No code parameter found in URL.');
        navigate('/auth/login');
      }
    };

    handleOAuthResponse();
  }, [navigate, setAuthTokens]);

  return (
    <Box
      sx={{
        backgroundImage: `url('/background1.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          boxShadow: 3,
          p: 4,
          borderRadius: 2,
          maxWidth: 400,
          width: '90%',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          textAlign: 'center',
        }}
      >
        {error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          <>
            <CircularProgress />
            <Typography variant="h6" sx={{ mt: 2 }}>
              Processing authentication...
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

export default OAuth2RedirectHandler;
