// src/contexts/WebSocketContext.tsx

import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { useAuth } from './AuthContext'; // Adjust the import path as needed

interface WebSocketContextProps {
  socket: Socket | null;
  isConnected: boolean;
}

const WebSocketContext = createContext<WebSocketContextProps>({
  socket: null,
  isConnected: false,
});

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isLoggedIn, loading } = useAuth(); // Get isLoggedIn and loading from AuthContext
  const socketRef = useRef<Socket | null>(null);
  const [isConnected, setIsConnected] = useState(false);

  const API_BASE_URL =
    window.location.origin === 'http://localhost:3000'
      ? 'http://localhost:5000'
      : process.env.REACT_APP_API_BASE_URL_WSS;

  useEffect(() => {
    if (loading) {
      // Authentication status is still loading, do not connect WebSocket yet
      console.log('WebSocketProvider: Authentication loading, not connecting yet.');
      return;
    }

    if (!isLoggedIn) {
      // User is not authenticated, do not connect WebSocket
      console.log('WebSocketProvider: User not authenticated, not connecting.');
      // Disconnect if socket is connected
      if (socketRef.current) {
        console.log('Disconnecting from WebSocket server because user is not logged in');
        socketRef.current.disconnect();
        socketRef.current = null;
        setIsConnected(false);
      }
      return;
    }

    if (!socketRef.current) {
      console.log('Attempting to connect to WebSocket server at', API_BASE_URL);
      // Initialize Socket.IO client
      socketRef.current = io(API_BASE_URL, {
        withCredentials: true, // Send cookies with the request
        transports: ['websocket'], // Use WebSocket only
      });

      // Connection established
      socketRef.current.on('connect', () => {
        console.log('Connected to WebSocket server');
        setIsConnected(true);
      });

      // Handle connection errors
      socketRef.current.on('connect_error', (err) => {
        console.error('WebSocket connection error:', err.message);
      });

      // Handle disconnection
      socketRef.current.on('disconnect', (reason) => {
        console.log('Disconnected from WebSocket server:', reason);
        setIsConnected(false);
      });
    }

    // Cleanup on unmount
    return () => {
      if (socketRef.current) {
        console.log('Disconnecting from WebSocket server on component unmount');
        socketRef.current.disconnect();
        socketRef.current = null;
        setIsConnected(false);
      }
    };
  }, [isLoggedIn, loading, API_BASE_URL]);

  return (
    <WebSocketContext.Provider value={{ socket: socketRef.current, isConnected }}>{children}</WebSocketContext.Provider>
  );
};
