// src/components/Categories.tsx

import React from 'react';
import { Container, Typography, Grid2, Card, CardActionArea, CardContent, Box } from '@mui/material';

// Define your categories here
const categories = [
  { title: 'Watches', imageUrl: '/images/watches.png' },
  { title: 'Jewelry', imageUrl: '/images/jewelry.png' },
  { title: 'Diamonds', imageUrl: '/images/diamonds.png' },
  { title: 'Gemstones', imageUrl: '/images/gemstones.png' },
];

interface CategoriesProps {
  onCategorySelect: (category: string) => void; // Function to handle category selection
}

const Categories: React.FC<CategoriesProps> = ({ onCategorySelect }) => {
  return (
    <Container maxWidth="lg" sx={{ paddingY: { xs: 1, sm: 1, md: 2 }, paddingX: { xs: 1, sm: 1, md: 2 } }}>
      <Grid2 container spacing={{ xs: 1, sm: 2, md: 3 }}>
        {categories.map((category) => (
          <Grid2 key={category.title} size={{ xs: 3, sm: 3, md: 3 }}>
            <Card
              sx={{
                height: '100%',
                borderRadius: 2,
                boxShadow: 1,
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                  transform: 'scale(1.02)',
                  boxShadow: 3,
                },
              }}
            >
              <CardActionArea
                onClick={() => {
                  console.log('Category clicked:', category.title); // Debugging log
                  onCategorySelect(category.title); // Trigger the callback
                }}
                aria-label={`Select category ${category.title}`} // Accessibility label
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  height: '100%',
                  padding: { xs: 0.2, sm: 1, md: 1.5 }, // Responsive padding
                }}
              >
                {/* Responsive Image with Reduced Height */}
                <Box
                  component="img"
                  src={category.imageUrl}
                  alt={`${category.title} category`} // Enhanced alt text
                  loading="lazy" // Lazy loading for performance
                  sx={{
                    height: { xs: 60, sm: 80, md: 100, xl: 250 }, // Reduced heights
                    width: '100%',
                    objectFit: 'contain',
                    mb: { xs: 0.5, sm: 1, md: 1, xl: 1 }, // Reduced margin bottom
                  }}
                />
                {/* Category Title with Smaller Font */}
                <CardContent sx={{ padding: 0, textAlign: 'center' }}>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      fontSize: {
                        xs: '1rem',
                        sm: '1rem',
                        md: '1.5rem',
                        lg: '2rem',
                      }, // Responsive font sizes
                      fontWeight: 500,
                      whiteSpace: 'nowrap', // Prevent text wrapping
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {category.title}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid2>
        ))}
      </Grid2>
    </Container>
  );
};

export default Categories;
