// src/contexts/AuthContext.tsx

import React, { createContext, useState, useEffect, ReactNode, useContext, useCallback, useMemo } from 'react';
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession,
  CognitoUserAttribute,
} from 'amazon-cognito-identity-js';
import { useNavigate } from 'react-router-dom';
import { AuthTokens } from '../types/auth';
import { refreshTokens } from './tokenManager'; // Import the singleton token refresh
import debounce from 'lodash/debounce'; // Import debounce from lodash
import { UpdateUserProfileDto } from '../components/onboarding/dto/UpdateUserProfileDto'; // Ensure correct path

interface CurrentUser {
  email: string;
  userId: string;
  displayName: string;
  role: string;
  hasCompletedOnboarding: boolean;
  // Add other user attributes as needed
}

interface AuthContextProps {
  user: CurrentUser | null;
  isLoggedIn: boolean;
  loading: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  requireAuth: () => Promise<void>;
  getAccessToken: () => Promise<string>;
  signUp: (email: string, password: string, attributes: { [key: string]: string }) => Promise<void>;
  confirmSignUp: (email: string, code: string) => Promise<void>;
  setAuthTokens: (tokens: AuthTokens) => void;
  updateUserProfile: (data: UpdateUserProfileDto) => Promise<void>; // Updated to use specific type
}

const AuthContext = createContext<AuthContextProps>({
  user: null,
  isLoggedIn: false,
  loading: true,
  login: async () => {},
  logout: () => {},
  requireAuth: async () => {},
  getAccessToken: async () => '',
  signUp: async () => {},
  confirmSignUp: async () => {},
  setAuthTokens: () => {},
  updateUserProfile: async () => {},
});

export const useAuth = () => useContext(AuthContext);

interface AuthProviderProps {
  children: ReactNode;
}

interface PendingAuthPromise {
  resolve: () => void;
  reject: (reason?: any) => void;
}

const poolData = {
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || 'YOUR_USER_POOL_ID', // Your User Pool ID
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID || 'YOUR_APP_CLIENT_ID', // Your App Client ID
};

const userPool = new CognitoUserPool(poolData);

/**
 * Utility function to fetch user profile from the server
 */
const fetchUserProfile = async (accessToken: string): Promise<CurrentUser> => {
  const response = await fetch('/api/user/profile', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch user profile');
  }

  const data = await response.json();
  return {
    email: data.email,
    userId: data._id,
    displayName: data.displayName || data.email,
    role: data.role,
    hasCompletedOnboarding: data.hasCompletedOnboarding, // Ensure backend provides this
    // ... other attributes
  };
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<CurrentUser | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [pendingAuthPromises, setPendingAuthPromises] = useState<PendingAuthPromise[]>([]);
  const [tokens, setTokens] = useState<AuthTokens | null>(null);

  /**
   * Logout Function
   */
  const logout = useCallback(() => {
    console.log('Logging out...');
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
    }
    localStorage.removeItem('id_token');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    setUser(null);
    setIsLoggedIn(false);
    setTokens(null);
    navigate('/auth/login');
  }, [navigate]);

  /**
   * setAuthTokens Function
   */
  const setAuthTokensHandler = useCallback(
    async (authTokens: AuthTokens) => {
      console.log('Setting auth tokens:', authTokens);
      setTokens(authTokens);
      localStorage.setItem('id_token', authTokens.idToken);
      localStorage.setItem('access_token', authTokens.accessToken);
      localStorage.setItem('refresh_token', authTokens.refreshToken);

      try {
        const userData = await fetchUserProfile(authTokens.accessToken);
        console.log('Fetched user profile:', userData);
        setUser(userData);
        setIsLoggedIn(true);
      } catch (error) {
        console.error('Error fetching user profile:', error);
        logout();
      }
    },
    [logout]
  );

  /**
   * Login Function
   */
  const login = useCallback(
    async (email: string, password: string) => {
      console.log('Attempting to log in:', email);
      const authenticationDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
      });

      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: userPool,
      });

      return new Promise<void>((resolve, reject) => {
        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: async (session: CognitoUserSession) => {
            console.log('Authentication successful:', session);
            const idToken = session.getIdToken().getJwtToken();
            const accessToken = session.getAccessToken().getJwtToken();
            const refreshToken = session.getRefreshToken().getToken();

            const authTokens: AuthTokens = {
              accessToken,
              idToken,
              refreshToken,
              user: null, // Will be set after fetching from server
            };

            try {
              await setAuthTokensHandler(authTokens);
              console.log('Login process completed successfully.');
              resolve();
            } catch (error) {
              console.error('Error during login:', error);
              reject(error);
            }
          },
          onFailure: (err) => {
            console.error('Login failed:', err);
            reject(err);
          },
        });
      });
    },
    [setAuthTokensHandler]
  );

  /**
   * requireAuth Function
   */
  const requireAuth = useCallback((): Promise<void> => {
    if (isLoggedIn) {
      return Promise.resolve();
    } else {
      return new Promise<void>((resolve, reject) => {
        setPendingAuthPromises((prev) => [...prev, { resolve, reject }]);
        navigate('/auth/login');
      });
    }
  }, [isLoggedIn, navigate]);

  /**
   * getAccessToken Function
   */
  const getAccessToken = useCallback(async (): Promise<string> => {
    if (tokens?.accessToken) {
      return tokens.accessToken;
    } else {
      throw new Error('No access token found');
    }
  }, [tokens]);

  /**
   * signUp Function
   */
  const signUp = useCallback(async (email: string, password: string, attributes: { [key: string]: string }) => {
    console.log('Signing up:', email);
    const attributeList: CognitoUserAttribute[] = [];
    for (const key in attributes) {
      if (attributes.hasOwnProperty(key)) {
        const attribute = new CognitoUserAttribute({
          Name: key,
          Value: attributes[key],
        });
        attributeList.push(attribute);
      }
    }

    return new Promise<void>((resolve, reject) => {
      userPool.signUp(email, password, attributeList, [], (err, result) => {
        if (err) {
          console.error('Sign-up error:', err);
          reject(err);
        } else {
          console.log('Sign-up success:', result);
          resolve();
        }
      });
    });
  }, []);

  /**
   * confirmSignUp Function
   */
  const confirmSignUp = useCallback(async (email: string, code: string) => {
    console.log('Confirming sign-up for:', email);
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    return new Promise<void>((resolve, reject) => {
      cognitoUser.confirmRegistration(code, true, (err, result) => {
        if (err) {
          console.error('Confirmation error:', err);
          reject(err);
        } else {
          console.log('Confirmation success:', result);
          resolve();
        }
      });
    });
  }, []);

  /**
   * updateUserProfile Function
   */
  const updateUserProfile = useCallback(
    async (data: UpdateUserProfileDto) => {
      // Updated parameter type
      if (!tokens?.accessToken) {
        throw new Error('No access token found');
      }

      console.log('Updating user profile with data:', data);

      const response = await fetch('/api/user/update-profile', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${tokens.accessToken}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Failed to update profile:', errorData);
        throw new Error(errorData.message || 'Failed to update profile');
      }

      // Fetch the updated user profile
      const updatedUser = await fetchUserProfile(tokens.accessToken);
      console.log('Updated user profile:', updatedUser);
      setUser(updatedUser);
    },
    [tokens]
  );

  /**
   * Handle pending authentication promises
   */
  useEffect(() => {
    if (isLoggedIn && pendingAuthPromises.length > 0) {
      console.log('Resolving pending auth promises:', pendingAuthPromises.length);
      pendingAuthPromises.forEach((promise) => promise.resolve());
      setPendingAuthPromises([]);
    }
  }, [isLoggedIn, pendingAuthPromises]);

  /**
   * Token Refresh Logic with Debounce
   */
  useEffect(() => {
    // Define the token refresh function
    const handleTokenRefresh = async () => {
      const refreshToken = localStorage.getItem('refresh_token');
      const domain = process.env.REACT_APP_COGNITO_DOMAIN;
      const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;

      if (refreshToken && domain && clientId) {
        console.log('Refreshing tokens...');
        try {
          const newTokens = await refreshTokens(refreshToken, clientId, domain);
          console.log('Tokens refreshed:', newTokens);
          await setAuthTokensHandler(newTokens);
        } catch (error) {
          console.error('Error during token refresh:', error);
          logout();
        }
      } else {
        console.log('No refresh token found, logging out.');
        logout();
      }
    };

    // Create a debounced version of the token refresh function
    const debouncedHandleTokenRefresh = debounce(handleTokenRefresh, 1000, {
      leading: true,
      trailing: false,
    });

    const checkTokenExpiry = () => {
      const idToken = localStorage.getItem('id_token');
      if (idToken) {
        try {
          // Decode token to check expiry
          const decodedToken: any = parseJwt(idToken);
          const currentTime = Date.now() / 1000;

          console.log('Token expiry check:', decodedToken.exp, 'currentTime:', currentTime);

          if (decodedToken.exp < currentTime + 300) {
            // Refresh 5 minutes before expiration
            console.log('Token is about to expire, refreshing...');
            debouncedHandleTokenRefresh();
          } else {
            console.log('Token is valid, no need to refresh.');
          }
        } catch (error) {
          console.error('Error decoding token for expiry check:', error);
          logout();
        }
      }
    };

    const parseJwt = (token: string): any => {
      try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
          atob(base64)
            .split('')
            .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
            .join('')
        );
        return JSON.parse(jsonPayload);
      } catch (e) {
        console.error('Failed to parse JWT:', e);
        return {};
      }
    };

    // Initial check on mount
    console.log('Initializing token expiry check on mount.');
    checkTokenExpiry();

    // Set up the interval once on mount
    const interval = setInterval(
      () => {
        console.log('Running periodic token expiry check.');
        checkTokenExpiry();
      },
      5 * 60 * 1000
    ); // Check every 5 minutes

    return () => {
      clearInterval(interval);
      debouncedHandleTokenRefresh.cancel(); // Cleanup debounce on unmount
    };
  }, [logout, setAuthTokensHandler]);

  /**
   * Memoize the context value to prevent unnecessary re-renders
   */
  const authContextValue = useMemo(
    () => ({
      user,
      isLoggedIn,
      loading,
      login,
      logout,
      requireAuth,
      getAccessToken,
      signUp,
      confirmSignUp,
      setAuthTokens: setAuthTokensHandler,
      updateUserProfile, // Added to context
    }),
    [
      user,
      isLoggedIn,
      loading,
      login,
      logout,
      requireAuth,
      getAccessToken,
      signUp,
      confirmSignUp,
      setAuthTokensHandler,
      updateUserProfile,
    ]
  );

  /**
   * Initialize user from localStorage on mount
   */
  useEffect(() => {
    const initializeAuth = async () => {
      console.log('Initializing authentication from localStorage.');
      const idToken = localStorage.getItem('id_token');
      const accessToken = localStorage.getItem('access_token');
      const refreshToken = localStorage.getItem('refresh_token');

      if (idToken && accessToken) {
        const authTokens: AuthTokens = {
          accessToken,
          idToken,
          refreshToken: refreshToken || '',
          user: null,
        };

        try {
          await setAuthTokensHandler(authTokens);
        } catch (error) {
          console.error('Error initializing auth:', error);
          logout();
        }
      }
      setLoading(false);
    };

    initializeAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array ensures this runs once on mount

  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
};

export default AuthContext;
