// src/components/apiClient.ts

import axios from 'axios';

// Determine the API base URL based on the environment
const API_BASE_URL =
  window.location.origin === 'http://localhost:3000'
    ? 'http://localhost:5000/api'
    : process.env.REACT_APP_API_BASE_URL || '/api';

// Create an Axios instance with the base URL
const apiClient = axios.create({
  baseURL: API_BASE_URL,
});

// Add a request interceptor to include the Authorization header
apiClient.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiClient;
