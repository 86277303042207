// src/components/LimitedOptionsList.tsx

import React, { useState } from 'react';
import { List, ListItem, FormControlLabel, Checkbox, Button } from '@mui/material';

interface LimitedOptionsListProps {
  field: any;
  dynamicFilterValues: { [key: string]: any };
  handleInputChange: (name: string, value: any) => void;
}

const LimitedOptionsList: React.FC<LimitedOptionsListProps> = ({ field, dynamicFilterValues, handleInputChange }) => {
  const [showAll, setShowAll] = useState(false);
  const MAX_OPTIONS = 5; // Number of options to show by default

  const optionsToShow = showAll ? field.values : field.values.slice(0, MAX_OPTIONS);

  return (
    <>
      <List dense>
        {optionsToShow.map((value: string) => (
          <ListItem key={value}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={dynamicFilterValues[field.name]?.includes(value) || false}
                  onChange={() => {
                    const currentValues = dynamicFilterValues[field.name] || [];
                    const newValues = currentValues.includes(value)
                      ? currentValues.filter((v: string) => v !== value)
                      : [...currentValues, value];
                    handleInputChange(field.name, newValues);
                  }}
                  color="primary"
                />
              }
              label={value}
            />
          </ListItem>
        ))}
      </List>
      {field.values.length > MAX_OPTIONS && (
        <Button onClick={() => setShowAll(!showAll)} size="small" className="show-more-button">
          {showAll ? 'Show Less' : 'Show More'}
        </Button>
      )}
    </>
  );
};

export default LimitedOptionsList;
