// src/contexts/tokenManager.ts

import { AuthTokens } from '../types/auth';

let isRefreshing = false;
let refreshPromise: Promise<AuthTokens> | null = null;

export const refreshTokens = async (refreshToken: string, clientId: string, domain: string): Promise<AuthTokens> => {
  if (isRefreshing && refreshPromise) {
    console.log('Token refresh already in progress. Awaiting existing refresh.');
    return refreshPromise;
  }

  isRefreshing = true;

  refreshPromise = new Promise<AuthTokens>(async (resolve, reject) => {
    try {
      const body = new URLSearchParams();
      body.append('grant_type', 'refresh_token');
      body.append('client_id', clientId);
      body.append('refresh_token', refreshToken);

      const response = await fetch(`https://${domain}/oauth2/token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: body.toString(),
      });

      const data = await response.json();

      if (!response.ok) {
        console.error('Token refresh failed:', data);
        reject(new Error(data.error_description || 'Failed to refresh token'));
        return;
      }

      const accessToken = data.access_token;
      const idToken = data.id_token || '';

      console.log('Token refresh successful.');

      resolve({
        accessToken,
        idToken,
        refreshToken,
        user: null, // Will be set after fetching user profile
      });
    } catch (error) {
      console.error('Error during token refresh:', error);
      reject(error);
    } finally {
      isRefreshing = false;
      refreshPromise = null;
      console.log('Token refresh completed.');
    }
  });

  return refreshPromise;
};
