// src/components/add-auction/fields/productFields.ts
export const productFields = [
  {
    name: 'title',
    label: 'Calculated Title',
    type: 'readonly',
    required: true,
  },
  {
    name: 'description',
    label: 'Description',
    ai_instruction: 'Please provide long description, need to be around 80 words long',
    type: 'text',
    multiline: true,
    rows: 6,
    required: true,
  },
  {
    name: 'customerSKU',
    label: 'Customer SKU',
    type: 'text',
  },
  {
    name: 'supplierSitePreview',
    label: 'Supplier Site Preview',
    type: 'text',
    ai_instruction:
      'Please provide the URL of the supplier site preview image or video taken from the description input',
  },
];
