// src/components/onboarding/Step4MobileVerification.tsx

import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { MobileVerificationDto, OnboardingPersonalInfo } from './dto/OnboardingDto';
import apiClient from '../apiClient';
import { useMobileVerification } from './MobileVerificationContext'; // Import the custom hook

interface StepProps {
  onNext: (data: any) => Promise<void>; // Changed to 'any'
  onSkip: () => void;
  initialData: MobileVerificationDto;
  personalInfo: OnboardingPersonalInfo;
}

const Step4MobileVerification: React.FC<StepProps> = ({ onNext, onSkip, initialData, personalInfo }) => {
  const [verificationCode, setVerificationCode] = useState<string>(initialData.verificationCode);
  const [error, setError] = useState<string>('');
  const [sent, setSent] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { isSending, setIsSending, isVerifying, setIsVerifying } = useMobileVerification();

  const sendVerificationCode = async () => {
    if (isSending) {
      console.log('sendVerificationCode: already sending, skipping');
      return;
    }
    setIsSending(true);
    setLoading(true);
    try {
      console.log('sendVerificationCode called');
      await apiClient.post('/user/send-verification-code');
      setSent(true);
      setError('');
    } catch (err: any) {
      console.error('Error sending verification code:', err);
      setError('Failed to send verification code.');
    } finally {
      setLoading(false);
      setIsSending(false);
    }
  };

  const handleVerify = async () => {
    console.log('handleVerify function called');
    if (!verificationCode) {
      setError('Please enter the verification code.');
      return;
    }
    if (isVerifying) {
      console.log('handleVerify: already verifying, skipping');
      return;
    }
    setIsVerifying(true);
    setLoading(true);
    try {
      console.log('handleVerify called');
      await apiClient.post('/user/verify-mobile', {
        verificationCode: verificationCode.trim(),
      });
      await onNext({}); // Pass empty object as verification is already handled
    } catch (err: any) {
      console.error('Error verifying code:', err);
      setError('Verification failed. Please try again.');
    } finally {
      setLoading(false);
      setIsVerifying(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Typography variant="h6" gutterBottom>
        Mobile Verification
      </Typography>
      <Typography variant="body1" gutterBottom>
        {personalInfo.mobileNumber
          ? `To: ${personalInfo.countryCode}-${personalInfo.mobileNumber}`
          : 'No phone number found'}
      </Typography>

      {error && <Typography color="error">{error}</Typography>}

      {!sent ? (
        <Button
          variant="contained"
          color="primary"
          onClick={sendVerificationCode}
          disabled={loading || isSending}
          sx={{ marginBottom: 2 }}
        >
          {loading ? 'Sending...' : 'Send Verification Code'}
        </Button>
      ) : (
        <Box sx={{ flexGrow: 1 }}>
          <TextField
            label="Verification Code"
            fullWidth
            margin="normal"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            required
          />
          <Box display="flex" justifyContent="space-between" marginTop={3}>
            <Button
              variant="contained"
              color="primary"
              type="button" // Changed to 'button'
              onClick={handleVerify} // Added onClick handler
              disabled={loading || isVerifying}
            >
              {loading ? 'Verifying...' : 'Verify'}
            </Button>
          </Box>
        </Box>
      )}

      {/* "Skip" Button Always Visible */}
      <Box display="flex" justifyContent="flex-end" marginTop={2}>
        <Button variant="text" onClick={onSkip} disabled={loading}>
          Skip
        </Button>
      </Box>
    </Box>
  );
};

export default Step4MobileVerification;
