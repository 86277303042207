// src/components/FilterSidebar.tsx

import React, { useState, useEffect, useRef } from 'react';
import {
  Drawer,
  FormControlLabel,
  Typography,
  Slider,
  Divider,
  Switch,
  TextField,
  Button,
  Chip,
  useMediaQuery,
  Checkbox,
} from '@mui/material';
import './FilterSidebar.css';
import { useLocation, useNavigate } from 'react-router-dom';
import FilterSection from './FilterSection';
import DynamicFilterSection from './DynamicFilterSection';
import { categoryFieldsMap } from '../add-auction/fields/index';

const categories = ['Watches', 'Jewelry', 'Diamonds', 'Gemstones'];

interface FilterSidebarProps {
  isOpen: boolean;
  onClose: () => void;
  selectedCategory: string | null;
  filters: any;
  onApplyFilters: (filters: any) => void;
  isSidebarVisible: boolean; // Add this prop
}

const MAX_PRICE = 3000;

const FilterSidebar: React.FC<FilterSidebarProps> = ({
  isOpen,
  onClose,
  selectedCategory,
  filters,
  onApplyFilters,
  isSidebarVisible,
}) => {
  // Detect if the device is mobile
  const isMobile = useMediaQuery('(max-width:767px)');

  // Filters configuration
  const filtersConfig = [
    {
      key: 'categories',
      title: 'Categories',
      items: categories,
    },
  ];

  // State for selected items in filters
  const [selectedFilters, setSelectedFilters] = useState<{
    [key: string]: string[];
  }>({
    categories: [],
  });

  const [priceRange, setPriceRange] = useState<number[]>([0, MAX_PRICE]);
  const [myBids, setMyBids] = useState<boolean>(false);
  const [favorites, setFavorites] = useState<boolean>(false);
  const [myAuctions, setMyAuctions] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchSupplier, setSearchSupplier] = useState<string>('');
  const [supplier, setSupplier] = useState<string>('');

  const [dynamicFilters, setDynamicFilters] = useState<Array<any>>([]);
  const [dynamicFilterValues, setDynamicFilterValues] = useState<{
    [key: string]: any;
  }>({});

  const isFilterChangedByUser = useRef(false);
  const navigate = useNavigate();
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const location = useLocation();

  const isRouteWithSidebar = () => {
    return (
      location.pathname === '/' ||
      location.pathname.startsWith('/category/') ||
      location.pathname.startsWith('/supplier/') ||
      location.pathname.startsWith('/ai/search')
    );
  };

  // Update internal state when filters prop changes
  useEffect(() => {
    setSelectedFilters({
      categories: filters?.categories || [],
    });
    setPriceRange(filters?.priceRange || [0, MAX_PRICE]);
    setMyBids(filters?.myBids || false);
    setFavorites(filters?.favorites || false);
    setMyAuctions(filters?.myAuctions || false);
    setSearchQuery(filters?.q || '');
    setSearchSupplier(filters?.supplierName || '');
    setSupplier(filters?.supplier || '');
    setDynamicFilterValues(filters?.dynamicFilters || {});

    // Load dynamic filters based on categories
    if (filters?.categories && filters.categories.length === 1) {
      const category = filters.categories[0];
      const fields = categoryFieldsMap[category] || [];
      setDynamicFilters(fields);
    } else {
      setDynamicFilters([]);
      // Return to / if no category is selected
      if (!isInitialLoad && isRouteWithSidebar() && location.pathname !== '/ai/search') {
        console.log('No category selected so returning to /');
        navigate('/');
      }
    }
  }, [filters, navigate, isInitialLoad]);

  // Update selectedFilters when selectedCategory prop changes
  useEffect(() => {
    if (selectedCategory) {
      setSelectedFilters((prevState) => ({
        ...prevState,
        categories: [selectedCategory],
      }));

      const fields = categoryFieldsMap[selectedCategory] || [];
      setDynamicFilters(fields);
    } else {
      setSelectedFilters((prevState) => ({
        ...prevState,
        categories: [],
      }));
      setDynamicFilters([]);
    }
  }, [selectedCategory]);

  // Handle toggling of filter items
  const handleToggleFilterItem = (filterKey: string, item: string) => {
    setIsInitialLoad(false);
    isFilterChangedByUser.current = true;
    setSelectedFilters((prevState) => {
      const currentItems = prevState[filterKey] || [];
      let newItems;
      if (currentItems.includes(item)) {
        newItems = currentItems.filter((i) => i !== item);
      } else {
        newItems = [...currentItems, item];
      }

      // If the filterKey is 'categories', ensure only one category is selected
      if (filterKey === 'categories') {
        newItems = currentItems.includes(item) ? [] : [item];
      }

      return { ...prevState, [filterKey]: newItems };
    });

    // Update dynamic filters based on the new category selection
    if (filterKey === 'categories') {
      const newCategory = item;
      if (selectedFilters.categories.includes(item)) {
        // Deselecting the category
        setDynamicFilters([]);
        setDynamicFilterValues({});
      } else {
        const fields = categoryFieldsMap[newCategory] || [];
        setDynamicFilters(fields);
        setDynamicFilterValues({});
      }

      // Handle navigation when category is selected
      if (location.pathname !== '/ai/search') {
        if (newCategory) {
          navigate(`/category/${newCategory}`);
        } else {
          console.log('No category selected so returning to /');
          navigate('/');
        }
      }
    }
  };

  const handlePriceChange = (event: Event, newValue: number | number[]) => {
    isFilterChangedByUser.current = true;
    setPriceRange(newValue as number[]);
  };

  const handleToggleSwitch = (setter: React.Dispatch<React.SetStateAction<boolean>>) => {
    isFilterChangedByUser.current = true;
    setter((prev) => !prev);
  };

  const handlePriceRangeReset = () => {
    setPriceRange([0, MAX_PRICE]);
    isFilterChangedByUser.current = true;
  };

  // Handle search query change
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    isFilterChangedByUser.current = true;
    setSearchQuery(e.target.value);
  };

  const handleSearchSupplierChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    isFilterChangedByUser.current = true;
    setSearchSupplier(e.target.value);
  };

  const handleClearAllFilters = () => {
    isFilterChangedByUser.current = true;
    setSelectedFilters({
      categories: [],
    });
    setPriceRange([0, MAX_PRICE]);
    setMyBids(false);
    setFavorites(false);
    setMyAuctions(false);
    setSearchQuery('');
    setSearchSupplier('');
    setSupplier('');
    setDynamicFilters([]);
    setDynamicFilterValues({});
    console.log('No category selected so returning to /');
    onClose();
    navigate('/');
  };

  // Handle dynamic filter delete
  const handleDynamicFilterDelete = (filterKey: string, value: any) => {
    isFilterChangedByUser.current = true;
    if (Array.isArray(dynamicFilterValues[filterKey])) {
      const newValues = dynamicFilterValues[filterKey].filter((v: string) => v !== value);
      setDynamicFilterValues((prev) => ({
        ...prev,
        [filterKey]: newValues,
      }));
    } else {
      setDynamicFilterValues((prev) => ({
        ...prev,
        [filterKey]: '',
      }));
    }
  };

  const handleDynamicFilterValues = (filterKey: string, value: any) => {
    isFilterChangedByUser.current = true;
    setDynamicFilterValues((prev) => ({
      ...prev,
      [filterKey]: value,
    }));
  };

  // Apply filters when internal state changes, but only if changed by user
  useEffect(() => {
    if (isFilterChangedByUser.current) {
      const updatedFilters = {
        ...selectedFilters,
        priceRange,
        myBids,
        favorites,
        myAuctions,
        q: searchQuery,
        supplierName: searchSupplier,
        supplier,
        dynamicFilters: dynamicFilterValues, // Include dynamic filters
      };
      onApplyFilters(updatedFilters);
      isFilterChangedByUser.current = false;
    }
  }, [
    selectedFilters,
    priceRange,
    myBids,
    favorites,
    myAuctions,
    searchQuery,
    searchSupplier,
    supplier,
    dynamicFilterValues,
    onApplyFilters,
  ]);

  // Prepare selected filters for the SelectedFilters component
  const selectedFiltersChips = Object.entries(selectedFilters).flatMap(([filterKey, items]) =>
    items.map((item) => ({
      label: item,
      onDelete: () => handleToggleFilterItem(filterKey, item),
    }))
  );

  const dynamicFiltersChips = Object.entries(dynamicFilterValues).flatMap(([filterKey, value]) => {
    const field = dynamicFilters.find((f) => f.name === filterKey);
    const label = field ? field.label : filterKey;

    if (Array.isArray(value)) {
      return value.map((item) => ({
        label: `${label}: ${item}`,
        onDelete: () => handleDynamicFilterDelete(filterKey, item),
      }));
    } else {
      return value
        ? [
            {
              label: `${label}: ${value}`,
              onDelete: () => handleDynamicFilterDelete(filterKey, value),
            },
          ]
        : [];
    }
  });

  const otherFiltersChips = [
    ...(priceRange[0] !== 0 || priceRange[1] !== MAX_PRICE
      ? [
          {
            label: `$${priceRange[0]} - $${priceRange[1]}`,
            onDelete: handlePriceRangeReset,
          },
        ]
      : []),
    ...(myBids
      ? [
          {
            label: 'My Bids',
            onDelete: () => {
              setMyBids(false);
              isFilterChangedByUser.current = true;
            },
          },
        ]
      : []),
    ...(favorites
      ? [
          {
            label: 'Favorites',
            onDelete: () => {
              setFavorites(false);
              isFilterChangedByUser.current = true;
            },
          },
        ]
      : []),
    ...(myAuctions
      ? [
          {
            label: 'My Auctions',
            onDelete: () => {
              setMyAuctions(false);
              isFilterChangedByUser.current = true;
            },
          },
        ]
      : []),
    ...(searchQuery
      ? [
          {
            label: `Search: ${searchQuery}`,
            onDelete: () => {
              setSearchQuery('');
              isFilterChangedByUser.current = true;
            },
          },
        ]
      : []),
    ...(searchSupplier
      ? [
          {
            label: `Supplier: ${searchSupplier}`,
            onDelete: () => {
              setSearchSupplier('');
              isFilterChangedByUser.current = true;
            },
          },
        ]
      : []),
    ...(supplier
      ? [
          {
            label: `Supplier: ${supplier}`,
            onDelete: () => {
              setSupplier('');
              isFilterChangedByUser.current = true;
            },
          },
        ]
      : []),
  ];

  const allSelectedFilters = [...selectedFiltersChips, ...dynamicFiltersChips, ...otherFiltersChips];

  // Determine if sidebar should be shown on desktop
  const shouldShowDesktopSidebar =
    (!isMobile &&
      isRouteWithSidebar() &&
      filters &&
      Object.keys(filters).length > 0 &&
      ((filters.categories && filters.categories.length > 0) ||
        (filters.dynamicFilters && Object.keys(filters.dynamicFilters).length > 0))) ||
    location.pathname === '/ai/search';

  console.log(` shouldShowDesktopSidebar: ${shouldShowDesktopSidebar}`);
  console.log(` isRouteWithSidebar(): ${isRouteWithSidebar()}`);
  console.log(` location.pathname: ${location.pathname}`);
  console.log(` isMobile: ${isMobile}`);
  console.log(` isSidebarVisible: ${isSidebarVisible}`);
  console.log(` allSelectedFilters.length: ${allSelectedFilters.length}`);
  console.log(` Object.keys(filters.dynamicFilters || {}).length: ${Object.keys(filters.dynamicFilters || {}).length}`);
  console.log(` selectedFilters.categories.length: ${selectedFilters.categories.length}`);
  console.log(` isRouteWithSidebar(): ${isRouteWithSidebar()}`);
  console.log(` isOpen: ${isOpen}`);
  return (
    <div className={`filter-sidebar-container ${shouldShowDesktopSidebar ? 'sidebar-visible' : ''}`}>
      {isMobile ? (
        // Mobile: Use Drawer (overlay)
        <Drawer
          anchor="left"
          open={isOpen}
          onClose={onClose}
          ModalProps={{
            BackdropProps: {
              style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
            },
          }}
          PaperProps={{
            style: {
              width: '80%',
            },
          }}
        >
          <div className="custom-filter-sidebar">
            {/* Sidebar content */}
            {/* Selected Filters */}
            {allSelectedFilters.length > 0 && (
              <div className="selected-filters-section">
                <Typography variant="subtitle1" className="filter-section-title">
                  Selected Filters
                </Typography>
                <div className="selected-filters-list">
                  {allSelectedFilters.map((filter, index) => (
                    <Chip key={index} label={filter.label} onDelete={filter.onDelete} className="filter-chip" />
                  ))}
                  <Divider className="filter-divider" />
                  <Button size="small" onClick={handleClearAllFilters} className="clear-all-button">
                    Clear All
                  </Button>
                </div>
                <Divider className="filter-divider" />
              </div>
            )}
            {/* Filters Title */}
            <Typography variant="h6" className="filter-sidebar-title">
              Filters
            </Typography>
            {/* Switch Filters */}
            <div className="toggle-filters">
              <FormControlLabel
                control={<Checkbox checked={myBids} onChange={() => handleToggleSwitch(setMyBids)} color="primary" />}
                label="My Bids"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={favorites} onChange={() => handleToggleSwitch(setFavorites)} color="primary" />
                }
                label="Favorites"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={myAuctions} onChange={() => handleToggleSwitch(setMyAuctions)} color="primary" />
                }
                label="My Auctions"
              />
            </div>
            <Divider className="filter-divider" />
            {/* Search Fields */}
            <TextField
              label="Search Items"
              value={searchQuery}
              onChange={handleSearchChange}
              fullWidth
              margin="normal"
              className="filter-sidebar-search-field"
            />
            <Divider className="filter-divider" />
            {/* Categories Filter Section */}
            {filtersConfig.map(({ key, title, items }) => (
              <React.Fragment key={key}>
                <FilterSection
                  title={title}
                  items={items}
                  selectedItems={selectedFilters[key]}
                  onToggle={(item) => handleToggleFilterItem(key, item)}
                />
                <Divider className="filter-divider" />
              </React.Fragment>
            ))}
            {/* Price Range */}
            <Typography variant="subtitle1" className="filter-section-title">
              Price Range
            </Typography>
            <Slider
              value={priceRange}
              onChange={handlePriceChange}
              valueLabelDisplay="auto"
              min={0}
              max={MAX_PRICE}
              className="filter-slider"
            />
            <Typography className="filter-price-range">
              Price: ${priceRange[0]} - ${priceRange[1]}
            </Typography>
            <Divider className="filter-divider" />
            {/* Supplier Search */}
            <TextField
              label="Supplier"
              value={searchSupplier}
              onChange={handleSearchSupplierChange}
              fullWidth
              margin="normal"
              className="filter-search-field"
            />
            <Divider className="filter-divider" />
            {/* Dynamic Sub-Filters Section */}
            {selectedFilters.categories.length === 1 && dynamicFilters.length > 0 && (
              <>
                <Typography variant="h6" className="filter-title">
                  {selectedFilters.categories[0]} Filters
                </Typography>
                <DynamicFilterSection
                  fields={dynamicFilters}
                  dynamicFilterValues={dynamicFilterValues}
                  onDynamicFilterChange={handleDynamicFilterValues}
                />
              </>
            )}
            <Divider className="filter-divider" />
          </div>
        </Drawer>
      ) : (
        // Desktop: Inline sidebar
        <div className="custom-filter-sidebar">
          {/* Sidebar content */}
          {/* Selected Filters */}
          {allSelectedFilters.length > 0 && (
            <div className="selected-filters-section">
              <Typography variant="subtitle1" className="filter-section-title">
                Selected Filters
              </Typography>
              <div className="selected-filters-list">
                {allSelectedFilters.map((filter, index) => (
                  <Chip key={index} label={filter.label} onDelete={filter.onDelete} className="filter-chip" />
                ))}
                <Divider className="filter-divider" />
                <Button size="small" onClick={handleClearAllFilters} className="clear-all-button">
                  Clear All
                </Button>
              </div>
              <Divider className="filter-divider" />
            </div>
          )}
          {/* Filters Title */}
          <Typography variant="h6" className="filter-title">
            Filters
          </Typography>
          {/* Switch Filters */}
          <div className="toggle-filters">
            <FormControlLabel
              control={<Checkbox checked={myBids} onChange={() => handleToggleSwitch(setMyBids)} color="primary" />}
              label="My Bids"
            />
            <FormControlLabel
              control={
                <Checkbox checked={favorites} onChange={() => handleToggleSwitch(setFavorites)} color="primary" />
              }
              label="Favorites"
            />
            <FormControlLabel
              control={
                <Checkbox checked={myAuctions} onChange={() => handleToggleSwitch(setMyAuctions)} color="primary" />
              }
              label="My Auctions"
            />
          </div>
          <Divider className="filter-divider" />
          {/* Search Fields */}
          <TextField
            label="Search Items"
            value={searchQuery}
            onChange={handleSearchChange}
            fullWidth
            margin="normal"
            className="filter-search-field"
          />
          <Divider className="filter-divider" />
          {/* Categories Filter Section */}
          {filtersConfig.map(({ key, title, items }) => (
            <React.Fragment key={key}>
              <FilterSection
                title={title}
                items={items}
                selectedItems={selectedFilters[key]}
                onToggle={(item) => handleToggleFilterItem(key, item)}
              />
              <Divider className="filter-divider" />
            </React.Fragment>
          ))}
          {/* Price Range */}
          <Typography variant="subtitle1" className="filter-section-title">
            Price Range
          </Typography>
          <Slider
            value={priceRange}
            onChange={handlePriceChange}
            valueLabelDisplay="auto"
            min={0}
            max={MAX_PRICE}
            className="filter-slider"
          />
          <Typography className="filter-price-range">
            Price: ${priceRange[0]} - ${priceRange[1]}
          </Typography>
          <Divider className="filter-divider" />
          {/* Supplier Search */}
          <TextField
            label="Supplier"
            value={searchSupplier}
            onChange={handleSearchSupplierChange}
            fullWidth
            margin="normal"
            className="filter-search-field"
          />
          <Divider className="filter-divider" />
          {/* Dynamic Sub-Filters Section */}
          {selectedFilters.categories.length === 1 && dynamicFilters.length > 0 && (
            <>
              <Typography variant="h6" className="filter-title">
                {selectedFilters.categories[0]} Filters
              </Typography>
              <DynamicFilterSection
                fields={dynamicFilters}
                dynamicFilterValues={dynamicFilterValues}
                onDynamicFilterChange={handleDynamicFilterValues}
              />
            </>
          )}
          <Divider className="filter-divider" />
        </div>
      )}
    </div>
  );
};

export default FilterSidebar;
