// src/components/add-auction/ImageUploadSection.tsx

import React, { useState, useEffect } from 'react';
import {
  Grid2,
  IconButton,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from '@mui/material';
import {
  PhotoCamera,
  Visibility,
  AutoFixHigh,
  SwapHoriz as SwapHorizIcon,
  Star as StarIcon,
} from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import { ImageItem } from '../../models/interfaces';
import apiClient from '../apiClient';

interface ImageUploadSectionProps {
  coverImage: ImageItem | null;
  setCoverImage: React.Dispatch<React.SetStateAction<ImageItem | null>>;
  images: ImageItem[];
  setImages: React.Dispatch<React.SetStateAction<ImageItem[]>>;
  handleImageChange: (e: React.ChangeEvent<HTMLInputElement>, imageType: 'coverImage' | 'images') => void;
  handleRemoveImage: (index: number) => void;
  updateAuctionItemImage: (imageUrl: string) => void;
}

const ImageUploadSection: React.FC<ImageUploadSectionProps> = ({
  coverImage,
  setCoverImage,
  images,
  setImages,
  handleImageChange,
  handleRemoveImage,
  updateAuctionItemImage,
}) => {
  // State for viewing images
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [viewImageSrc, setViewImageSrc] = useState<string>('');
  const [enhanceModalOpen, setEnhanceModalOpen] = useState(false);
  const [enhancing, setEnhancing] = useState(false);
  const [enhanceError, setEnhanceError] = useState('');

  // Image Dropzone for cover image
  const {
    getRootProps: getCoverRootProps,
    getInputProps: getCoverInputProps,
    isDragActive: isCoverDragActive,
  } = useDropzone({
    accept: {
      'image/*': [],
    },
    multiple: false, // Only one cover image
    onDrop: (acceptedFiles: File[]) => {
      const event = {
        target: {
          files: acceptedFiles,
        },
      } as unknown as React.ChangeEvent<HTMLInputElement>;
      handleImageChange(event, 'coverImage');
    },
  });

  // Image Dropzone for additional images
  const {
    getRootProps: getImagesRootProps,
    getInputProps: getImagesInputProps,
    isDragActive: isImagesDragActive,
  } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDrop: (acceptedFiles: File[]) => {
      const event = {
        target: {
          files: acceptedFiles,
        },
      } as unknown as React.ChangeEvent<HTMLInputElement>;
      handleImageChange(event, 'images');
    },
  });

  // Function to handle viewing an image
  const handleViewImage = (src: string) => {
    setViewImageSrc(src);
    setIsViewOpen(true);
  };

  // Function to close the view modal
  const handleCloseView = () => {
    setIsViewOpen(false);
    setViewImageSrc('');
  };

  // Handle enhancing the cover image
  const handleEnhanceImage = async () => {
    if (!coverImage) {
      setEnhanceError('Please select a cover image to enhance.');
      return;
    }

    let imageFile = coverImage.file;

    if (!imageFile && coverImage.existing) {
      // Fetch existing cover image and set as coverImage
      try {
        const response = await fetch(coverImage.src);
        const blob = await response.blob();
        const fileName = coverImage.src.split('/').pop() || 'coverImage.jpg';
        imageFile = new File([blob], fileName, { type: blob.type });

        // Update the image in state
        setCoverImage({
          ...coverImage,
          file: imageFile,
          existing: false,
        });
      } catch (error: any) {
        console.error('Error fetching existing cover image:', error);
        setEnhanceError('Failed to fetch existing cover image.');
        return;
      }
    }

    if (!imageFile) {
      setEnhanceError('Please select a cover image to enhance.');
      return;
    }

    try {
      setEnhanceModalOpen(true);
      setEnhancing(true);
      setEnhanceError('');

      const formData = new FormData();
      formData.append('coverImage', imageFile);

      const response = await apiClient.post('/auction-items/enhanceCover', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob', // Expect binary data in response
      });

      // Handle successful response (binary data)
      const blob = response.data;
      const fileName = 'enhancedCoverImage.jpg';
      const enhancedImageFile = new File([blob], fileName, { type: blob.type });

      // Update cover image in state
      setCoverImage({
        ...coverImage,
        src: URL.createObjectURL(enhancedImageFile),
        file: enhancedImageFile,
        existing: false, // Since it's a local URL
      });

      // Update auctionItem.img if necessary
      updateAuctionItemImage(''); // Clear existing image URL or set as needed

      // Enhancement successful
      setEnhancing(false);
      setEnhanceModalOpen(false);
    } catch (error: any) {
      if (error.response) {
        // Server responded with a status other than 2xx
        const errorMessage = error.response.data?.message || 'Failed to enhance cover image.';
        console.error('Error enhancing cover image:', errorMessage);
        setEnhanceError(errorMessage);
      } else if (error.request) {
        // Request was made but no response received
        console.error('No response received:', error.request);
        setEnhanceError('No response from server.');
      } else {
        // Something else happened
        console.error('Error enhancing cover image:', error.message);
        setEnhanceError(error.message || 'Failed to enhance cover image.');
      }
      setEnhancing(false);
      setEnhanceModalOpen(false);
    }
  };

  // Handle swapping an image with the cover image
  const handleSwapWithCover = (index: number) => {
    if (!coverImage) {
      const selectedImage = images[index];
      setCoverImage(selectedImage);
      setImages((prevImages) => {
        const imageToRemove = prevImages[index];
        if (!imageToRemove.existing) {
          URL.revokeObjectURL(imageToRemove.src);
        }
        return prevImages.filter((_, idx) => idx !== index);
      });
      updateAuctionItemImage(selectedImage.src);
      return;
    }

    // Swap cover image with selected image
    const selectedImage = images[index];
    setImages((prevImages) => {
      const newImages = [...prevImages];
      newImages[index] = coverImage!;
      return newImages;
    });
    setCoverImage(selectedImage);
    updateAuctionItemImage(selectedImage.src);
  };

  // Handle drag and drop reordering
  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) return;

    const reorderedImages = Array.from(images);
    const [movedImage] = reorderedImages.splice(source.index, 1);
    reorderedImages.splice(destination.index, 0, movedImage);

    setImages(reorderedImages);
  };

  useEffect(() => {
    // Cleanup function to revoke object URLs when component unmounts
    return () => {
      if (coverImage && !coverImage.existing) {
        URL.revokeObjectURL(coverImage.src);
      }
      images.forEach((image) => {
        if (!image.existing) {
          URL.revokeObjectURL(image.src);
        }
      });
    };
  }, []); // Empty dependency array ensures this runs only on unmount

  return (
    <>
      {/* Cover Image */}
      <div>
        <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
          Upload Cover Image
        </Typography>
        <Grid2 container spacing={2} alignItems="center">
          <Grid2>
            <div
              {...getCoverRootProps()}
              style={{
                border: '2px dashed #cccccc',
                padding: '20px',
                textAlign: 'center',
                cursor: 'pointer',
                borderRadius: '8px',
              }}
            >
              <input {...getCoverInputProps()} />
              <IconButton color="primary" aria-label="upload cover image">
                <PhotoCamera />
              </IconButton>
              {isCoverDragActive ? (
                <Typography variant="body2">Drop the cover image here...</Typography>
              ) : (
                <Typography variant="body2">
                  Drag 'n' drop cover image here,
                  <br />
                  or click to select a file
                </Typography>
              )}
            </div>
          </Grid2>
          {/* Display current cover image */}
          {coverImage && (
            <Grid2>
              <div style={{ position: 'relative' }}>
                <img
                  src={coverImage.src}
                  alt="Cover"
                  style={{
                    width: '100px',
                    height: '100px',
                    objectFit: 'cover',
                    borderRadius: '4px',
                    border: '2px solid #3f51b5',
                  }}
                />
                {/* Delete Button */}
                {!coverImage.existing && (
                  <IconButton
                    style={{
                      position: 'absolute',
                      top: -10,
                      right: -10,
                      color: 'red',
                      backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    }}
                    onClick={() => {
                      // Remove cover image
                      setCoverImage(null);
                      updateAuctionItemImage('');
                    }}
                    aria-label="delete cover image"
                  >
                    &times;
                  </IconButton>
                )}
                {/* View Button */}
                <IconButton
                  style={{
                    position: 'absolute',
                    bottom: -10,
                    right: -10,
                    color: 'gray',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  }}
                  onClick={() => handleViewImage(coverImage.src)}
                  aria-label="view cover image"
                >
                  <Visibility />
                </IconButton>
                {/* Enhance Button */}
                <IconButton
                  style={{
                    position: 'absolute',
                    top: -10,
                    left: -10,
                    color: 'green',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  }}
                  onClick={handleEnhanceImage}
                  aria-label="enhance cover image"
                >
                  <AutoFixHigh />
                </IconButton>
              </div>
            </Grid2>
          )}
        </Grid2>
      </div>

      {/* Additional Images */}
      <div>
        <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
          Upload Images
        </Typography>
        <Grid2 container spacing={2} alignItems="center">
          <Grid2>
            <div
              {...getImagesRootProps()}
              style={{
                border: '2px dashed #cccccc',
                padding: '20px',
                textAlign: 'center',
                cursor: 'pointer',
                borderRadius: '8px',
              }}
            >
              <input {...getImagesInputProps()} />
              <IconButton color="primary" aria-label="upload pictures">
                <PhotoCamera />
              </IconButton>
              {isImagesDragActive ? (
                <Typography variant="body2">Drop the images here...</Typography>
              ) : (
                <Typography variant="body2">
                  Drag 'n' drop images here,
                  <br />
                  or click to select files
                </Typography>
              )}
            </div>
          </Grid2>
          {images.length > 0 && (
            <Grid2 size={{ xs: 12 }}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="images" direction="horizontal">
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={{
                        display: 'flex',
                        overflowX: 'auto',
                        marginTop: '5px',
                      }}
                    >
                      {images.map((image, index) => (
                        <Draggable key={image.id} draggableId={image.id} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                position: 'relative',
                                marginRight: '10px',
                                ...provided.draggableProps.style,
                              }}
                            >
                              <img
                                src={image.src}
                                alt={`Image ${index + 1}`}
                                style={{
                                  width: '80px',
                                  height: '80px',
                                  objectFit: 'cover',
                                  borderRadius: '4px',
                                }}
                              />
                              {/* Delete Button */}
                              <IconButton
                                style={{
                                  position: 'absolute',
                                  top: -5,
                                  right: -10,
                                  color: 'red',
                                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                }}
                                onClick={() => handleRemoveImage(index)}
                                aria-label="delete image"
                              >
                                &times;
                              </IconButton>
                              {/* View Button */}
                              <IconButton
                                style={{
                                  position: 'absolute',
                                  bottom: -10,
                                  right: -10,
                                  color: 'gray',
                                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                }}
                                onClick={() => handleViewImage(image.src)}
                                aria-label="view image"
                              >
                                <Visibility />
                              </IconButton>
                              {/* Swap with Cover Button */}
                              <IconButton
                                style={{
                                  position: 'absolute',
                                  top: -10,
                                  left: -10,
                                  color: 'blue',
                                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                }}
                                onClick={() => handleSwapWithCover(index)}
                                aria-label="swap with cover image"
                              >
                                <SwapHorizIcon />
                              </IconButton>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Grid2>
          )}
        </Grid2>
      </div>

      {/* View Image Modal */}
      <Dialog open={isViewOpen} onClose={handleCloseView} maxWidth="sm" fullWidth aria-labelledby="view-image-dialog">
        <DialogContent
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px',
            backgroundColor: '#f5f5f5',
          }}
        >
          <img
            src={viewImageSrc}
            alt="View"
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'contain',
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseView} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Enhance Image Modal */}
      <Dialog open={enhanceModalOpen} onClose={() => setEnhanceModalOpen(false)} maxWidth="sm" fullWidth>
        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '20px',
          }}
        >
          {enhancing ? (
            <>
              <CircularProgress />
              <Typography variant="body1" style={{ marginTop: '10px' }}>
                Enhancing image...
              </Typography>
            </>
          ) : enhanceError ? (
            <Typography variant="body1" color="error">
              {enhanceError}
            </Typography>
          ) : (
            <Typography variant="body1">Image enhanced successfully!</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEnhanceModalOpen(false)} color="primary" variant="contained">
            {enhancing ? 'Cancel' : 'Close'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ImageUploadSection;
