// src/components/chat/StatusIndicator.tsx

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip, CircularProgress } from '@mui/material';
import { Mic, MicOff, VolumeUp, Send, ErrorOutline, Hearing } from '@mui/icons-material';
import { set } from 'date-fns';
import timeWithMilliseconds from './timeWithMilliseconds';

interface StatusIndicatorProps {
  status: string;
  statusRef: React.MutableRefObject<string>;
}

const StatusIndicator: React.FC<StatusIndicatorProps> = ({ status, statusRef }) => {
  const { t } = useTranslation();
  const [icon, setIcon] = React.useState<React.ReactNode | null>(null);

  let tooltip = '';
  let showProgress = false;

  useEffect(() => {
    console.log(timeWithMilliseconds(), 'StatusIndicator.tsx status:', status);
    switch (statusRef.current) {
      case 'getting-audio':
        setIcon(<Mic />);
        tooltip = t('talkInterface.status.getting-audio');
        showProgress = true;
        break;
      case 'playing-audio':
        setIcon(<VolumeUp />);
        tooltip = t('talkInterface.status.playing-audio');
        showProgress = true;
        break;
      case 'sending-message':
        setIcon(<Send />);
        tooltip = t('talkInterface.status.sending-message');
        showProgress = true;
        break;
      case 'failed-sending-message':
        setIcon(<ErrorOutline />);
        tooltip = t('talkInterface.status.failed-sending-message');
        break;
      case 'start-listening':
        setIcon(<Hearing />);
        tooltip = t('talkInterface.status.start-listening');
        break;
      case 'stop-listening':
        setIcon(<> </>);
        tooltip = t('talkInterface.status.stop-listening');
        break;
      default:
        setIcon(<> </>);
        tooltip = t('talkInterface.status.default');
        break;
    }
  }, [status]);

  return (
    <Tooltip title={tooltip}>
      <IconButton color="primary">
        {icon}
        {showProgress && <CircularProgress size={24} style={{ position: 'absolute', zIndex: 1 }} />}
      </IconButton>
    </Tooltip>
  );
};

export default StatusIndicator;
