// src/components/header/HeaderMobile.tsx

import React from 'react';
import {
  AppBar,
  Toolbar,
  Grid2,
  Box,
  Button,
  IconButton,
  Tooltip,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Chip,
  Menu,
  Fade,
  MenuItem,
  Typography,
  Grid,
} from '@mui/material';
import {
  AccountCircle,
  Search as SearchIcon,
  Sort as SortIcon,
  FilterAltOff as FilterAltOffIcon,
  FilterAlt as FilterAltIcon,
  Menu as MenuIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import UserMenu from '../UserMenu';
import CategoryButton from './CategoryButton';
import './HeaderMobile.css';
import { useAuth } from '../../contexts/AuthContext';
import { ReactComponent as BlingLongLogo } from '../../assets/images/BlingLong.svg';

interface HeaderMobileProps {
  isDesktop: boolean;
  searchQuery: string;
  handleSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSearchSubmit: () => void;
  handleKeyPress: (e: React.KeyboardEvent) => void;
  anchorEl: null | HTMLElement;
  openSortMenu: boolean;
  handleSortMenuOpen: (e: React.MouseEvent<HTMLElement>) => void;
  handleSortMenuClose: () => void;
  handleSortSelect: (value: string) => void;
  drawerOpen: boolean;
  toggleDrawer: (open: boolean) => (e: React.KeyboardEvent | React.MouseEvent) => void;
  categories: Array<{ name: string; path: string; filter: string; requireAuth?: boolean }>;
  handleCategorySelectInternal: (category: string, filter_name: string) => void;
  truncateDisplayName: (name: string) => string;
  handleGoHome: () => void;
  handleLogin: () => void;
  isMobileSearchOpen: boolean;
  handleMobileSearchOpen: () => void;
  handleMobileSearchClose: () => void;
  searchBoxRef: React.RefObject<HTMLDivElement>;
  hasFiltersApplied: boolean;
  handleRemoveFilter: (filterType: string, value: any) => void;
  handleClearAllFilters: () => void;
  sortOptions: Array<{ value: string; label: string }>;
  getDynamicFilterLabel: (key: string) => string;
  user: {
    displayName: string;
    role: string;
  } | null;
  onLogout: () => void;
  filters: any;
  onOpenFilter?: () => void;
}

const HeaderMobile: React.FC<HeaderMobileProps> = ({
  isDesktop,
  searchQuery,
  handleSearchChange,
  handleSearchSubmit,
  handleKeyPress,
  anchorEl,
  openSortMenu,
  handleSortMenuOpen,
  handleSortMenuClose,
  handleSortSelect,
  drawerOpen,
  toggleDrawer,
  categories,
  handleCategorySelectInternal,
  truncateDisplayName,
  handleGoHome,
  handleLogin,
  isMobileSearchOpen,
  handleMobileSearchOpen,
  handleMobileSearchClose,
  searchBoxRef,
  hasFiltersApplied,
  handleRemoveFilter,
  handleClearAllFilters,
  sortOptions,
  getDynamicFilterLabel,
  user,
  onLogout,
  filters,
  onOpenFilter,
}) => {
  const MAX_PRICE = 3000;
  const location = useLocation();
  const { isLoggedIn } = useAuth();

  return (
    <div className="header-container-mobile">
      <AppBar position="static" className="header-appbar-mobile">
        <Toolbar className="header-toolbar-mobile">
          <Grid2 container alignItems="center" className="header-mobile-container">
            {/* Left Side: Hamburger Menu */}
            <Grid2 size={1}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                className="header-hamburger-menu"
              >
                <MenuIcon />
              </IconButton>
            </Grid2>

            {/* Middle: BlingLong Button or Search Input */}
            <Grid2
              size={7}
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexGrow: 1,
                justifyContent: 'center',
              }}
            >
              {isMobileSearchOpen ? (
                <Box ref={searchBoxRef} display="flex" alignItems="center" className="header-search-box-mobile">
                  <TextField
                    variant="outlined"
                    placeholder="Search..."
                    size="small"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    onKeyPress={handleKeyPress}
                    className="header-search-input-mobile"
                    InputProps={{
                      style: {
                        backgroundColor: 'white',
                        borderRadius: 4,
                        paddingRight: 0,
                      },
                    }}
                    autoFocus
                  />
                  <IconButton
                    onClick={handleMobileSearchClose}
                    className="header-search-close-button"
                    aria-label="close search"
                    sx={{ p: 1 }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              ) : (
                <Button onClick={handleGoHome} className="header-home-button-mobile">
                  <BlingLongLogo width={120} height={32} /> {/* Adjust size as needed */}
                </Button>
              )}
            </Grid2>

            {/* Right Side: Search Icon and UserMenu/Login */}
            {!isMobileSearchOpen && (
              <Grid2 size={1}>
                <IconButton
                  onClick={handleMobileSearchOpen}
                  className="header-search-icon-button"
                  aria-label="open search"
                  sx={{ p: 1 }}
                >
                  <SearchIcon />
                </IconButton>
              </Grid2>
            )}
            {user ? (
              <Grid2 size={1.5}>
                <UserMenu
                  user={{
                    ...user,
                    displayName: truncateDisplayName(user?.displayName || ''),
                  }}
                  onLogout={onLogout}
                  onApplyFilters={handleSortSelect}
                />
              </Grid2>
            ) : (
              <Grid2 size={3}>
                <Button className="header-login-button-mobile" startIcon={<AccountCircle />} onClick={handleLogin}>
                  Login
                </Button>
              </Grid2>
            )}
          </Grid2>
        </Toolbar>

        {/* Selected Filters Section */}
        {(location.pathname === '/' || location.pathname.startsWith('/category')) && hasFiltersApplied && (
          <div className="header-filters-section-mobile">
            <div className="header-selected-filters-list-mobile">
              {/* Display Chips for selected filters */}
              {filters.categories &&
                filters.categories.map((category: string) => (
                  <Chip
                    key={category}
                    label={category}
                    onDelete={() => handleRemoveFilter('categories', category)}
                    className="filter-chip-mobile"
                  />
                ))}
              {filters.priceRange && (filters.priceRange[0] !== 0 || filters.priceRange[1] !== MAX_PRICE) && (
                <Chip
                  label={`$${filters.priceRange[0]} - $${filters.priceRange[1]}`}
                  onDelete={() => handleRemoveFilter('priceRange', null)}
                  className="filter-chip-mobile"
                />
              )}
              {filters.myBids && (
                <Chip
                  label="My Bids"
                  onDelete={() => handleRemoveFilter('myBids', null)}
                  className="filter-chip-mobile"
                />
              )}
              {filters.favorites && (
                <Chip
                  label="Favorites"
                  onDelete={() => handleRemoveFilter('favorites', null)}
                  className="filter-chip-mobile"
                />
              )}
              {filters.myAuctions && (
                <Chip
                  label="My Auctions"
                  onDelete={() => handleRemoveFilter('myAuctions', null)}
                  className="filter-chip-mobile"
                />
              )}
              {filters.q && (
                <Chip
                  label={`Search: ${filters.q}`}
                  onDelete={() => handleRemoveFilter('q', null)}
                  className="filter-chip-mobile"
                />
              )}
              {filters.supplier && (
                <Chip
                  label={`Supplier: ${filters.supplier}`}
                  onDelete={() => handleRemoveFilter('supplier', null)}
                  className="filter-chip-mobile"
                />
              )}
              {filters.supplierName && (
                <Chip
                  label={`Supplier: ${filters.supplierName}`}
                  onDelete={() => handleRemoveFilter('supplierName', null)}
                  className="filter-chip-mobile"
                />
              )}
              {filters.dynamicFilters &&
                Object.keys(filters.dynamicFilters).map((key) => (
                  <Chip
                    key={key}
                    label={`${getDynamicFilterLabel(key)}: ${filters.dynamicFilters[key]}`}
                    onDelete={() => handleRemoveFilter('dynamicFilters', key)}
                    className="filter-chip-mobile"
                  />
                ))}
              {filters.sort && (
                <Chip
                  label={`Sort: ${filters.sort}`}
                  onDelete={() => handleRemoveFilter('sort', null)}
                  className="filter-chip-mobile"
                />
              )}
            </div>
            <div className="header-selected-filters-clear-mobile">
              <Tooltip title="Filters">
                <Button
                  variant="text"
                  onClick={onOpenFilter}
                  className="header-filter-button"
                  startIcon={<FilterAltIcon />}
                ></Button>
              </Tooltip>

              {/* Clear All Filters Button */}
              <Tooltip title="Clear All Filters">
                <IconButton
                  aria-label="clear all filters"
                  onClick={handleClearAllFilters}
                  size="large"
                  className="clear-all-button-mobile"
                >
                  <FilterAltOffIcon fontSize="small" color="error" />
                </IconButton>
              </Tooltip>

              {/* Sort Options */}
              <Box display="flex" alignItems="center" className="sort-select-mobile">
                <Tooltip title="Sort By">
                  <IconButton aria-label="sort by" onClick={handleSortMenuOpen} size="large" color="inherit">
                    <SortIcon />
                  </IconButton>
                </Tooltip>
                <Menu
                  TransitionComponent={Fade}
                  anchorEl={anchorEl}
                  open={openSortMenu}
                  onClose={handleSortMenuClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                >
                  {sortOptions.map((option) => (
                    <MenuItem
                      key={option.value}
                      selected={filters.sort === option.value}
                      onClick={() => handleSortSelect(option.value)}
                      sx={{
                        fontSize: {
                          xs: '1rem',
                          sm: '1rem',
                          md: '1rem',
                          lg: '1rem',
                        },
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </div>
          </div>
        )}
      </AppBar>

      {/* Drawer for Mobile */}
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 250 }} role="presentation">
          {user ? (
            <Box sx={{ p: 2 }}>
              <Typography variant="subtitle1">{truncateDisplayName(user.displayName)}</Typography>
            </Box>
          ) : (
            <Box sx={{ p: 2 }}>
              <Button startIcon={<AccountCircle />} onClick={handleLogin} fullWidth variant="outlined">
                Login
              </Button>
            </Box>
          )}
          <List>
            {categories.map(
              (category) =>
                !(category.requireAuth && !isLoggedIn) && (
                  <ListItem key={category.name} disablePadding>
                    <ListItemButton
                      component={RouterLink}
                      to={category.path}
                      onClick={() => handleCategorySelectInternal(category.name, category.filter)}
                    >
                      <ListItemText primary={category.name} />
                    </ListItemButton>
                  </ListItem>
                )
            )}
          </List>
        </Box>
      </Drawer>
    </div>
  );
};

export default HeaderMobile;
